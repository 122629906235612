import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"

import goal1Img from "assets/goals/goal1.png"
import goal2Img from "assets/goals/goal2.png"
import goal3Img from "assets/goals/goal3.png"
import goal4Img from "assets/goals/goal4.png"
import goal5Img from "assets/goals/goal5.png"
import goal6Img from "assets/goals/goal6.png"
import goal7Img from "assets/goals/goal7.png"
import goal8Img from "assets/goals/goal8.png"
import goal9Img from "assets/goals/goal9.png"
import goal10Img from "assets/goals/goal10.png"
import goal11Img from "assets/goals/goal11.png"
import goal12Img from "assets/goals/goal12.png"
import goal13Img from "assets/goals/goal13.png"
import goal14Img from "assets/goals/goal14.png"
import goal15Img from "assets/goals/goal15.png"
import goal16Img from "assets/goals/goal16.png"
import goal17Img from "assets/goals/goal17.png"
import { Page } from "./components"

const imgs = [
  goal1Img,
  goal2Img,
  goal3Img,
  goal4Img,
  goal5Img,
  goal6Img,
  goal7Img,
  goal8Img,
  goal9Img,
  goal10Img,
  goal11Img,
  goal12Img,
  goal13Img,
  goal14Img,
  goal15Img,
  goal16Img,
  goal17Img,
]

const texts = [
  "Utrydde alle former for fattigdom i hele verden",
  "Utrydde sult, oppnå matsikkerhet og bedre ernæring, og fremme bærekraftig landbruk",
  "Sikre god helse og fremme livskvalitet for alle, uansett alder",
  "Sikre inkluderende, rettferdig og god utdanning og fremme muligheter for livslang læring for alle",
  "Oppnå likestilling og styrke jenters og kvinners stilling i samfunnet",
  "Sikre bærekraftig vannforvaltning, tilgang til vann og gode sanitærforhold for alle",
  "Sikre tilgang til pålitelig, bærekraftig og moderne energi til en overkommelig pris for alle",
  "Fremme varig, inkluderende og bærekraftig økonomisk vekst, full sysselsetting og anstendig arbeid for alle",
  "Bygge solid infrastruktur og fremme inkluderende og bærekraftig industrialisering og innovasjon",
  "Redusere ulikhet i og mellom land",
  "Gjøre byer og lokalsamfunn inkluderende, trygge, robuste og bærekraftige",
  "Sikre bærekraftige forbruks- og produksjonsmønstre",
  "Handle umiddelbart for å bekjempe klimaendringene og konsekvensene av dem",
  "Bevare og bruke havet og de marine ressursene på en måte som fremmer bærekraftig utvikling",
  "Beskytte, gjenopprette og fremme bærekraftig bruk av økosystemer, sikre bærekraftig skogforvaltning, bekjempe ørkenspredning, stanse og reversere landforringelse samt stanse tap av artsmangfold",
  "Fremme fredelige og inkluderende samfunn for å sikre bærekraftig utvikling, sørge for tilgang til rettsvern for alle, og bygge velfungerende, ansvarlige og inkluderende institusjoner på alle nivåer",
  "Styrke virkemidlene som trengs for å gjennomføre arbeidet, og fornye globale partnerskap for bærekraftig utvikling",
]

const goals = Array.from({ length: 17 }).map((_, k) => ({
  text: texts[k],
  goal: k + 1,
  img: imgs[k],
}))

export const SustainableDevelopmentGoals: React.FC = () => {
  return (
    <Page id="SustainableDevelopmentGoals">
      <Heading mb={0}>FNs MÅL FOR BÆREKRAFTIG UTVIKLING</Heading>
      <Box mt="1mm">Sustainable Development Goals (SDG)</Box>
      <Flex wrap={"wrap"} mt={"4mm"} justifyContent={"space-between"}>
        {goals.map((g, k) => (
          <Flex mb={"1.8mm"} alignItems={"flex-start"} basis={"50%"} key={k}>
            <Box>
              <Image minH={68} maxH={68} height={68} minW={68} maxW={68} width={68} src={g.img} alt="" />
            </Box>
            <Box alignItems={"flex-start"} pr={"4mm"} fontSize={"10pt"} lineHeight={"14pt"} ml={"3mm"}>
              <Text mt={0} fontWeight={600} mb={0}>
                Mål {g.goal}:
              </Text>
              <Text mt={2}>{g.text}</Text>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Page>
  )
}
