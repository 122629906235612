import { FormControl, FormLabel, Input } from "@chakra-ui/react"
import { BRREGUnit } from "@esgt/types"
import { ContributorsInfoTooltipIcon } from "components/Tooltips"
import { EmailInput } from "components/common/Contact/EmailInput"
import { PhoneInput } from "components/common/Contact/PhoneInput"
import { RequiredInputAsterisk } from "components/common/Contact/RequiredInputAsterisk"
import { Dispatch, SetStateAction, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { NewRatingStep, NewRatingSubstep } from "../NewRatingStep"
import { CompanyInfo } from "./CompanyInfo"

interface CompanyInfoFormProps {
  selectedOrganization: BRREGUnit
  setSelectedOrganization: Dispatch<SetStateAction<BRREGUnit>>
  primaryContact: RatingContactInfo
  setPrimaryContact: (c: RatingContactInfo) => void
}

export type RatingContactInfo = {
  email: string
  name: string
  phone?: string
}

export const CompanyInfoForm = ({
  selectedOrganization,
  setSelectedOrganization,
  setPrimaryContact,
  primaryContact,
}: CompanyInfoFormProps) => {
  const {
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm()

  const nameInputRef = useRef<HTMLInputElement>(null)

  const { ref: contactNameRefCb, ...contactNameProps } = register("contactName", {
    required: true,
    value: primaryContact.name,
  })

  useEffect(() => {
    const subscription = watch((value) => {
      setPrimaryContact({
        email: value.contactEmail,
        name: value.contactName,
        phone: value.contactPhone,
      })
    })
    return () => subscription.unsubscribe()
  }, [setPrimaryContact, watch])

  return (
    <NewRatingStep as="form">
      <CompanyInfo selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization} />
      <NewRatingSubstep
        title={
          <>
            Kontaktperson hos kunde <ContributorsInfoTooltipIcon />
          </>
        }
      >
        <FormControl>
          <FormLabel htmlFor="name">
            Navn <RequiredInputAsterisk />
          </FormLabel>

          <Input
            id="name"
            width="50%"
            type="name"
            background="white"
            placeholder="Navn"
            {...contactNameProps}
            ref={(e) => {
              contactNameRefCb(e)
              nameInputRef.current = e
            }}
          />
        </FormControl>

        <EmailInput
          inputKey="contactEmail"
          width="50%"
          label="E-postadresse"
          value={primaryContact.email}
          setError={setError}
          clearErrors={clearErrors}
          register={register}
          errors={errors}
        />

        <PhoneInput
          inputKey="contactPhone"
          width="50%"
          value={primaryContact.phone}
          setError={setError}
          clearErrors={clearErrors}
          register={register}
          errors={errors}
          acceptEmpty
        />
      </NewRatingSubstep>
    </NewRatingStep>
  )
}
