import { Box, Heading, Text } from "@chakra-ui/react"
import { useRating } from "lib/providers/RatingProvider"
import { Page } from "./components"

export const CustomRatingComment: React.FC = () => {
  const { ratingState } = useRating()

  return (
    <Page>
      <Heading mb="4mm">Kommentar fra utførende part</Heading>
      <Box>
        <Text maxWidth={"100%"} fontWeight={300} whiteSpace="break-spaces">
          {ratingState?.ratingComment}
        </Text>
      </Box>
      <Box mt={"10mm"}>
        <Text fontStyle={"italic"} maxWidth={"100%"} fontWeight={300} whiteSpace="break-spaces">
          Muligheter og trusler belyses av «trafikklysene» i solen og i oversikten over ressurser. Ledelsens mål og
          ambisjoner samt strategi for hvordan virksomheten skal komme dit, utarbeides av virksomheten i et eget
          dokument. Rammeverket NSRS er ikke fulgt uten dette.
        </Text>
      </Box>
    </Page>
  )
}
