import { RatingEvent } from "@esgt/event-store"
import { RatingCommitsQuery, useRatingCommitsQuery } from "lib/generated/graphql"
import { prettyFormatAmount } from "lib/helpers/misc"
import { useMemo } from "react"
import { useParams } from "react-router"
import { FixedSizeList } from "react-window"
import styled from "styled-components"
import useResizeObserver from "use-resize-observer"

type RatingCommit = NonNullable<Required<RatingCommitsQuery>["rating"]>["commits"][number]

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
`

const TableHeader = styled.div`
	background-color: #fafafa;
	display: flex;
	flex-flow: row nowrap;
	grid-gap: 16px;
	height: 55px;
	align-items: center;
	padding: 0 16px;
	font-weight: 500;
`

const TableBody = styled.div`
	flex: 1;
	overflow: hidden;
	font-size: 14px;
	line-height: 1.3;
`

const Cell = styled.div``

const Column = styled(Cell)``

const RowContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	border-bottom: 1px solid #f1f1f1;
	box-sizing: border-box;
	align-items: center;
	grid-gap: 16px;
	padding: 0 16px;
	cursor: default;
`

const userEvents: Array<RatingEvent["type"]> = ["DocumentationSubmitted", "FileUploaded"]

const eventDescription = (event: RatingEvent) => {
  switch (event.type) {
    case "RatingCreated":
      return "Måling opprettet"
    case "DocumentationSubmitted":
      return "Dokumentasjon innsendt"
    case "PeriodChanged":
      return "Periode endret"
    case "RatingProfileChanged":
      return "Målingsprofil endret"
    case "RatingAborted":
      return "Måling kansellert"
    case "RatingCompleted":
      return "Måling fullført"
    case "ContactChanged":
      return `Kontaktperson endret: ${event.name} — ${event.email}/${event.phone}`
    case "SubmissionInviteEmailSent":
      return "E-post sendt til kontaktperson"
    case "CommentChanged":
      return "Kommentar oppdatert"
    case "BrregInformationRetrieved":
      return "Firmainformasjon hentet fra brreg"
    case "AdditionalQuestionAdded":
      return "Tilleggsspørsmål lagt til"
    case "AdditionalQuestionRemoved":
      return "Tilleggsspørsmål slettet"
    case "DatapointsSet":
      return `Datapunkt oppdatert: ${JSON.stringify(event.datapoints)}`
    case "KpiValueOverridden":
      return `Verdi av KPI ${event.kpiId} overstyrt: ${event.oldValue} -> ${event.value}`
    case "Ompostering":
      return `Omklassifisering gjort til: ${prettyFormatAmount(event.amount)} ${
        event.omposteringsRule.from ? `flyttet fra ${event.omposteringsRule.from}, ` : ""
      } flyttet til ${event.omposteringsRule.to}`
    case "DocumentationProcessed":
      return "Underlag prosessert"
    case "DocumentationProcessingFailed":
      return `Feil ved prosessering av underlag, type fil: ${event.ratingFileType}, feilmelding: ${event.errorMsg}`
    case "FileUploaded":
      return `Fil lastet opp (${event.filename}) under '${event.scope}'`
    case "SelfServiceDatafilesProcessingFailed":
      return `Feil ved self-service prosessering, type fil: ${event.ratingFileType}, feilmelding: ${event.errorMsg}`
    case "DocumentationAmendmentsRequested":
      return `Bedt om korrigert underlag (${event.scopes?.map(
        (englishScope) =>
          ({
            finances: "regnskap",
            altinn: "altinn",
            questions: "spørsmål",
            additionalQuestions: "tilleggsspørsmål",
          })[englishScope],
      )})`
    default:
      return event.type
  }
}

export const EventsTable: React.FC = () => {
  const { id } = useParams()

  if (!id) {
    return null
  }

  const [{ fetching, data }] = useRatingCommitsQuery({ variables: { key: id } })

  const commits = useMemo(() => {
    if (fetching) return []
    if (!data) return []

    return data.rating.commits
  }, [data, fetching])

  const events = useMemo(
    () =>
      commits
        .reduce(
          (memo, commit) => {
            const newEvents = Array.from(memo)

            for (const event of commit.events) {
              newEvents.push({ commit, event })
            }
            return newEvents
          },
          [] as Array<{ commit: RatingCommit; event: RatingEvent }>,
        )
        .filter(
          (ev) =>
            ![
              "AnswerValuesChanged",
              "UploadArchived",
              "UploadsArchived",
              "KpiValueApproved",
              "AnomalyApproved",
              "AnomalyUnapproved",
              "DatafilesProcessed",
            ].includes(ev.event.type),
        ),
    [commits],
  )

  const { ref: bodyRef, width: tableBodyWidth = 1, height: tableBodyHeight = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c1Ref, width: c1Width = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c2Ref, width: c2Width = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c3Ref, width: c3Width = 1 } = useResizeObserver<HTMLDivElement>()

  const Row = useMemo(() => {
    const MemoizedRow = ({ index, style }: any) => {
      const rowData = events[events.length - 1 - index]

      const userType = userEvents.includes(rowData.event.type) ? "Kunde" : "Admin"
      const committedBy =
        rowData.event.committer === "system" ? userType : `${userType} (ID: ${rowData.event.committer})`

      return (
        <RowContainer style={style}>
          <Cell style={{ width: c1Width }}>
            <span>{new Date(rowData.commit.timestamp).toLocaleString()}</span>
          </Cell>
          <Cell style={{ width: c2Width }}>{committedBy} </Cell>
          <Cell style={{ width: c3Width }}>{eventDescription(rowData.event)}</Cell>
        </RowContainer>
      )
    }
    return MemoizedRow
  }, [c1Width, c2Width, c3Width, events])

  return (
    <Container>
      <TableHeader>
        <Column ref={c1Ref} style={{ width: "200px" }}>
          Tidspunkt
        </Column>
        <Column ref={c2Ref} style={{ width: "200px" }}>
          Bruker
        </Column>
        <Column ref={c3Ref} style={{ flex: 1 }}>
          Hendelse
        </Column>
      </TableHeader>

      <TableBody ref={bodyRef}>
        <FixedSizeList
          height={tableBodyHeight}
          width={tableBodyWidth}
          itemCount={events.length}
          itemSize={100}
          style={{ position: "absolute" }}
        >
          {Row}
        </FixedSizeList>
      </TableBody>
    </Container>
  )
}
