import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { RatingState } from "@esgt/event-store"
import { useState } from "react"
import { AdditionalQuestionRow } from "./AdditionalQuestionRow"
import { DeleteQuestionModal } from "./DeleteQuestionModal"

interface AdditionalQuestionsTableProps {
  ratingState: RatingState
}

export const AdditionalQuestionsTable: React.FC<AdditionalQuestionsTableProps> = ({ ratingState }) => {
  const [deleteId, setDeleteId] = useState<string>()

  return (
    <>
      <Table width="100%">
        <Thead>
          <Tr>
            <Th minWidth="300px">Tilleggsspørsmål</Th>
            <Th>Tekstsvar</Th>
            <Th>Filopplasting</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {ratingState.additionalQuestions.map((q) => (
            <AdditionalQuestionRow key={q.id} ratingState={ratingState} question={q} onDelete={setDeleteId} />
          ))}
        </Tbody>
      </Table>
      <DeleteQuestionModal isOpen={!!deleteId} questionId={deleteId} onClose={() => setDeleteId(undefined)} />
    </>
  )
}
