import { Theme } from "@chakra-ui/react"

type ColorScheme = Theme["colors"]["green"] & { 25?: string; 950?: string }

export const colors: Record<string, ColorScheme> = {
  blue: {
    50: "#E8F2FC",
    100: "#D2E5FA",
    200: "#A3C9F5",
    300: "#76AFF0",
    400: "#4794EB",
    500: "#1A79E5",
    600: "#1461B8",
    700: "#0F488A",
    800: "#0A305C",
    900: "#05182E",
  },
  green: {
    50: "#F6FAF9",
    100: "#E0F0EC",
    200: "#C8E4DE",
    300: "#ADD7CE",
    400: "#89C6B8",
    500: "#4BA792",
    600: "#41917F",
    700: "#367768",
    800: "#2C6256",
    900: "#1F463D",
    950: "#132B26",
  },
  critical: {
    25: "#FEF5F5",
    50: "#FEECEC",
    100: "#FED9DA",
    200: "#FDB3B5",
    300: "#FB8C8F",
    400: "#FA666A",
    500: "#F94045",
    600: "#C73337",
    700: "#952629",
    800: "#641A1C",
    900: "#320D0E",
    950: "#190607",
  },
  neutral: {
    25: "#FAFDFE",
    50: "#F5F7F9",
    100: "#EDF0F1",
    200: "#CACDD0",
    300: "#B3B6B7",
    400: "#959A9D",
    500: "#787F83",
    600: "#5E646A",
    700: "#394751",
    800: "#25343F",
    900: "#09171F",
    950: "#01080D",
  },
  gray: {
    50: "#F2F2F2",
    100: "#F9F9F9",
    200: "#CCCCCC",
    300: "#B3B3B3",
    400: "#999999",
    500: "#808080",
    600: "#666666",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
  primary: {
    50: "#F6FAF9",
    100: "#E0F0EC",
    200: "#C8E4DE",
    300: "#ADD7CE",
    400: "#89C6B8",
    500: "#4BA792",
    600: "#41917F",
    700: "#367768",
    800: "#2C6256",
    900: "#1F463D",
    950: "#132B26",
  },
}
