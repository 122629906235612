import { WarningTwoIcon } from "@chakra-ui/icons"
import { Text } from "@chakra-ui/react"
import { ReactNode } from "react"

interface WarningProps {
  level: "error" | "warning"
  children: ReactNode
}

export const Warning = ({ level, children }: WarningProps) => {
  return (
    <Text color={level === "error" ? "red" : "darkorange"}>
      <WarningTwoIcon marginTop={-1} /> {children}
    </Text>
  )
}

interface MissingWarningProps {
  level: "error" | "warning"
  label: string
  children?: ReactNode
}

export const MissingWarning = ({ children, label, level }: MissingWarningProps) => {
  if (children) {
    return <>{children}</>
  }

  return (
    <Warning level={level}>
      Mangler: <em>{label}</em>
    </Warning>
  )
}
