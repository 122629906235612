import { Flex, FlexProps } from "@chakra-ui/react"

export const ContentBox = ({ children, ...flexProps }: FlexProps) => {
  return (
    <Flex
      bg={"white"}
      direction={"column"}
      borderRadius="lg"
      borderWidth="1px"
      borderColor="blackAlpha.200"
      padding={8}
      {...flexProps}
    >
      {children}
    </Flex>
  )
}
