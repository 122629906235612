import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { MinimalContactInfo, User } from "lib/generated/graphql"
import { useSet } from "lib/helpers/useSet"
import { useCallback, useMemo, useState } from "react"
import { useBoolean } from "usehooks-ts"
import { EditPrimaryContactModal } from "../EditPrimaryContactModal"
import { ExistingContactPersonRow } from "./ExistingContactPersonRow"
import { NewContactPersonRow } from "./NewContactPersonRow"

interface EditContactPersonsModalProps {
  contactPersons: Array<User>
  primaryContactEmail: string
  onClose: () => void
  onSubmit: (removedUserIds: Set<number>, addedContacts: Array<MinimalContactInfo>) => void
  refetchContacts: () => void
}

export const EditContactPersonsModal = ({
  contactPersons,
  primaryContactEmail,
  onSubmit,
  onClose,
  refetchContacts,
}: EditContactPersonsModalProps) => {
  const [wipNewContacts, setWipNewContacts] = useState<Array<ContactInput>>([])

  const [removedIds, removeId, restoreId] = useSet<number>()

  const contactPersonChanged = useCallback((index: number, contact: ContactInput) => {
    setWipNewContacts((contacts) => {
      return contacts.map((c, i) => (index === i ? contact : c))
    })
  }, [])

  const isValid = useMemo(
    () => wipNewContacts.every((contact) => contact.isValid || (!contact.email && !contact.name)),
    [wipNewContacts],
  )

  const addContact = useCallback(() => {
    setWipNewContacts([...wipNewContacts, { name: "", email: "", isValid: false }])
  }, [wipNewContacts, setWipNewContacts])

  const handleSubmit = useCallback(() => {
    const hasRemovedSome = removedIds.size > 0
    const hasAddedSome = wipNewContacts.filter((c) => c.isValid).length > 0

    if (hasRemovedSome || hasAddedSome) {
      onSubmit(
        removedIds,
        wipNewContacts.map(({ name, email }) => ({ name, email })),
      )
    }

    onClose()
  }, [onClose, onSubmit, removedIds, wipNewContacts])

  const { value: isEditingPrimaryContact, setTrue: startEditingPrimaryContact } = useBoolean(false)

  if (isEditingPrimaryContact) {
    return <EditPrimaryContactModal onClose={onClose} refetchContacts={refetchContacts} />
  }

  return (
    <Modal isOpen onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit}>
        <ModalHeader>Kontaktpersoner</ModalHeader>

        <ModalCloseButton />

        <ModalBody display="flex" flexDirection="column" gap={4} alignItems="flex-start">
          <Table size="sm">
            <Thead>
              <Tr>
                <Th paddingLeft={1}>Navn</Th>
                <Th>Epost</Th>
                <Th />
              </Tr>
            </Thead>

            <Tbody>
              {contactPersons.map((user) => (
                <ExistingContactPersonRow
                  key={`user-${user.id}`}
                  isPrimaryContact={user.email === primaryContactEmail}
                  user={user}
                  isRemoved={removedIds.has(user.id)}
                  onRemove={() => removeId(user.id)}
                  onRestore={() => restoreId(user.id)}
                />
              ))}

              {wipNewContacts.map((contact, index) => (
                <NewContactPersonRow
                  key={`new-contact-${index}`}
                  index={index}
                  contact={contact}
                  contactChanged={contactPersonChanged}
                />
              ))}
            </Tbody>
          </Table>

          <Button variant="ghost" onClick={addContact}>
            Legg til ny
          </Button>
        </ModalBody>

        <ModalFooter gap="4">
          <Button variant="outline" onClick={startEditingPrimaryContact}>
            Endre hovedkontaktperson
          </Button>

          <Button variant="outline" onClick={onClose}>
            Avbryt
          </Button>

          <Button colorScheme="green" onClick={handleSubmit} isDisabled={!isValid}>
            Fullfør
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export type ContactInput = MinimalContactInfo & { isValid: boolean }
