import { AccountsQuery } from "lib/generated/graphql"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { FixedSizeList } from "react-window"
import styled from "styled-components"
import useResizeObserver from "use-resize-observer"

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
`

const TableHeader = styled.div`
	display: flex;
	flex-flow: row nowrap;
	grid-gap: 16px;
	height: 55px;
	align-items: center;
	padding: 0 16px;
	font-weight: var(--chakra-fontWeights-heading);
	font-family: var(--chakra-fonts-heading);
	text-transform: uppercase;
	font-size: 14px;
	color: var(--chakra-colors-blackAlpha-600);
	border-bottom: 1px solid var(--chakra-colors-blackAlpha-200);
`

const TableBody = styled.div`
	flex: 1;
	overflow: hidden;
	line-height: 1.3;
`

const Cell = styled.div``

const Column = styled(Cell)``

const RowContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	border-bottom: 1px solid #f1f1f1;
	box-sizing: border-box;
	align-items: center;
	grid-gap: 16px;
	padding: 0 16px;
	cursor: pointer;

	&:hover {
		background-color: #bae7ff33;
	}
`

export const OverviewTable: React.FC<{ data?: AccountsQuery }> = ({ data }) => {
  const navigate = useNavigate()

  const { ref: bodyRef, width: tableBodyWidth = 1, height: tableBodyHeight = 1 } = useResizeObserver<HTMLDivElement>()
  const { ref: c1Ref, width: c1Width = 10 } = useResizeObserver<HTMLDivElement>()
  const { ref: c2Ref, width: c2Width = 10 } = useResizeObserver<HTMLDivElement>()
  const { ref: c3Ref, width: c3Width = 10 } = useResizeObserver<HTMLDivElement>()
  const { ref: c4Ref, width: c4Width = 10 } = useResizeObserver<HTMLDivElement>()

  const Row = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      ({ index, style }: any) => {
        const rowData = data.accounts[index]
        return (
          <RowContainer style={style} onClick={() => navigate(`${rowData.id}`)}>
            <Cell style={{ width: c1Width }}>
              <span>{rowData.id}</span>
            </Cell>
            <Cell style={{ width: c2Width }}>{rowData.title}</Cell>
            <Cell style={{ width: c3Width }}>{rowData.numOfUsers}</Cell>
            <Cell style={{ width: c4Width }}>{rowData.numOfRatings}</Cell>
          </RowContainer>
        )
      },
    [c1Width, c2Width, c3Width, c4Width, data, navigate],
  )

  return (
    <Container>
      <TableHeader>
        <Column ref={c1Ref} style={{ flex: 0.8 }}>
          ID
        </Column>
        <Column ref={c2Ref} style={{ flex: 2 }}>
          Tittel
        </Column>
        <Column ref={c3Ref} style={{ flex: 2 }}>
          Antall brukere
        </Column>
        <Column ref={c4Ref} style={{ flex: 2 }}>
          Antall målinger
        </Column>
      </TableHeader>

      <TableBody ref={bodyRef}>
        <FixedSizeList
          height={tableBodyHeight}
          width={tableBodyWidth}
          itemCount={data?.accounts.length || 0}
          itemSize={60}
          style={{ position: "absolute" }}
        >
          {Row}
        </FixedSizeList>
      </TableBody>
    </Container>
  )
}
