import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Tooltip, TooltipProps } from "@chakra-ui/react"

type InfoTooltipProps = Omit<TooltipProps, "children">

export const InfoTooltipIcon: React.FC<InfoTooltipProps> = (props) => {
  return (
    <>
      <span style={{ whiteSpace: "nowrap" }}> </span>
      <Tooltip
        placement="top"
        fontSize="md"
        lineHeight="20px"
        padding="12px 16px"
        borderRadius="4px"
        background="gray.900"
        {...props}
      >
        <InfoOutlineIcon verticalAlign="-15%" />
      </Tooltip>
    </>
  )
}
