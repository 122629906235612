import { Icon, IconProps } from "@chakra-ui/react"

export const HeadingTooltipIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon width="32px" height="34px" viewBox="0 0 32 34" fill="none" {...props}>
      <title>Help</title>
      <path
        d="M14.6667 12H17.3334V9.33332H14.6667M16.0001 26.6667C10.1201 26.6667 5.33341 21.88 5.33341 16C5.33341 10.12 10.1201 5.33332 16.0001 5.33332C21.8801 5.33332 26.6667 10.12 26.6667 16C26.6667 21.88 21.8801 26.6667 16.0001 26.6667ZM16.0001 2.66666C14.2491 2.66666 12.5153 3.01153 10.8976 3.6816C9.27996 4.35166 7.81011 5.33379 6.57199 6.5719C4.07151 9.07238 2.66675 12.4638 2.66675 16C2.66675 19.5362 4.07151 22.9276 6.57199 25.4281C7.81011 26.6662 9.27996 27.6483 10.8976 28.3184C12.5153 28.9884 14.2491 29.3333 16.0001 29.3333C19.5363 29.3333 22.9277 27.9286 25.4282 25.4281C27.9287 22.9276 29.3334 19.5362 29.3334 16C29.3334 14.249 28.9885 12.5152 28.3185 10.8975C27.6484 9.27987 26.6663 7.81001 25.4282 6.5719C24.1901 5.33379 22.7202 4.35166 21.1025 3.6816C19.4849 3.01153 17.751 2.66666 16.0001 2.66666M14.6667 22.6667H17.3334V14.6667H14.6667V22.6667Z"
        fill="#09171F"
      />
    </Icon>
  )
}
