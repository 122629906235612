import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { MinimalContactInfo } from "@esgt/types"
import { FadedLabel } from "../FadedLabel"
import { RatingContactInfo } from "../orderInfo/CompanyInfoForm"
import { MissingWarning } from "./Warning"

interface ContactPersonsSummaryProps {
  nonEmptyContributors: Array<MinimalContactInfo>
  contactPerson: RatingContactInfo
}

export const ContactPersonsSummary = ({ nonEmptyContributors, contactPerson }: ContactPersonsSummaryProps) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th paddingLeft={0}>Navn</Th>
          <Th>E-post</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        <ContributorSummaryRow contributor={contactPerson} label="Hovedkontaktperson" />

        {nonEmptyContributors.map((contributor, index) => (
          <ContributorSummaryRow contributor={contributor} key={index} />
        ))}
      </Tbody>
    </Table>
  )
}

interface ContributorSummaryRowProps {
  contributor: MinimalContactInfo
  label?: string
}

const ContributorSummaryRow = ({ contributor, label }: ContributorSummaryRowProps) => {
  return (
    <Tr>
      <Td paddingLeft={0}>
        <MissingWarning level="error" label="navn">
          {contributor.name}
        </MissingWarning>
      </Td>

      <Td>
        <MissingWarning level="error" label="e-post">
          {contributor.email}
        </MissingWarning>
      </Td>

      <Td>
        <FadedLabel>{label}</FadedLabel>
      </Td>
    </Tr>
  )
}
