import { Heading } from "@chakra-ui/react"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { RatingProfileQuery, useRatingProfileQuery } from "lib/generated/graphql"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { UseQueryState } from "urql"
import { Revisions } from "./Revisions"

export const RatingProfileHeader: React.FC = () => {
  const { ratingProfileId } = useParams()

  const [query] = useRatingProfileQuery({
    variables: { id: Number(ratingProfileId) },
    requestPolicy: "network-only",
  })

  useBreadcrumb(makeLabel(query), `/admin/rating-profiles/${ratingProfileId}`)

  return (
    <Header>
      <SiteTitle title={`Målingsprofil: ${query.data?.ratingProfile.name}`} />
      <div style={{ maxWidth: 400 }}>
        <Heading as="h1" style={{ fontSize: "32px", marginBottom: "2px" }}>
          {query.data?.ratingProfile.name}
        </Heading>
        <p>{query.data?.ratingProfile?.description || "..."}</p>
      </div>

      <Revisions />
    </Header>
  )
}

const Header = styled.div`
	padding: 48px 0 16px 0;
	justify-content: space-between;
	gap: 16px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`

function makeLabel(query: UseQueryState<RatingProfileQuery>) {
  if (query.fetching) {
    return ""
  }

  const name = query.data?.ratingProfile.name
  const description = query.data?.ratingProfile.description

  if (name) {
    if (description) {
      return `${name}${description ? `: ${description}` : ""}`
    }

    return name
  }

  return "Måling"
}
