import styled from "styled-components"

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { BusinessArea, BusinessAreaNames } from "@esgt/types"
import { NewItem } from "components/NewItem"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { useNewRatingProfileMutation, useRatingProfilesQuery } from "lib/generated/graphql"
import { useEffect } from "react"
import { Controller, FieldPath, FormProvider, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { ClassificationSelector } from "./ClassificationSelector"
import { MethodVersionSelector } from "./MethodVersionSelector"
import { MethodVersionSelection } from "./MethodVersionSelector/MethodVersionSelector"

const Form = styled.form`
	overflow: visible;
	display: flex;
	flex-flow: column nowrap;
	gap: 16px;
`

export interface FormValues {
  profileType: "CODE" | "CUSTOM"
  method: MethodVersionSelection
  isBaseline: boolean
  existingProfileId?: number
  businessArea: BusinessArea
  name?: string
  description?: string
  selectedClassification?: { code: string; name: string }
}

export function New() {
  useBreadcrumb("Opprett målingsprofil", "/admin/rating-profiles/new")

  const formMethods = useForm<FormValues>({
    defaultValues: {
      profileType: "CODE",
      businessArea: BusinessArea.SMBS,
      isBaseline: false,
    },
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = formMethods

  const profileType = watch("profileType")
  const selectedClassification = watch("selectedClassification")

  const [existingRatingProfiles] = useRatingProfilesQuery()
  const [createRatingProfileResult, createRatingProfile] = useNewRatingProfileMutation()
  const navigate = useNavigate()

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const keysToWatch: Array<FieldPath<FormValues>> = ["isBaseline", "method", "businessArea"]
      if (keysToWatch.includes(name) && type === "change") {
        if (value.isBaseline && value.method && value.businessArea) {
          setValue("name", `BASELINE_${value.method.name}_${value.businessArea}`)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmit = async ({
    businessArea,
    isBaseline,
    method,
    profileType,
    description,
    existingProfileId,
    name,
    selectedClassification,
  }: FormValues) => {
    const res = await createRatingProfile({
      name: profileType === "CUSTOM" ? name : selectedClassification.code,
      description: profileType === "CUSTOM" ? description : selectedClassification.name,
      businessArea,
      isBaseline,
      methodId: method.id,
      existingProfileId: existingProfileId ?? undefined,
    })

    if (res.data) {
      navigate(`../${res.data.createRatingProfile.id}`, { replace: true })
    }
  }

  return (
    <NewItem headingText="Opprett målingsprofil">
      <SiteTitle title="Opprett målingsprofil" />
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="profileType"
            control={control}
            render={({ field: { onChange, ref: _, ...field } }) => (
              <RadioGroup {...field} onChange={(value) => onChange({ target: { value } })} mb="2">
                <Stack spacing={6} direction="row">
                  <Radio value="CODE">Basert på næringskode</Radio>
                  <Radio value="CUSTOM">Egendefinert</Radio>
                </Stack>
              </RadioGroup>
            )}
          />

          {profileType === "CODE" && (
            <FormControl isInvalid={!!errors.selectedClassification}>
              {selectedClassification ? (
                <Box
                  p={5}
                  shadow="sm"
                  borderWidth="1px"
                  flexFlow="row nowrap"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="10px"
                >
                  <div>
                    <Heading fontSize="xl">{selectedClassification.code}</Heading>
                    <Text mt={1} fontSize="xs">
                      {selectedClassification.name}
                    </Text>
                  </div>
                  <Button colorScheme="purple" onClick={() => setValue("selectedClassification", undefined)}>
                    Endre
                  </Button>
                </Box>
              ) : (
                <ClassificationSelector />
              )}
              {errors.selectedClassification && <FormErrorMessage>Næringskode er påkrevd</FormErrorMessage>}
            </FormControl>
          )}

          {profileType === "CUSTOM" && (
            <>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name">Navn</FormLabel>
                <Input id="name" placeholder="Navn" autoFocus={true} {...register("name", { required: true })} />
                {errors.name && <FormErrorMessage>Navn er påkrevd</FormErrorMessage>}
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="description">Beskrivelse</FormLabel>
                <Textarea id="description" placeholder="Beskrivelse" {...register("description")} />
              </FormControl>
            </>
          )}

          <FormControl mb="2">
            <FormLabel>Forretningsområde</FormLabel>
            <Controller
              control={control}
              name="businessArea"
              render={({ field: { onChange, ref: _, ...field } }) => (
                <RadioGroup {...field} onChange={(value) => onChange({ target: { value } })}>
                  <Stack spacing={6} direction="row">
                    <Radio value={BusinessArea.SMBS}>{BusinessAreaNames[BusinessArea.SMBS]}</Radio>
                    <Radio value={BusinessArea.BUILDINGS}>{BusinessAreaNames[BusinessArea.BUILDINGS]}</Radio>
                    <Radio value={BusinessArea.CITIES}>{BusinessAreaNames[BusinessArea.CITIES]}</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormControl mb="2" isInvalid={!!errors.method}>
            <FormLabel>Methode-versjon</FormLabel>
            <MethodVersionSelector businessArea={watch("businessArea")} />
            {errors.method && <FormErrorMessage>Methode-versjon er påkrevd</FormErrorMessage>}
          </FormControl>

          <FormControl mb="2">
            <FormLabel>Er dette baselineprofilen?</FormLabel>
            <Switch {...register("isBaseline")} />
          </FormControl>

          <FormControl mb="2">
            <FormLabel htmlFor="description">Baser på eksisterende profil?</FormLabel>
            <Select
              placeholder={
                existingRatingProfiles.fetching ? "Henter målingsprofiler..." : "Ikke baser på en eksisterende profil"
              }
              background="white"
              {...register("existingProfileId", { valueAsNumber: true })}
            >
              {existingRatingProfiles.data?.ratingProfiles.map((profile, index) => (
                <option key={index} value={profile.id}>
                  {profile.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <Flex w="100%" alignItems="center" gap="2" mt="24px" justify="end">
            <Button variant="outline" as={Link} to="..">
              Avbryt
            </Button>
            <Button colorScheme="green" isLoading={createRatingProfileResult.fetching} type="submit">
              Opprett
            </Button>
          </Flex>
        </Form>
      </FormProvider>
    </NewItem>
  )
}
