import { Button, Flex, Icon, Stack, Td, Text, Tr } from "@chakra-ui/react"
import { RatingState } from "@esgt/event-store"
import { MarkdownText } from "components/MarkdownText"
import { formatValue } from "lib/helpers/formatValue"
import { useMemo } from "react"
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"

interface Props {
  ratingState: RatingState
  question: RatingState["additionalQuestions"][number]
  onDelete: (id: string) => void
}

export const AdditionalQuestionRow: React.FC<Props> = ({ ratingState, question, onDelete }) => {
  const formattedAnswer = useMemo(
    () => (ratingState.answerValues[question.id] ? formatValue(ratingState.answerValues[question.id].input_0) : ""),
    [ratingState.answerValues[question.id]],
  )

  const uploads = useMemo(
    () =>
      ratingState?.uploads?.filter(
        (upload) => upload.ratingFileType === "generic" && upload.scope === `questions/${question.id}`,
      ),
    [ratingState?.uploads, question.id],
  )

  return (
    <Tr>
      <Td color="gray.500">
        <Stack alignItems="flex-start">
          <MarkdownText markdown={`**${question.questionNumber}** - ${question.text}`} />

          <Text fontSize="14px">{question.helpText}</Text>

          {ratingState.answerValues[question.id] && (
            <Text minWidth="200px" fontSize="14px" color="green.600">
              {formattedAnswer}
            </Text>
          )}

          <Flex flexFlow="row wrap" gap="16px">
            {uploads?.map((upload) => (
              <Text fontSize="14px" fontStyle="italic" key={upload.uploadId}>
                {upload.filename}
              </Text>
            ))}
          </Flex>
        </Stack>
      </Td>
      <Td>
        <Icon as={question.textInput ? MdCheckBox : MdCheckBoxOutlineBlank} color="gray.400" boxSize="21px" />
      </Td>
      <Td>
        <Icon as={question.uploads ? MdCheckBox : MdCheckBoxOutlineBlank} color="gray.400" boxSize="21px" />
      </Td>
      <Td>
        <Button minWidth={0} size="sm" variant="outline" aria-label="Slett" onClick={() => onDelete(question.id)}>
          Slett
        </Button>
      </Td>
    </Tr>
  )
}
