import { Box, Heading } from "@chakra-ui/react"
import { KpiCategory } from "@esgt/types"
import ecoPageImg from "assets/report-result-eco.jpg"
import envPageImg from "assets/report-result-env.jpg"
import socPageImg from "assets/report-result-soc.jpg"
import frontpageImg from "assets/report-result.jpg"
import { useRating } from "lib/providers/RatingProvider"
import React from "react"
import { FreeVersionWatermark, Page } from "../components"
import { KPI } from "./KPI"

export const KPIs: React.FC = () => {
  const { ratingProfile, method } = useRating()

  if (!ratingProfile || !method) return null

  return (
    <>
      <Page id="kpis">
        <Heading mb="8mm" fontWeight={500}>
          RESULTAT PER INDIKATOR
        </Heading>
        <Box fontWeight={500} mb="16px">
          Bærekraftmålingen i denne rapporten baserer seg på tre dimensjoner; den økonomiske dimensjonen,
          miljødimensjonen og den sosiale dimensjonen. Sammen representerer disse tre dimensjonene bærekraft. Bærekraft
          er også ansvarlig virksomhetsstyring.
        </Box>
        <Box mb="16px">
          Videre presenteres virksomhetens resultat for hver indikator i det forhåndsdefinerte indikatorsettet.
          Bakgrunnen for indikatorenes relevans redegjøres for, sammen med indikatorens definisjon og resultat.
          Resultatet er sammenstilt med måltall. Virksomhetens referanse tall blir dermed måltall for virksomhetens
          bransje der det eksisterer. Ved mangel av måltall, kan tall fra virksomhetens første måling være referanse
          tall for neste måling.
        </Box>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={frontpageImg} alt="" />
        </Box>
      </Page>
      <Page id="kpi-eco">
        <Heading mb="8mm" fontWeight={500}>
          DEN ØKONOMISKE DIMENSJONEN
        </Heading>
        <Box fontWeight={500} mb="16px">
          Den økonomiske dimensjonen indikerer virksomhetens lønnsomhet, likvide forhold og soliditet. Kategorienes
          indikatorer belyser følgende;
        </Box>
        <Box mb="16px">
          - Om virksomheten tjener penger i sitt virke
          <br />- Om det er sannsynlig at virksomheten kan betale sine forpliktelser
          <br />- Hvor stor andel av virksomheten som eies av aksjonærer
          <br />- Hvordan virksomheten er finansiert
          <br />- Om det er sannsynlig at virksomheten kan håndtere nedgangstider
        </Box>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={ecoPageImg} alt="" />
        </Box>
      </Page>
      <Page>
        {method.config.kpiDimensions[0].categories.map((category: KpiCategory) => (
          <React.Fragment key={category.id}>
            {category.kpiIds.map((kpiId) => (
              <KPI kpi={method.kpisById[kpiId]} category={category} key={kpiId} />
            ))}
          </React.Fragment>
        ))}
      </Page>

      <Page id="kpi-env">
        <Heading mb="8mm" fontWeight={500}>
          MILJØDIMENSJONEN
        </Heading>
        <Box fontWeight={500} mb="16px">
          Miljødimensjonen indikerer virksomhetens ressurseffektivitet, sirkulærøkonomi, utslipp og miljøinitiativ.
          Kategorienes indikatorer belyser følgende;
        </Box>
        <Box mb="16px">
          - Virksomhetens utnyttelse av ressursene strøm og vann
          <br />- Avfallsproduksjon og avfallshåndtering
          <br />- Virksomhetens arbeid med å begrense utslipp
          <br />- Virksomhetens deltakelse i utvikling av miljøet
        </Box>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={envPageImg} alt="" />
        </Box>
      </Page>
      <Page>
        {method.config.kpiDimensions[1].categories.map((category: KpiCategory) => (
          <React.Fragment key={category.id}>
            {category.kpiIds.map((kpiId) => (
              <KPI kpi={method.kpisById[kpiId]} category={category} key={kpiId} />
            ))}
          </React.Fragment>
        ))}
      </Page>

      <Page id="kpi-soc">
        <Heading mb="8mm" fontWeight={500}>
          DEN SOSIALE DIMENSJONEN
        </Heading>
        <Box fontWeight={500} mb="16px">
          Den sosiale dimensjonen indikerer virksomhetens likestilling, balansert lønn, arbeidsforhold,
          verdikjedeansvar, virksomhetsstyring og eksternaliteter. Kategorienes indikatorer belyser følgende;
        </Box>
        <Box mb="16px">
          - Virksomhetens kjønns- og aldersfordeling
          <br />- Lønnspraksis
          <br />- Sosiale tendenser
          <br />- Bidrag til utvikling av samfunnet
        </Box>
        <Box position="relative">
          <FreeVersionWatermark />
          <img width={"100%"} src={socPageImg} alt="" />
        </Box>
      </Page>
      <Page>
        {method.config.kpiDimensions[2].categories.map((category: KpiCategory) => (
          <React.Fragment key={category.id}>
            {category.kpiIds.map((kpiId) => (
              <KPI kpi={method.kpisById[kpiId]} category={category} key={kpiId} />
            ))}
          </React.Fragment>
        ))}
      </Page>
    </>
  )
}
