import { Box, Heading, Text } from "@chakra-ui/react"
import frontpageImg from "assets/report-frontpage.jpg"
import { Logo } from "components/Logo"
import { useRatingAccount } from "lib/providers/RatingAccount"
import { useRating } from "lib/providers/RatingProvider"
import { FreeVersionWatermark } from "./components"
import { Page } from "./components"

export const Frontpage: React.FC = () => {
  const { ratingState, isFree } = useRating()
  const { accountTitle } = useRatingAccount()

  const brreg = ratingState?.brreg

  return (
    <Page>
      {isFree && (
        <Heading mb={"2mm"} fontWeight={300} fontSize={"14pt"} textAlign={"center"}>
          GRATIS -{" "}
          <Text as="span" fontWeight={600}>
            UFULLSTENDIG, IKKE VERIFISERT
          </Text>
        </Heading>
      )}
      <Heading fontWeight={500} mb={"4mm"} fontSize={"30pt"} textAlign={"center"}>
        BÆREKRAFTRAPPORT
      </Heading>
      <Box textAlign={"center"} fontSize="12pt" mb="8mm" fontWeight="400">
        PRODUSERT AV {accountTitle} - {new Date().getFullYear()}
      </Box>
      <Box position={"relative"}>
        <FreeVersionWatermark />
        <img alt="" width={"100%"} src={frontpageImg} />
        <div style={{ position: "absolute", top: "10mm", left: "10mm" }}>
          <Logo />
        </div>
      </Box>
      <Box mt="8mm" mb="2mm" textAlign={"center"} fontSize="18pt" fontWeight="300">
        {brreg?.navn}
      </Box>
      <Box textAlign={"center"} fontSize="12pt" mb="2mm" fontWeight="300">
        {brreg?.organisasjonsnummer.substring(0, 3)} {brreg?.organisasjonsnummer.substring(3, 6)}{" "}
        {brreg?.organisasjonsnummer.substring(6, 9)}
      </Box>
      <Box textAlign={"center"} fontSize="12pt" mb="0" fontWeight="300">
        {ratingState?.period?.toPrettyString()}
      </Box>
    </Page>
  )
}
