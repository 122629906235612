import { RatingProfileConfig } from "@esgt/types"
import { useRatingProfileRevisionQuery, useUpdateRatingProfileRevisionConfigMutation } from "lib/generated/graphql"
import { useMethod, useResultToast } from "lib/hooks"
import merge from "lodash.merge"
import { useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { RevisionEditingContext } from "./RevisionEditingContext"

interface RevisionEditingProviderProps {
  children?: React.ReactNode
}

const stripStuff = (obj: Record<any, any>): any => {
  return Object.entries(obj).reduce((c, [k, v]) => {
    if (v === undefined || v === null) return c
    return Object.assign(c, { [k]: typeof v === "object" ? stripStuff(v) : v })
  }, {})
}

export const RevisionEditingProvider: React.FC<RevisionEditingProviderProps> = ({ children }) => {
  const { ratingProfileId, "*": subpath } = useParams()
  const revision = subpath?.split("/")[0]

  /**
   * Using refs to keep track of pending changes & what they are
   * without triggering re-renders & making the newest config available
   * to the recursive onSave function calls if they happen
   */
  const changesPending = useRef(false)
  const changesToSave = useRef(null)

  const [query, refetch] = useRatingProfileRevisionQuery({
    variables: { ratingProfileId: +ratingProfileId, revision },
    requestPolicy: "network-only",
    pause: !ratingProfileId || !revision,
  })

  const [saveStatus, save] = useUpdateRatingProfileRevisionConfigMutation()
  const [currentConfig, setCurrentConfig] = useState<RatingProfileConfig>()

  const fetchedConfig = query.data?.ratingProfile?.revision?.config as RatingProfileConfig

  useEffect(() => {
    if (!query.fetching && fetchedConfig) {
      setCurrentConfig(fetchedConfig)
    }
  }, [query.fetching, fetchedConfig])

  useResultToast(saveStatus, undefined, "Kunne ikke oppdatere profilen")

  const isReadOnly = useMemo(() => {
    return query.data?.ratingProfile?.activeRevisionId >= revision
  }, [query.data?.ratingProfile?.activeRevisionId, revision])

  const methodId = query.data?.ratingProfile?.revision?.methodId
  const { method } = useMethod(methodId)

  const onChange = (itemType: string, itemId: string, key: string, value: any, saveImmediately?: boolean) => {
    if (currentConfig?.[itemType]?.[itemId]?.[key] === value) return

    const mergedConfig = merge({}, currentConfig, {
      [itemType]: {
        [itemId]: {
          [key]: value !== undefined ? value : null,
        },
      },
    })

    setCurrentConfig(mergedConfig)
    changesPending.current = true
    changesToSave.current = mergedConfig

    if (saveImmediately) {
      onSave()
    }
  }

  const onSave = async () => {
    if (saveStatus.fetching) {
      return
    }

    changesPending.current = false
    const configToSave = changesToSave.current
    changesToSave.current = null

    if (configToSave) {
      await save({ config: stripStuff(configToSave), ratingProfileId: Number(ratingProfileId), revision })

      if (changesPending.current && changesToSave.current) {
        await onSave()
      }
    }
  }

  return (
    <RevisionEditingContext.Provider
      value={{
        fetching: query.fetching,
        isSaving: saveStatus.fetching,
        refetch,
        baselineConfig: query.data?.ratingProfile?.baseline.activeRevision?.config || {},
        revisionConfig: currentConfig || query.data?.ratingProfile?.revision?.config || {},
        ratingProfile: query.data?.ratingProfile,
        isReadOnly,
        method,
        isBaselineProfile: query.data?.ratingProfile?.isBaseline,
        setValue: onChange,
        onSave,
      }}
    >
      {children}
    </RevisionEditingContext.Provider>
  )
}
