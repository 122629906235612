import { RatingState } from "@esgt/event-store"
import { MethodConfig, RatingProfileConfig } from "@esgt/types"
import { AltinnProgress, altinnProgress } from "./altinnProgress"
import { QuestionsProgress, questionsProgress } from "./questionsProgress"
import { RegnskapProgress, regnskapProgress } from "./regnskapProgress"

export type SubmissionProgressState = "UNSTARTED" | "PARTIALLY_COMPLETED" | "COMPLETED"

type ProgressPerspective = {
  regnskap: RegnskapProgress
  regnskapStatus: SubmissionProgressState

  altinn: AltinnProgress
  altinnStatus: SubmissionProgressState

  questions: QuestionsProgress
  questionsStatus: SubmissionProgressState

  allCompleted: boolean
}

export const submissionProgressPerspective = (
  ratingState: RatingState,
  methodConfig: MethodConfig,
  ratingProfileConfig?: RatingProfileConfig,
): ProgressPerspective => {
  const regnskap = regnskapProgress(ratingState)

  let regnskapStatus: SubmissionProgressState

  if (Object.values(regnskap).every((v) => v)) {
    regnskapStatus = "COMPLETED"
  } else if (Object.values(regnskap).some((v) => v)) {
    regnskapStatus = "PARTIALLY_COMPLETED"
  } else {
    regnskapStatus = "UNSTARTED"
  }

  const altinn = altinnProgress(ratingState)

  let altinnStatus: SubmissionProgressState

  if (Object.values(altinn).every((v) => v)) {
    altinnStatus = "COMPLETED"
  } else if (Object.values(altinn).some((v) => v)) {
    altinnStatus = "PARTIALLY_COMPLETED"
  } else {
    altinnStatus = "UNSTARTED"
  }
  const questions = questionsProgress(ratingState, methodConfig, ratingProfileConfig)

  let questionsStatus: SubmissionProgressState

  if (Object.values(questions).every((v) => v)) {
    questionsStatus = "COMPLETED"
  } else if (Object.values(questions).some((v) => v)) {
    questionsStatus = "PARTIALLY_COMPLETED"
  } else {
    questionsStatus = "UNSTARTED"
  }

  const allCompleted = regnskapStatus === "COMPLETED" && altinnStatus === "COMPLETED" && questionsStatus === "COMPLETED"

  return {
    regnskap,
    regnskapStatus,
    altinn,
    altinnStatus,
    questions,
    questionsStatus,
    allCompleted,
  }
}
