import { Button, Heading, Spinner } from "@chakra-ui/react"
import { RatingType } from "@esgt/types"
import { CenteredContent } from "components/CenteredContent"
import { SiteTitle } from "components/SiteTitle"
import { Tabs } from "components/Tabs"
import { useRatingCountsSubscription } from "lib/generated/graphql"
import { BiPlus } from "react-icons/bi"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { FetchRatingsWrapper } from "./FetchRatingsWrapper"
import { StateFilter } from "./types"

const Header = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-end;
`
const Container = styled.div`
	margin: 70px 0;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
`

const stateFilters: StateFilter[] = [
  {
    label: "Venter grunnlag",
    value: "PENDING_BASIS",
    emptyText: "Du har ingen målinger som venter grunnlag",
    countKey: "pendingBasis",
  },
  {
    label: "Venter kontroll",
    value: "PENDING_CONTROL",
    emptyText: "Du har ingen målinger som venter kontroll",
    countKey: "pendingControl",
  },
  {
    label: "Ferdigstilt",
    value: "COMPLETED",
    emptyText: "Du har ingen ferdigstilte målinger",
    countKey: "completed",
  },
  {
    label: "Kansellert",
    value: "CANCELLED",
    emptyText: "Du har ingen kansellerte målinger",
    countKey: "cancelled",
  },
  {
    label: "Gratis",
    value: RatingType.Free,
    emptyText: "Du har ingen gratis målinger",
    countKey: "free",
  },
]

export function Overview() {
  const [searchParams, setSearchParams] = useSearchParams({ state: stateFilters[0].value })
  const currentFilter = stateFilters.find((filter) => filter.value === searchParams.get("state")) || stateFilters[0]

  const [{ fetching, data }] = useRatingCountsSubscription()

  const counts = data?.ratingCounts || { free: 0 }

  const updateSearchParam = (key: string, value?: string) => {
    if (value) {
      searchParams.set(key, value)
    } else {
      searchParams.delete(key)
    }
    setSearchParams(searchParams)
  }

  const changeFilterState = (newIndex: number) => {
    const newFilter = stateFilters[newIndex]
    updateSearchParam("state", newFilter.value === stateFilters[0].value ? undefined : newFilter.value)
  }

  const isLoading = fetching && !data

  return (
    <CenteredContent maxWidth="1378px" scrollable>
      <SiteTitle title="Målinger" />
      <Container>
        <Heading as="h1" style={{ fontSize: "24px", marginBottom: "24px" }}>
          Målinger {isLoading && <Spinner size="sm" />}
        </Heading>
        <Header>
          <Tabs
            value={currentFilter.value}
            onChange={(newIndex) => changeFilterState(newIndex)}
            tabs={stateFilters
              .map(
                (state) =>
                  (state?.value !== RatingType.Free || counts[state.countKey] > 0) && {
                    value: state.value,
                    label: `${state.label} (${counts[state.countKey] || 0})`,
                  },
              )
              .filter(Boolean)}
          />
          <Link to="new">
            <Button colorScheme="green" size="lg" leftIcon={<BiPlus />}>
              Ny måling
            </Button>
          </Link>
        </Header>

        {!isLoading && <FetchRatingsWrapper filter={currentFilter} count={counts[currentFilter.countKey]} />}
      </Container>
    </CenteredContent>
  )
}
