import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react"

type BreadcrumbType = {
  label: string
  path: string
}

type BreadcrumbLabelsByPath = Record<string, string>

type BreadcrumbContextType = {
  breadcrumbs: BreadcrumbLabelsByPath
  addBreadcrumb: (label: string, path: string) => void
  removeBreadcrumb: (label: string, path: string) => void
}

const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumbs: {},
  addBreadcrumb: () => {},
  removeBreadcrumb: () => {},
})

export function BreadcrumbProvider({ children }: { children: ReactNode }) {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbLabelsByPath>({})

  const addBreadcrumb = useCallback((label: string, path: string) => {
    setBreadcrumbs((crumbs) => {
      return { ...crumbs, [path]: label }
    })
  }, [])

  const removeBreadcrumb = useCallback((_: string, path: string) => {
    setBreadcrumbs((crumbs) => {
      const copy = { ...crumbs }
      delete copy[path]
      return copy
    })
  }, [])

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, addBreadcrumb, removeBreadcrumb }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

export function useBreadcrumb(label: string, path: string) {
  const { addBreadcrumb, removeBreadcrumb } = useContext(BreadcrumbContext)

  useEffect(() => {
    addBreadcrumb(label, path)

    return () => {
      removeBreadcrumb(label, path)
    }
  }, [label, path, addBreadcrumb, removeBreadcrumb])
}

export function useBreadcrumbs() {
  const { breadcrumbs } = useContext(BreadcrumbContext)
  return breadcrumbsByShortestFirst(breadcrumbs)
}

function breadcrumbsByShortestFirst(map: BreadcrumbLabelsByPath): Array<BreadcrumbType> {
  return Object.entries(map)
    .sort(([pathA], [pathB]) => pathA.length - pathB.length) // Shortest paths first
    .map(([path, label]) => ({ path, label }))
}
