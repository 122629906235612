import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Link, Text } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect } from "react"
import { FieldValues, useForm } from "react-hook-form"

export type AgreementSettings = {
  agreementAccepted: boolean
}

interface AgreementFormProps {
  agreementAccepted: boolean
  setAgreementValues: Dispatch<SetStateAction<AgreementSettings>>
}

export const AgreementForm = ({ agreementAccepted, setAgreementValues }: AgreementFormProps) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      agreementAccepted,
    } as FieldValues,
  })

  useEffect(() => {
    const subscription = watch((values) => {
      setAgreementValues({
        agreementAccepted: values.agreementAccepted,
      })
    })
    return () => subscription.unsubscribe()
  }, [setAgreementValues, watch])

  return (
    <Box mt="16px" bg="neutral.100" p="8" pb="6" pt="6" borderRadius="lg" borderTopRadius={0}>
      <FormControl isInvalid={!!errors.agreementAccepted}>
        <Flex gap={2} alignItems="baseline">
          <Checkbox {...register("agreementAccepted", { required: true })} />
          <FormLabel>
            <Text fontSize="lg" mb="8px">
              Jeg har lest og er enig i{" "}
              <Link target="_blank" href="https://www.esgtrackr.no/avtalevilkar" variant="underlined">
                Avtalevilkår
              </Link>
            </Text>
            <Text fontSize="xs">
              For å kunne bruke ESG Trackr må du dele, og ha rettigheter til å dele, data på vegne av virksomheten din.
              Data omfatter regnskapstall og avstemmingsrapporter, spesifisert per person (ansatte), for perioden. Les
              våre Avtalevilkår og tilhørende databehandleravtale for bruk og behandling av dataene.
            </Text>
          </FormLabel>
        </Flex>
        <FormErrorMessage>Du må godkjenne våre Avtalevilkår</FormErrorMessage>
      </FormControl>
    </Box>
  )
}
