import { CUID, RatingUpload } from "@esgt/types"

export function getUploadsByQuestionId(uploads: Array<RatingUpload>) {
  const byQuestionId: Record<CUID, Array<RatingUpload>> = {}

  for (const upload of uploads) {
    if (upload.scope.startsWith("questions/")) {
      const questionId = upload.scope.split("/")[1]
      if (byQuestionId[questionId]) {
        byQuestionId[questionId].push(upload)
      } else {
        byQuestionId[questionId] = [upload]
      }
    }
  }

  return byQuestionId
}
