import { Button, Checkbox, HStack, Td, Tr } from "@chakra-ui/react"
import { DatafileAnomaly } from "@esgt/types"
import { InfoTooltipIcon } from "components/Tooltips/InfoTooltipIcon"
import { useUnapproveAnomalyMutation } from "lib/generated/graphql"
import { prettyFormatAmount } from "lib/helpers/misc"
import { useRating } from "lib/providers/RatingProvider"
import { useNavigate } from "react-router-dom"

export const AnomalyTableRow: React.FC<{
  anomaly: DatafileAnomaly & { ok?: boolean; comment?: string }
  readOnly: boolean
  id: string
}> = ({ anomaly, readOnly, id }) => {
  const { ratingId } = useRating()
  const navigate = useNavigate()

  const [unapproveStatus, unapprove] = useUnapproveAnomalyMutation()

  const handleToggle = (newVal: boolean) => {
    if (newVal) {
      navigate(`approve-anomaly/${id}`)
    } else {
      unapprove({ ratingId, anomalyId: id })
    }
  }

  const canBeReposted = !anomaly.ok && anomaly.omposteringsRules

  return (
    <Tr>
      <Td fontSize="16px">
        {anomaly.source}: {anomaly.concerning}
      </Td>
      <Td fontSize="16px" whiteSpace={"break-spaces"}>
        {anomaly.message}
        {anomaly.amount ? `\n(${prettyFormatAmount(anomaly.amount.toNumber())})` : ""}
      </Td>
      <Td>
        <HStack gap="8px">
          <Checkbox
            value="true"
            isDisabled={readOnly || unapproveStatus.fetching || (anomaly.omposteringsRules && anomaly.ok)}
            isChecked={!!anomaly.ok}
            onChange={(e) => handleToggle(e.target.checked)}
          />
          {anomaly.comment && <InfoTooltipIcon label={anomaly.comment} />}
        </HStack>
      </Td>
      <Td whiteSpace={"pre"} textAlign="right">
        {canBeReposted && !readOnly
          ? `${prettyFormatAmount(anomaly.amount?.toNumber() || 0)} \nkan omklassifiseres`
          : "—"}
        {canBeReposted && !readOnly && (
          <div style={{ marginTop: 8 }}>
            <Button
              minWidth={0}
              variant="link"
              color="blue.500"
              onClick={() => navigate(`ompostering/${id}`)}
              size={"sm"}
            >
              Gjør omklassifisering
            </Button>
          </div>
        )}
      </Td>
    </Tr>
  )
}
