import { Box, CircularProgress, Flex, Icon, Progress, Text, chakra } from "@chakra-ui/react"
import { RatingUpload } from "@esgt/types"
import { useArchiveRatingUploadMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { MdAttachFile, MdClear } from "react-icons/md"
import { PiWarning } from "react-icons/pi"

type Props = RatingUpload & {
  onDelete?: () => void
  readOnly?: boolean
}

export const ExistingItem: React.FC<Props> = ({
  filename,
  uploadId,
  processed,
  processFailureMessages,
  processWarningMessages,
  valid,
  readOnly,
}) => {
  const { ratingId, ratingState } = useRating()
  const [archiveStatus, archive] = useArchiveRatingUploadMutation()

  const missingFields = processWarningMessages
    ?.map((e) => (e.includes("missing field") ? e.split("`")[1] : null))
    .filter((value, index, self) => value && self.indexOf(value) === index) as string[]
  const last = missingFields?.pop()
  const missingFieldsText = missingFields?.length
    ? `${missingFields.join(", ")}${last ? ` og ${last}` : ""}`
    : last || ""

  return (
    <Box maxWidth="450px">
      <Flex flexFlow={"row nowrap"} gap="8px" alignItems="center">
        <Icon boxSize="16px" as={MdAttachFile} color="gray.600" />
        <Text size="sm" color="gray.700" flex={1}>
          {filename}
        </Text>
        {archiveStatus.fetching ? (
          <CircularProgress size="16px" isIndeterminate color="red.400" />
        ) : (
          !readOnly && (
            <Icon
              boxSize="20px"
              as={MdClear}
              color="gray.600"
              display={ratingState?.phase !== "SUBMISSION" ? "none" : "block"}
              cursor="pointer"
              onClick={() => archive({ ratingId, uploadId })}
            />
          )
        )}
      </Flex>

      <Progress
        hasStripe={!processed}
        isAnimated={!processed}
        value={100}
        size="xs"
        mt="2"
        colorScheme={processed ? (valid ? (missingFieldsText ? "yellow" : "green") : "red") : "blue"}
      />
      {!processed && (
        <Text size="xs" mt="6px">
          Filen prosesseres. Du kan fortsette å jobbe med andre ting mens dette foregår.
        </Text>
      )}
      {processFailureMessages && processFailureMessages.length > 0 && (
        <Text size="xs" color="red.600" mt="6px">
          {processFailureMessages.join(", ")}
        </Text>
      )}
      {!processFailureMessages && missingFieldsText && (
        <Box mt="6px">
          <Icon boxSize="15px" as={PiWarning} color="yellow.600" mr="3px" float="left" />
          <Text size="xs">
            <chakra.span color="yellow.600">Advarsel: </chakra.span>
            A07 mangler {missingFieldsText} for arbeidtaker(e).
            <br />
            Dette resulterer i feil utregning av likestillings- og lønnsforhold.
          </Text>
        </Box>
      )}
    </Box>
  )
}
