import { InfoIcon } from "@chakra-ui/icons"
import { Tag, TagLabel, TagLeftIcon, ThemeTypings } from "@chakra-ui/react"
import { ReactNode } from "react"

interface InfoTagProps {
  children: ReactNode
  colorScheme?: ThemeTypings["colorSchemes"]
}

export const InfoTag = ({ children, colorScheme }: InfoTagProps) => {
  return (
    <Tag ml={2} size={"md"} width="min-content" variant="subtle" colorScheme={colorScheme || "linkedin"}>
      <TagLeftIcon boxSize="12px" as={InfoIcon} />
      <TagLabel>{children}</TagLabel>
    </Tag>
  )
}
