import { FormControl, FormControlProps, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { useCallback } from "react"
import { FieldErrors, FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetError } from "react-hook-form"
import { PHONE_PATTERN_SIMPLE, validatePhone } from "./phoneNumberValidation"

const PHONE_ERROR_MESSAGE = "Ugyldig telefonnummer"

interface PhoneInputProps extends FormControlProps {
  inputKey: string
  errors?: FieldErrors
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  register: UseFormRegister<FieldValues>
  errorMessage?: string
  value?: string
  onUpdate?: (value: string) => void
  acceptEmpty?: boolean
}

export function PhoneInput({
  inputKey,
  errors,
  setError,
  clearErrors,
  register,
  errorMessage = PHONE_ERROR_MESSAGE,
  value,
  acceptEmpty,
  ...formControlProps
}: PhoneInputProps) {
  const error = errors[inputKey]

  const handlePhoneInputBlur = useCallback(
    (event) => {
      const newValue = event.currentTarget.value
      const isValid = validatePhone(newValue) || (newValue === "" && acceptEmpty)

      if (!isValid) {
        setError(inputKey, { message: errorMessage, type: "pattern" })
      } else {
        clearErrors(inputKey)
      }
    },
    [setError, inputKey, errorMessage, clearErrors, acceptEmpty],
  )

  return (
    <FormControl {...formControlProps} isInvalid={error !== undefined}>
      <FormLabel htmlFor="phone">Telefonnummer</FormLabel>

      <Input
        id="phone"
        type="text"
        background="white"
        placeholder="Telefonnummer"
        {...register(inputKey, {
          pattern: { value: PHONE_PATTERN_SIMPLE, message: errorMessage },
          onBlur: handlePhoneInputBlur,
          value,
        })}
      />

      {error && <FormErrorMessage>{error.message.toString()}</FormErrorMessage>}
    </FormControl>
  )
}
