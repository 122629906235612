import { Icon, IconProps } from "@chakra-ui/react"

export const IconList: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <title>List</title>
      <path
        d="M21.5001 4H19.3334V5.33333H21.3334V20H2.66676V5.33333H4.66676V4H2.50009C2.34425 4.0026 2.19045 4.03588 2.04747 4.09794C1.90449 4.15999 1.77514 4.24961 1.6668 4.36166C1.55846 4.47371 1.47325 4.60601 1.41605 4.751C1.35885 4.89599 1.33077 5.05083 1.33342 5.20667V20.1267C1.33077 20.2825 1.35885 20.4373 1.41605 20.5823C1.47325 20.7273 1.55846 20.8596 1.6668 20.9717C1.77514 21.0837 1.90449 21.1733 2.04747 21.2354C2.19045 21.2974 2.34425 21.3307 2.50009 21.3333H21.5001C21.6559 21.3307 21.8097 21.2974 21.9527 21.2354C22.0957 21.1733 22.225 21.0837 22.3334 20.9717C22.4417 20.8596 22.5269 20.7273 22.5841 20.5823C22.6413 20.4373 22.6694 20.2825 22.6668 20.1267V5.20667C22.6694 5.05083 22.6413 4.89599 22.5841 4.751C22.5269 4.60601 22.4417 4.47371 22.3334 4.36166C22.225 4.24961 22.0957 4.15999 21.9527 4.09794C21.8097 4.03588 21.6559 4.0026 21.5001 4V4Z"
        fill="currentColor"
      />
      <path d="M5.33325 8.5H7.66325V9.83333H5.33325V8.5Z" fill="currentColor" />
      <path d="M5.33325 11.8335H7.66325V13.1668H5.33325V11.8335Z" fill="currentColor" />
      <path d="M5.33325 15.167H7.66325V16.5003H5.33325V15.167Z" fill="currentColor" />
      <path d="M10.3301 8.5H18.6601V9.83333H10.3301V8.5Z" fill="currentColor" />
      <path d="M10.3301 11.8335H18.6601V13.1668H10.3301V11.8335Z" fill="currentColor" />
      <path d="M10.3301 15.167H18.6601V16.5003H10.3301V15.167Z" fill="currentColor" />
      <path d="M4.66675 4H20.1667V5.33333H4.66675V4Z" fill="currentColor" />
    </Icon>
  )
}
