import {
  ProcessingStatus,
  RatingDatapoints,
  RatingFileCategory,
  RatingType,
  SaftDatapoints,
  UploadScopeEnum,
} from "@esgt/types"
import dayjs from "dayjs"
import { produce } from "immer"
import { BigNumber, bignumber, evaluate, reviver } from "mathjs"
import { AccountingPeriod, AccountingPeriodRange } from "./AccountingPeriod"
import { transformDatapointInput } from "./inputProcessing/transformDatapointInput"
import { calculateOmpostering } from "./ompostering"
import { RatingCommit, RatingEvent, RatingState } from "./types"

export const ratingStateReducer = (
  currentState: RatingState | undefined,
  commit: RatingCommit,
  event: RatingEvent,
): RatingState => {
  if (!currentState) {
    if (event.type === "RatingCreated") {
      const { type: _, ...eventParams } = event
      return {
        phase: "SUBMISSION",
        datapoints: {} as SaftDatapoints,
        approvedKpis: {},
        overriddenKpisById: {},
        ...eventParams,
        genericFiles: {},
        uploads: [],
        answerValues: {},
        additionalQuestions: [],
        anomalies: [],
        earlierAnomalies: [],
        ratingComment: "",
        ratingType: eventParams.ratingType || RatingType.Full,
        dataFilesProcessingStatus: {},
      }
    }
    throw new Error(`Missing state when we encountered ${event.type}`)
  }

  switch (event.type) {
    case "BrregInformationRetrieved": {
      return produce(currentState, (ns) => {
        ns.brreg = event.data
        const periodEnd = dayjs().subtract(1, "month").endOf("month")
        const firstAvailable = dayjs(event.data.stiftelsesdato).add(1, "month").startOf("month")
        const fullYear = dayjs(periodEnd).subtract(11, "months").startOf("month")

        const periodStart = firstAvailable > fullYear ? firstAvailable : fullYear

        ns.period = new AccountingPeriodRange(new AccountingPeriod(periodStart), new AccountingPeriod(periodEnd))
      })
    }

    case "DatapointsSet": {
      return produce(currentState, (ns) => {
        for (const key of Object.keys(event.datapoints) as Array<keyof RatingDatapoints>) {
          const val = event.datapoints[key]
          if (val === null || val === "") {
            delete ns.datapoints[key]
          }
          // if an event datapoint value is undefined, don't overwrite value
          else if (val !== undefined) {
            ns.datapoints[key] = bignumber(val)
          }
        }
      })
    }

    case "KpiValueApproved": {
      return produce(currentState, (ns) => {
        ns.approvedKpis[event.kpiId] = event.approved
      })
    }

    case "KpiValueOverridden": {
      return produce(currentState, (ns) => {
        ns.overriddenKpisById[event.kpiId] = { value: event.value, reason: event.reason }
      })
    }

    case "KpiValueOverrideRemoved": {
      return produce(currentState, (ns) => {
        delete ns.overriddenKpisById[event.kpiId]
      })
    }

    case "Ompostering": {
      return produce(currentState, (ns) => {
        /*
					Config for which omposterings that can be done is set in core/src/models/CompanyRating/dataFileProcessing/SAFT.ts
					Ompostering is always done to a datapoint that can be negative/positive and vice/verca is added/subtracted in a from-datapoint if set
					Both the amount and the datapoint can be positive/negative, and calculateOmpostering handles all of the options that can occur.

					NOTE:
					If this needs to be changed, do it with care as this is set ut to be in correct according to norwegian accounting-standards
				*/

        ns.datapoints[event.omposteringsRule.to] = calculateOmpostering(
          ns.datapoints[event.omposteringsRule.to] as BigNumber,
          bignumber(event.amount),
        )

        if (event.omposteringsRule.from) {
          ns.datapoints[event.omposteringsRule.from] = calculateOmpostering(
            ns.datapoints[event.omposteringsRule.from] as BigNumber,
            bignumber(event.amount),
          )
        }

        if (event.omposteringsRule.affectedDatapoints.length > 0) {
          for (const affectedDatapoints of event.omposteringsRule.affectedDatapoints) {
            ns.datapoints[affectedDatapoints] = calculateOmpostering(
              ns.datapoints[affectedDatapoints] as BigNumber,
              bignumber(event.amount),
            )
          }
        }
        if (event.anomalyId) {
          const anomaly = ns.anomalies[parseInt(event.anomalyId, 10)]
          anomaly.ok = true
          anomaly.comment = `Omklassifisert til ${event.omposteringsRule.to}`
        }
      })
    }

    case "FileUploaded": {
      return produce(currentState, (ns) => {
        const { type: _, ...props } = event
        ns.uploads.push({ ...props, processed: !props.needsProcessing, valid: !props.needsProcessing })
        if (event.ratingFileType === RatingFileCategory.a07) {
          ns.dataFilesProcessingStatus.a07 = undefined
        } else if (event.ratingFileType === RatingFileCategory.saft) {
          ns.dataFilesProcessingStatus.saft = undefined
        }
      })
    }

    case "FileProcessed": {
      return produce(currentState, (ns) => {
        const uploadIndex = [...ns.uploads].findIndex((e) => e.uploadId === event.uploadId)
        ns.uploads[uploadIndex] = {
          ...ns.uploads[uploadIndex],
          parsedData: event.parsedData,
          processed: true,
          valid: !event.errorMsg,
          processFailureMessages: event.errorMsg,
          processWarningMessages: event.warningMsg,
        }
      })
    }

    case "UploadArchived": {
      return produce(currentState, (ns) => {
        const upload = ns.uploads.find((upload) => upload.uploadId === event.uploadId)
        if (upload?.scope === UploadScopeEnum.A07) {
          ns.dataFilesProcessingStatus.a07 = undefined
        } else if (upload?.scope === UploadScopeEnum.SAFT) {
          ns.dataFilesProcessingStatus.saft = undefined
        }
        ns.uploads = ns.uploads.filter((upload) => upload.uploadId !== event.uploadId)
      })
    }

    case "UploadsArchived": {
      const removeUploadIds = new Set(event.uploadIds)
      return produce(currentState, (ns) => {
        if (ns.uploads.some((upload) => removeUploadIds.has(upload.uploadId) && upload.scope === UploadScopeEnum.A07)) {
          ns.dataFilesProcessingStatus.a07 = undefined
        } else if (
          ns.uploads.some((upload) => removeUploadIds.has(upload.uploadId) && upload.scope === UploadScopeEnum.SAFT)
        ) {
          ns.dataFilesProcessingStatus.saft = undefined
        }
        ns.uploads = ns.uploads.filter((upload) => !removeUploadIds.has(upload.uploadId))
      })
    }

    case "AnswerValuesRemoved": {
      return produce(currentState, (ns) => {
        for (const scope of event.scopes) {
          // Remove any input values except Yes/No
          if (ns.answerValues[scope]) {
            ns.answerValues[scope] = { primaryAnswer: ns.answerValues[scope].primaryAnswer }
          }
        }
      })
    }

    case "AnswerValuesChanged": {
      return produce(currentState, (ns) => {
        // Note that this can be undefined: yes/no question about A07 also uses this event
        const question = event.question

        // If the user has checked yes or no, and the question has a formula for updating based on these values
        if (question && event.values && Boolean(event.values.primaryAnswer) && question.datapoint) {
          // If there is an action described for the boolean value of the question
          // E.g. "Har virksomheten hatt/fått miljøsertifiseringer gjennom perioden?" -> yes = 100, no = 0
          const primaryAnswerValues = question.datapoint.primaryAnswerValues as { yes: number; no: number }
          const scope = {
            primaryValue: primaryAnswerValues[event.values.primaryAnswer === "true" ? "yes" : "no"],
          }
          const val = evaluate(question.datapoint.formula, scope)
          if (typeof val === "number") {
            ns.datapoints[question.datapoint.name] = bignumber(val)
          }
        }

        // If the user has checked yes
        if (question && event.values?.primaryAnswer === "true") {
          // go thought text inputs in question and update datapoints if they define a value
          for (const [idx, textInput] of (question.textInputs || []).entries()) {
            const value = event.values[`input_${idx}`]
            if (textInput.linkedDatapoint) {
              const datapointValue = transformDatapointInput(value, textInput)
              if (datapointValue === undefined) {
                delete ns.datapoints[textInput.linkedDatapoint]
              } else if (datapointValue >= 0) {
                ns.datapoints[textInput.linkedDatapoint] = bignumber(datapointValue)
              }
            }
          }

          // reset datapoints in sub-questions if they define a value to use when the question is disabled
          for (const subQuestion of event.dependentQuestions || []) {
            // go through text inputs in sub-questions as well
            for (const textInput of subQuestion.textInputs || []) {
              if (textInput.linkedDatapoint && textInput.valueWhenDisabled !== undefined) {
                delete ns.datapoints[textInput.linkedDatapoint]
              }
            }
          }
        }

        // If the user has checked no
        if (question && event.values?.primaryAnswer === "false") {
          // update datapoints in question's text inputs if they define a value to use when answer is no
          for (const textInput of question.textInputs || []) {
            if (textInput.linkedDatapoint) {
              if (textInput.valueWhenAnswerNo !== undefined) {
                ns.datapoints[textInput.linkedDatapoint] = bignumber(textInput.valueWhenAnswerNo)
              } else {
                // if the text input does not have a valueWhenAnswerNo value, we clear the datapoint
                // ... but only if the question is not dependent on another question that is answered with "true"
                if (
                  !question.dependsOn ||
                  (question.dependsOn && ns.answerValues[question.dependsOn]?.primaryAnswer !== "false")
                ) {
                  delete ns.datapoints[textInput.linkedDatapoint]
                }
              }
            }
          }

          // update datapoints in all sub-questions's text inputs if they define a value to use when the question is disabled
          for (const subQuestion of event.dependentQuestions || []) {
            for (const textInput of subQuestion.textInputs || []) {
              if (textInput.linkedDatapoint && textInput.valueWhenDisabled !== undefined) {
                ns.datapoints[textInput.linkedDatapoint] = bignumber(textInput.valueWhenDisabled)
              }
            }
          }
        }

        ns.answerValues[event.scope] = { ...ns.answerValues[event.scope], ...event.values }
      })
    }

    case "ContactChanged": {
      return produce(currentState, (ns) => {
        ns.contactEmail = event.email
        ns.contactName = event.name
        ns.contactPhone = event.phone
      })
    }

    case "CommentChanged": {
      return produce(currentState, (ns) => {
        ns.ratingComment = event.comment
      })
    }

    case "PeriodChanged": {
      return produce(currentState, (ns) => {
        ns.period = new AccountingPeriodRange(new AccountingPeriod(event.start), new AccountingPeriod(event.end))
        ns.phase = "SUBMISSION"
      })
    }

    case "RatingProfileChanged": {
      return produce(currentState, (ns) => {
        ns.ratingProfileId = event.ratingProfileId
        ns.ratingProfileVersion = event.ratingProfileVersion
          ? new Date(event.ratingProfileVersion).toISOString().replace("T", " ").replace("Z", "+00")
          : undefined
      })
    }

    case "DocumentationSubmitted": {
      return produce(currentState, (ns) => {
        ns.phase = "RATING_IN_PROGRESS"
        ns.lastSubmitted = new Date(commit.timestamp)
        ns.reopenedScopes = undefined
      })
    }

    case "RequestDocumentationProcessing": {
      return produce(currentState, (ns) => {
        ns.phase = "PROCESSING_DOCUMENTATION"
        ns.lastSubmitted = new Date(commit.timestamp)
        ns.dataFilesProcessingStatus = {}
      })
    }

    case "DocumentationProcessed": {
      return produce(currentState, (ns) => {
        ns.phase = "RATING_IN_PROGRESS"
        ns.reopenedScopes = undefined
      })
    }

    case "DocumentationProcessingFailed": {
      return produce(currentState, (ns) => {
        ns.phase = "RATING_IN_PROGRESS"
        ns.reopenedScopes = undefined
        const processingStatus = {
          status: ProcessingStatus.Failed,
          errorMsg: event.errorMsg,
        }
        if (event.ratingFileType === RatingFileCategory.a07) {
          ns.dataFilesProcessingStatus.a07 = processingStatus
        } else if (event.ratingFileType === RatingFileCategory.saft) {
          ns.dataFilesProcessingStatus.saft = processingStatus
        }
      })
    }

    case "RatingAborted": {
      return produce(currentState, (ns) => {
        ns.phase = "ABORTED"
      })
    }

    case "AdditionalQuestionAdded": {
      return produce(currentState, (ns) => {
        const { type: _, ...eventProps } = event

        ns.phase = "SUBMISSION"
        ns.reopenedScopes = [...(ns.reopenedScopes || []), "additionalQuestions"]
        ns.currentAdditionalQuestionNumber = parseInt(eventProps.questionNumber.split(".")[1], 10)

        ns.additionalQuestions.push({
          ...eventProps,
          timestamp: new Date(commit.timestamp),
        })
      })
    }

    case "AdditionalQuestionRemoved": {
      return produce(currentState, (ns) => {
        ns.additionalQuestions = ns.additionalQuestions.filter((q) => q.id.toString() !== event.id)
      })
    }

    case "RatingCompleted": {
      return produce(currentState, (ns) => {
        ns.phase = "COMPLETED"
        // Lock rating to ratingProfileVersion (if not already locked)
        ns.ratingProfileVersion =
          currentState.ratingProfileVersion ||
          (event.ratingProfileVersion
            ? new Date(event.ratingProfileVersion).toISOString().replace("T", " ").replace("Z", "+00")
            : undefined)
        ns.pdfReportKey = event.pdfReportKey
        ns.completedAt = new Date(commit.timestamp)
      })
    }

    case "DocumentationAmendmentsRequested": {
      return produce(currentState, (ns) => {
        ns.phase = "SUBMISSION"
        ns.reopenedScopes = event.scopes

        if (ns.anomalies.length) {
          ns.earlierAnomalies.unshift(ns.anomalies)
        }

        ns.anomalies = []

        if (event.scopes) {
          for (const previousAnomaly of currentState.anomalies) {
            if (previousAnomaly.source === "SAFT" && !event.scopes.includes("finances")) {
              ns.anomalies.push(previousAnomaly)
            }
          }

          if (event.scopes.includes("altinn")) {
            ns.dataFilesProcessingStatus.a07 = undefined
          }
          if (event.scopes.includes("finances")) {
            ns.dataFilesProcessingStatus.saft = undefined
          }
        }
      })
    }

    case "RequestDatafilesProcessing": {
      return produce(currentState, (ns) => {
        ns.phase = "PROCESSING_DATAFILES"
      })
    }

    case "DatafilesProcessed": {
      return produce(currentState, (ns) => {
        ns.anomalies = [...ns.anomalies, ...JSON.parse(JSON.stringify(event.anomalies), reviver)]
        ns.datapoints = { ...ns.datapoints, ...JSON.parse(JSON.stringify(event.datapoints), reviver) }
      })
    }

    case "SelfServiceRequestDatafilesProcessing": {
      return produce(currentState, (ns) => {
        const processingStatus = {
          status: ProcessingStatus.Processing,
        }
        if (event.ratingFileType === RatingFileCategory.a07) {
          ns.dataFilesProcessingStatus.a07 = processingStatus
        } else if (event.ratingFileType === RatingFileCategory.saft) {
          ns.dataFilesProcessingStatus.saft = processingStatus
        }
      })
    }

    case "SelfServiceDatafilesProcessed": {
      return produce(currentState, (ns) => {
        ns.anomalies = [...ns.anomalies, ...JSON.parse(JSON.stringify(event.anomalies), reviver)]
        ns.datapoints = { ...ns.datapoints, ...JSON.parse(JSON.stringify(event.datapoints), reviver) }
        const processingStatus = {
          status: ProcessingStatus.Completed,
        }
        if (event.ratingFileType === RatingFileCategory.a07) {
          ns.dataFilesProcessingStatus.a07 = processingStatus
        } else if (event.ratingFileType === RatingFileCategory.saft) {
          ns.dataFilesProcessingStatus.saft = processingStatus
        }
      })
    }

    case "SelfServiceDatafilesProcessingFailed": {
      return produce(currentState, (ns) => {
        const processingStatus = {
          status: ProcessingStatus.Failed,
          errorMsg: event.errorMsg,
        }
        if (event.ratingFileType === RatingFileCategory.a07) {
          ns.dataFilesProcessingStatus.a07 = processingStatus
        } else if (event.ratingFileType === RatingFileCategory.saft) {
          ns.dataFilesProcessingStatus.saft = processingStatus
        }
      })
    }

    case "AnomalyApproved": {
      return produce(currentState, (ns) => {
        // replace with proper str id
        const anomaly = ns.anomalies[parseInt(event.anomalyId, 10)]
        anomaly.ok = true
        anomaly.comment = event.comment
      })
    }

    case "AnomalyUnapproved": {
      return produce(currentState, (ns) => {
        // replace with proper str id
        const anomaly = ns.anomalies[parseInt(event.anomalyId, 10)]
        anomaly.ok = false
        anomaly.comment = undefined
      })
    }

    case "RatingUpgradedToFull": {
      return produce(currentState, (ns) => {
        ns.ratingType = RatingType.Full
        ns.ratingUpgradedToFull = true
        ns.phase = "SUBMISSION"
      })
    }

    default: {
      return currentState
    }
  }
}
