import { Leaves, flattenObject } from "../utils"
import { datapoints } from "./datapoints"
import { faqs } from "./faqs"
import { helpTexts } from "./helpTexts"

const translations = {
  datapoints,
  helpTexts,
  faqs,
}

export type I18nKeys = Leaves<typeof translations>
export const nb = flattenObject(translations) as Record<I18nKeys, string>
