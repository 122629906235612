import { useToast } from "@chakra-ui/react"
import { useEffect } from "react"
import { UseMutationState } from "urql"

export function useResultToast(result: UseMutationState, successText?: string, errorText?: string) {
  const toast = useToast()

  useEffect(() => {
    if (result.error) {
      toast({
        title: errorText,
        status: "error",
        position: "top",
        isClosable: true,
      })
    } else if (result.data && successText) {
      toast({
        title: successText,
        status: "success",
        position: "top",
        isClosable: true,
      })
    }
  }, [result, toast, successText, errorText])
}
