import { Select } from "@chakra-ui/react"
import { BusinessArea } from "@esgt/types"
import { MethodsForRatingProfileCreationQuery, useMethodsForRatingProfileCreationQuery } from "lib/generated/graphql"
import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { FormValues } from "../New"

interface Props {
  businessArea: BusinessArea
}

export type MethodVersionSelection = MethodsForRatingProfileCreationQuery["methods"][number]

export const MethodVersionSelector: React.FC<Props> = ({ businessArea }) => {
  const { control } = useFormContext<FormValues>()
  const [{ fetching, data }] = useMethodsForRatingProfileCreationQuery({ variables: { businessArea } })

  const methodVersions = useMemo(() => data?.methods || [], [fetching, data])

  return (
    <Controller
      control={control}
      name="method"
      render={({ field: { onChange, value, ...field } }) => (
        <Select
          placeholder={fetching ? "Henter metodeversjoner..." : "Velg metode-versjon"}
          {...field}
          value={value?.id || ""}
          onChange={(e) => {
            const methodVersion = methodVersions.find((mv) => mv.id === parseInt(e.target.value, 10))
            onChange({
              ...e,
              target: {
                value: methodVersion,
              },
            })
          }}
        >
          {methodVersions.map((mv) => (
            <option key={mv.id} value={mv.id}>
              {mv.name} @ {mv.version}
            </option>
          ))}
        </Select>
      )}
    />
  )
}
