import { ChakraProvider, theme } from "@chakra-ui/react"
import { RatingAccount } from "lib/providers"
import { RatingProvider, useRating } from "lib/providers/RatingProvider"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { CustomRatingComment } from "./CustomRatingComment"
import { EndNotes } from "./EndNotes"
import { EsgTaxOther } from "./EsgTaxOther"
import { FreeOutro } from "./FreeOutro"
import { Frontpage } from "./Frontpage"
import { Indicators } from "./Indicators"
import { Intro } from "./Intro"
import { FreeIntro } from "./Intro/FreeIntro"
import { KPIs } from "./KPIs"
import { Outro } from "./Outro"
import { SunburstChart } from "./SunburstChart"
import { SustainableDevelopmentGoals } from "./SustainableDevelopmentGoals"
import { TOC } from "./TOC"

const Container = styled.div`
	font-family: Roboto, system-ui, -apple-system, sans-serif !important;
	font-weight: 400;
	-webkit-print-color-adjust: exact;

	@page {
		size: A4 portrait;
		margin: 16mm 0;
	}
`

const LoadingRating = () => {
  const { isReady, isFree } = useRating()

  if (!isReady) {
    return null
  }

  return (
    <>
      <Container>
        <Frontpage />
        {isFree ? <FreeIntro /> : <Intro />}
        <SunburstChart />
        {!isFree && <CustomRatingComment />}
        <TOC />
        {!isFree && <Indicators />}
        <KPIs />
        {!isFree && <SustainableDevelopmentGoals />}
        {!isFree && <EsgTaxOther />}
        <EndNotes />
        {isFree ? <FreeOutro /> : <Outro />}
      </Container>
    </>
  )
}

export const Report: React.FC = () => {
  const { id } = useParams()

  if (!id) return <h1>No ID found: {id}!</h1>

  const ratingId = id.replace(/-/g, ".")

  return (
    <ChakraProvider theme={theme}>
      <RatingAccount.Provider ratingId={ratingId}>
        <RatingProvider ratingId={ratingId}>
          <LoadingRating />
        </RatingProvider>
      </RatingAccount.Provider>
    </ChakraProvider>
  )
}
