import { Box, Flex, Heading, Icon, Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { benchmarkLevels, extendedCalculateKpi } from "@esgt/method-structure"
import { MethodI18nFragment } from "components/I18nText"
import * as d3 from "d3"
import { formatValue } from "lib/helpers/formatValue"
import { useMethod } from "lib/hooks"
import { getLanguage } from "lib/i18n/utils"
import { useRating } from "lib/providers/RatingProvider"
import { bignumber } from "mathjs"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { PreviewWatermark } from "../components"
import { reportIsReady } from "./reportIsReady"
import { useD3 } from "./useD3"

const Container = styled.div`
	page-break-after: always;
	position: relative;
`

const textSize = 16

const computeLeafLabel = (d: any) => {
  let rotation = 0
  let anchor = "start"
  let dy = 0

  if (d.x0 > Math.PI) {
    rotation = (((d.x0 + d.x1) / 2) * 180) / Math.PI + 90
    anchor = "end"
    dy = -(d.x1 - d.x0) * textSize * Math.PI - 10
  } else {
    rotation = (((d.x0 + d.x1) / 2) * 180) / Math.PI - 90
    anchor = "start"
    dy = (d.x1 - d.x0) * textSize * Math.PI + 35
  }

  return { rotation, anchor, dy }
}

const computeCategoryLabel = (d: any) => {
  let rotation = 0
  let anchor = "start"
  let dy = 0

  if (d.x0 > Math.PI) {
    rotation = (((d.x0 + d.x1) / 2) * 180) / Math.PI + 90
    anchor = "end"
    dy = -(d.x1 - d.x0) * textSize * Math.PI - 10
  } else {
    rotation = (((d.x0 + d.x1) / 2) * 180) / Math.PI - 90
    anchor = "start"
    dy = (d.x1 - d.x0) * textSize * Math.PI + 19
  }

  return { rotation, anchor, dy }
}

export const SunburstChart: React.FC = () => {
  const rating = useRating()
  const { ratingState, ratingProfileConfig, isReady, ratingProfile, isFree } = rating
  // For free ratings the method returned by useRating is the reduced method version
  const freeMethod = isFree ? rating.method : null
  // Therefore we need to get the full method manually here for free ratings to display the entire sunchart
  const method = isFree ? useMethod(ratingProfile?.revisionOrActiveRevision?.methodId)?.method : rating.method

  const { i18n } = useTranslation()

  const calcData = useMemo(
    () =>
      isReady && ratingProfile && method
        ? method.config.kpiDimensions.map((dimension) => {
            const categories = dimension.categories.map((category) => {
              const kpis = category.kpiIds
                .map((id) => method.kpisById[id])
                .filter((kpi) => {
                  return !(ratingProfileConfig?.kpis?.[kpi.id] && ratingProfileConfig?.kpis?.[kpi.id].enabled === false)
                })
                .map((kpi) => {
                  const calc = extendedCalculateKpi(
                    kpi,
                    ratingProfileConfig,
                    ratingState.datapoints,
                    ratingState.anomalies,
                    ratingState.phase,
                    ratingState.overriddenKpisById,
                    method.kpisById,
                  )
                  const greyedOut = isFree && !freeMethod?.kpisById[kpi.id]
                  return {
                    ...kpi,
                    calculations: {
                      ...calc,
                      color: greyedOut
                        ? "#C5C5C5"
                        : calc.noDataCause === "no_benchmark"
                          ? "#8ab4e0"
                          : calc.noDataCause === "no_data"
                            ? "#b04f4f"
                            : calc.color,
                    },
                  }
                })

              const sum = kpis
                .filter((k) => !!k.calculations.benchmarkLevel)
                .map((k) => k.calculations.benchmarkLevel)
                .reduce((prev, curr) => prev + curr, 0)

              const numOfCountingKpis = kpis.filter((k) => k.calculations.noDataCause !== "no_benchmark").length
              const avg = numOfCountingKpis ? sum / numOfCountingKpis : 0
              const greyedOut = isFree && kpis.some((kpi) => !freeMethod?.kpisById[kpi.id])
              return {
                ...category,
                color: greyedOut ? "#C6C6C6" : benchmarkLevels[Math.round(avg)].color,
                avg,
                kpis,
                greyedOut,
              }
            })

            const numOfCountingCategories = categories.length
            const sum = categories.map((k) => k.avg).reduce((prev, curr) => prev + curr, 0)

            const avg = numOfCountingCategories ? sum / numOfCountingCategories : 0
            const greyedOut = isFree && categories.some((cat) => cat.greyedOut)
            return {
              ...dimension,
              categories,
              color: greyedOut ? "#C6C6C6" : benchmarkLevels[Math.round(avg)].color,
            }
          })
        : [],
    [isReady, ratingProfile, method],
  )

  const ref = useD3(() => {
    if (!isReady) return
    if (!ratingProfile) return
    if (!method) return
    const vWidth = 2000
    const vHeight = 1000
    const vRadius = Math.min(vWidth, vHeight) / 2

    const vData = {
      id: "TOPICS",
      children: calcData.map((dimension) => ({
        id: `dimension_${dimension.id}`,
        label: getLanguage(dimension.name, i18n),
        color: dimension.color,
        children: dimension.categories.map((category) => ({
          id: `category_${category.id}`,
          label: getLanguage(category.name, i18n),
          color: category.color,
          children: category.kpis.map((kpi) => {
            return {
              id: `kpi_${kpi.id}`,
              type: "label",
              label: getLanguage(kpi.name, i18n),
              size: 1,
              color: kpi.calculations.color,
            }
          }),
        })),
      })),
    }

    // Prepare our physical space
    const g = d3
      .select("svg")
      .attr("width", vWidth)
      .attr("height", vHeight)
      .append("g")
      .attr("transform", `translate(${vWidth / 2}, ${vHeight / 2})`)

    d3.transition()
      .duration(0)
      .on("end", () => reportIsReady())

    // Declare d3 layout
    const vLayout = d3.partition().size([2 * Math.PI, vRadius])
    const ringHeights = [0.1, 0.2, 0.6, 0.1]
    const vArc = d3
      .arc<any>()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .innerRadius((d) => ringHeights.slice(0, d.depth).reduce((sum, n) => sum + n * vRadius, 0))
      .outerRadius((d) => ringHeights.slice(0, d.depth + 1).reduce((sum, n) => sum + n * vRadius, 0))

    // Layout + Data
    const vRoot = d3.hierarchy<any>(vData).sum((d) => d.size)
    const vNodes = vRoot.descendants()
    vLayout(vRoot)
    const vSlices = g.selectAll("g").data(vNodes).enter().append("path")

    // Dimension labels
    g.selectAll("g")
      .data(vNodes.filter((d) => d.depth === 1))
      .enter()
      .append("text")
      .attr("transform", (d) => `translate(${vArc.centroid(d)})`) // <-- 3
      .attr("text-anchor", "middle")
      .attr("dx", "0")
      .attr("dy", "0")
      .style("fill", "#ffffff")
      .style("font-weight", "600")
      .style("font-size", "18px")
      .text((d) => d.data.label.toUpperCase())

    // Category labels
    g.selectAll("g")
      .data<any>(vNodes.filter((d) => d.depth === 2))
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      // .attr('dy', d => computeCategoryLabel(d).dy)
      .style("fill", "#ffffff")
      .style("font-weight", "600")
      .style("font-size", "18px")
      .attr(
        "transform",
        (d) => {
          const { rotation } = computeCategoryLabel(d)
          return `translate(${vArc.centroid(d)[0]}, ${vArc.centroid(d)[1] + textSize / 2}) rotate(${rotation})`
        },
        //
      )
      .text((d) => d.data.label)

    // KPI labels
    g.selectAll("g")
      .data<any>(vNodes.filter((d) => d.data.type === "label"))
      .enter()
      .append("text")
      .attr("text-anchor", (d) => computeLeafLabel(d).anchor)
      .attr("dy", (d) => computeLeafLabel(d).dy)
      .style("fill", (d) => d.data.color)
      .style("font-weight", "600")
      .attr(
        "transform",
        (d) => {
          const { rotation } = computeLeafLabel(d)
          return `translate(
						${(vRadius + 15) * Math.sin(d.x0)},
						${-(vRadius + 15) * Math.cos(d.x0)}
					) rotate(${rotation})`
        },
        //
      )
      .text((d) => d.data.label)

    // Draw on screen
    vSlices
      .filter((d) => !!d.parent)
      .attr("d", vArc)
      .style("stroke", "#fff")
      .style("stroke-width", "3")
      .style("fill", (d) => d.data.color)
  }, [isReady, ratingProfile, method])

  const selectedKpis = useMemo(() => {
    if (calcData.length === 0 || !calcData[1]) return

    const calculated = calcData[1]

    const resourceEfficiencyCategory = calculated.categories[0]
    const circularEconomyCategory = calculated.categories[1]
    const emissionsCategory = calculated.categories[2]

    const energyEfficiency = resourceEfficiencyCategory?.kpis.find((e) => e.kpiNumber === "2.10.100") || null
    const recycledMaterial = circularEconomyCategory?.kpis.find((kpi) => kpi.kpiNumber === "2.20.100") || null
    const scope1 = emissionsCategory?.kpis.find((e) => e.kpiNumber === "2.30.100") || null
    const scope2Location = emissionsCategory?.kpis.find((e) => e.kpiNumber === "2.30.500") || null
    const scope2Market = emissionsCategory?.kpis.find((e) => e.kpiNumber === "2.30.600") || null

    return {
      energyEfficiency,
      recycledMaterial,
      scope1,
      scope2Location,
      scope2Market,
    }
  }, [calcData])

  const TableText = ({ children, ...rest }) => (
    <Text whiteSpace={"break-spaces"} maxWidth="100%" margin={0} fontSize={"10pt"} {...rest}>
      {children}
    </Text>
  )

  const Resources = () => (
    <ResourcesContainer>
      <Heading fontWeight={100} fontSize={"16pt"} mb={"1mm"}>
        Kritiske ressurser
      </Heading>
      <TableContainer>
        <Table width={"100%"} variant="simple">
          <Tbody>
            <Tr>
              <Td padding={0}>
                <TableText>Forbruk fossilt drivstoff liter</TableText>
              </Td>
              <Td padding={0}>
                <TableText>
                  {ratingState?.datapoints?.literBensin
                    ? `${bignumber(ratingState.datapoints.literBensin)
                        .add(bignumber(ratingState.datapoints.literDiesel))
                        .add(bignumber(ratingState.datapoints.literOlje))
                        .toString()} liter`
                    : "—"}
                </TableText>
              </Td>
            </Tr>
            <Tr>
              <Td padding={0}>
                <TableText>Forbruk strøm kwh</TableText>
              </Td>
              <Td padding={0}>
                <TableText color={selectedKpis?.energyEfficiency?.calculations?.color || "#000"}>
                  {selectedKpis?.energyEfficiency?.calculations?.value
                    ? formatValue(selectedKpis.energyEfficiency.calculations.value.toString())
                    : " — "}{" "}
                  {selectedKpis?.energyEfficiency?.unit && (
                    <MethodI18nFragment text={selectedKpis?.energyEfficiency?.unit} />
                  )}
                </TableText>
              </Td>
            </Tr>
            <Tr>
              <Td padding={0}>
                <TableText>Resirkulert materiale</TableText>
              </Td>
              <Td padding={0}>
                <TableText color={selectedKpis?.recycledMaterial?.calculations?.color || "#000"}>
                  {selectedKpis?.recycledMaterial?.calculations?.value
                    ? formatValue(selectedKpis.recycledMaterial.calculations.value.toString())
                    : " — "}{" "}
                  {selectedKpis?.recycledMaterial?.unit && (
                    <MethodI18nFragment text={selectedKpis?.recycledMaterial?.unit} />
                  )}
                </TableText>
              </Td>
            </Tr>
            <Tr>
              <Td padding={0}>
                <TableText>Scope 1 - Direkte utslipp</TableText>
              </Td>
              <Td padding={0}>
                <TableText color={selectedKpis?.scope1?.calculations?.color || "#000"}>
                  {selectedKpis?.scope1?.calculations?.value
                    ? formatValue(selectedKpis.scope1.calculations.value.toString())
                    : " — "}{" "}
                  {selectedKpis?.scope1?.unit && <MethodI18nFragment text={selectedKpis?.scope1?.unit} />}
                </TableText>
              </Td>
            </Tr>
            <Tr>
              <Td padding={0}>
                <TableText>Scope 2 - Lokasjonsbasert</TableText>
              </Td>
              <Td padding={0}>
                <TableText color={selectedKpis?.scope2Location?.calculations?.color || "#000"}>
                  {selectedKpis?.scope2Location?.calculations?.value
                    ? formatValue(selectedKpis.scope2Location.calculations.value.toString())
                    : " — "}{" "}
                  {selectedKpis?.scope2Location?.unit && (
                    <MethodI18nFragment text={selectedKpis?.scope2Location?.unit} />
                  )}
                </TableText>
              </Td>
            </Tr>
            <Tr>
              <Td padding={0}>
                <TableText>Scope 2 - Markedsbasert</TableText>
              </Td>
              <Td padding={0}>
                <TableText color={selectedKpis?.scope2Market?.calculations?.color || "#000"}>
                  {selectedKpis?.scope2Market?.calculations?.value
                    ? formatValue(selectedKpis.scope2Market.calculations.value.toString())
                    : " — "}{" "}
                  {selectedKpis?.scope2Market?.unit && <MethodI18nFragment text={selectedKpis?.scope2Market?.unit} />}
                </TableText>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </ResourcesContainer>
  )

  return (
    <Container>
      <PreviewWatermark />
      <Heading margin="0 16mm" fontWeight={500}>
        RESULTAT
      </Heading>
      {isFree && (
        <Heading margin="1mm 16mm 0" fontWeight={300} fontSize={"13pt"}>
          GRATIS -{" "}
          <Text as="span" fontWeight={600}>
            IKKE VERTIFISERT OG UFULLSTENDIG BÆREKRAFTSRAPPORT
          </Text>
        </Heading>
      )}

      <svg
        ref={ref}
        viewBox="0 0 2000 1000"
        style={{
          height: "200mm",
          width: "100%",
        }}
      />
      {isFree ? <Benchmarks /> : <Resources />}
    </Container>
  )
}

const Benchmarks = () => (
  <>
    <Box margin="0 16mm 4mm" fontSize="16px" fontWeight={300}>
      Resultatene presenteres i følgende kategorier:
    </Box>
    <BenchmarksContainer>
      {benchmarkLevels.map((bml, i) => (
        <BenchmarkLevel key={i}>
          <Flex flexFlow="row nowrap" alignItems="center" gap="6px">
            <CircleIcon color={bml.color} />
            <Box fontWeight="400">{bml.name}</Box>
          </Flex>
        </BenchmarkLevel>
      ))}
      <BenchmarkLevel>
        <Flex flexFlow="row nowrap" alignItems="center" gap="6px">
          <CircleIcon color="#C5C5C5" />
          <Box fontWeight="400">Ikke inkludert i gratis versjon</Box>
        </Flex>
      </BenchmarkLevel>
    </BenchmarksContainer>
  </>
)

const CircleIcon = (props: any) => (
  <Icon viewBox="0 0 100 100" {...props} width="16px" height="16px">
    <circle cx="50" cy="50" r="30" fill="currentColor" />
    {props.outline && <circle cx="50" cy="50" r="45" fill="none" strokeWidth={7} stroke="currentColor" />}
  </Icon>
)

const ResourcesContainer = styled.div`
margin: 0 16mm;
page-break-after: always;
line-height: 14pt;
font-size: 10pt;
font-weight: 100;
position: relative;
`

const BenchmarksContainer = styled.div`
  margin: 0 16mm;
  page-break-after: always;
	background-color: rgb(253, 253, 253);
	border: 1px solid rgb(223, 223, 219);
	border-radius: 8px;
	padding: 12px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 8px;
	font-size: 14px;
`

const BenchmarkLevel = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 1px;
`
