import { Tab, TabIndicator, TabList, TabProps, Tabs as ChakraTabs, TabsProps } from "@chakra-ui/react"
import { Link } from "react-router-dom"

interface TabCommon {
  label: React.ReactNode
  value?: string
  to?: string
}

interface ClickTab extends TabCommon {
  label: React.ReactNode
  value: string
  to?: never
}

interface LinkTab extends TabCommon {
  label: React.ReactNode
  value?: never
  to: string
}

type ITab = ClickTab | LinkTab

interface ITabsProps extends Omit<TabsProps, "children"> {
  tabs: ITab[]
  value: string
}

export const Tabs: React.FC<ITabsProps> = ({ tabs, value, ...props }) => {
  const commonTabProps: TabProps = {
    textAlign: "center",
    paddingX: 0,
    fontSize: "lg",
  }

  return (
    <ChakraTabs
      variant="unstyled"
      // https://github.com/chakra-ui/chakra-ui/issues/7450#issuecomment-1470792355
      // TabIndicator is not window resize aware, so position relative is needed for its parent
      position="relative"
      index={tabs.findIndex((tab) => (tab.value || tab.to) === value)}
      {...props}
    >
      <TabList gap={8}>
        {tabs.map((tab) =>
          tab.to ? (
            <Tab as={Link} key={tab.to} to={tab.to} {...commonTabProps}>
              {tab.label}
            </Tab>
          ) : (
            <Tab key={tab.value} {...commonTabProps}>
              {tab.label}
            </Tab>
          ),
        )}
      </TabList>
      {tabs.length > 0 && (
        <TabIndicator mt="-5px" height="2px" width="2px" bg="blue.700" borderRadius="1px" right={0} />
      )}
    </ChakraTabs>
  )
}
