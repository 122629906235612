import { MethodConfig } from "@esgt/types"
import { Can } from "components/Can"
import { ErrorMessage } from "components/ErrorMessage"
import { Tabs } from "components/Tabs"
import { useMethodQuery } from "lib/generated/graphql"
import { Navigate, Route, Routes, useMatch } from "react-router-dom"
import { MethodKpis } from "./MethodKpis"
import { MethodQuestions } from "./MethodQuestions"

export const MethodEditor = () => {
  const matchId = useMatch("/admin/method/:id/*")
  const matchPage = useMatch("/admin/method/:id/:page/*")
  const id = parseInt(matchId?.params.id)
  const page = matchPage?.params.page || "questions"

  const [query] = useMethodQuery({
    variables: { id },
    pause: !id,
  })

  if (!query.fetching && (query?.error || !query.data?.method)) {
    return <ErrorMessage title="Kunne ikke hente metode" error={query.error?.message || ""} />
  }

  return (
    <Can permission="system:write" entityId="*" noAccessComponent={<Navigate to="/" replace={true} />}>
      <Tabs
        value={page}
        tabs={[
          { label: "Spørsmål", to: "questions" },
          { label: "Indikatorer", to: "kpis" },
        ]}
      />
      <Routes>
        {/*<Route index element={<Overview />} />*/}
        <Route
          path="questions"
          element={query.data?.method ? <MethodQuestions config={query.data.method.config as MethodConfig} /> : null}
        />
        <Route path="kpis" element={query.data?.method ? <MethodKpis config={query.data.method.config} /> : null} />
      </Routes>
    </Can>
  )
}
