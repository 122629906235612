import {
  As,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react"

const headings = {}
for (let i = 1; i <= 6; i++) {
  headings[`h${i}`] = ({ node: _, ...props }) => (
    <Heading as={`h${i}` as As} size={`h${i}`} m="24px 0 8px 0" {...props} />
  )
}

export const i18nFormatting = {
  p: ({ node: _, ...props }) => <Text mb="16px" {...props} />,
  ...headings,
  ul: ({ node: _, ordered: __, ...props }) => <UnorderedList mb="16px" {...props} />,
  ol: ({ node: _, ordered: __, ...props }) => <OrderedList mb="16px" {...props} />,
  li: ({ node: _, ordered: __, ...props }) => <ListItem {...props} />,
  table: ({ node: _, ...props }) => <Table variant="roundedTop" mt="24px" {...props} />,
  thead: ({ node: _, ...props }) => <Thead {...props} />,
  tbody: ({ node: _, ...props }) => <Tbody {...props} />,
  tr: ({ node: _, isHeader: __, ...props }) => <Tr {...props} />,
  th: ({ node: _, isHeader: __, ...props }) => <Th {...props} />,
  td: ({ node: _, isHeader: __, ...props }) => <Td {...props} />,
  a: ({ node: _, ...props }) => <Link variant="underlined" color="blue.500" isExternal={true} {...props} />,
}
