import {
  BenchmarkKey,
  CUID,
  Kpi,
  KpiBenchmarkVariable,
  KpiEnabledVariable,
  KpiMathVariable,
  RatingAnomaly,
  RatingDatapoints,
  RatingProfileConfig,
} from "@esgt/types"
import type { BigNumber } from "mathjs"
import * as math from "mathjs"
import { KpiOverride } from "../types"
import { calculateKPIFormula } from "./calculateKPIFormula"
import { kpiIdToEnabledVariable, kpiMathVariableToKpiId } from "./kpiInterpolationUtils"

export function interpolateFormula(
  kpi: Kpi,
  datapoints: RatingDatapoints,
  anomalies: Array<RatingAnomaly>,
  kpisById: Record<CUID, Kpi> = {},
  ratingProfileConfig?: RatingProfileConfig,
  overriddenKpisById: Record<CUID, KpiOverride> = {},
): string {
  const { formula: kpiFormula, anomalies: kpiAnomalyDeps, kpiScores, additionalInterpolations } = kpi

  if (!kpiFormula) return ""

  const relevantAnomalySet = new Set(kpiAnomalyDeps || [])
  const relevantAnomalies = anomalies.filter(({ id }) => relevantAnomalySet.has(id))

  const anomalyAmountsByKey = Object.fromEntries<BigNumber>(
    relevantAnomalies.map((anomaly) => [anomaly.id, anomaly.amount] as [string, BigNumber]),
  )

  const anomalyOkValuesByKey = Object.fromEntries<boolean>(
    relevantAnomalies
      .filter((anomaly) => anomaly.ok)
      .map((anomaly) => [anomalyIsOkKey(anomaly.id), anomaly.ok] as [string, boolean]),
  )

  const kpiDependencyScores = getKpiDependencyScores(
    kpiScores,
    datapoints,
    anomalies,
    ratingProfileConfig,
    kpisById,
    overriddenKpisById,
  )

  // Initialize missing anomaly dependencies as undefined so we can check their undefinedness without errors
  const missingDependentAnomalies = mapUndefinedAnomalies(
    kpiAnomalyDeps || [],
    anomalyAmountsByKey,
    anomalyOkValuesByKey,
  )

  const kpiBenchmarks =
    ratingProfileConfig && kpiScores && additionalInterpolations?.includes("kpiBenchmarks")
      ? getKpiBenchmarks(kpiScores, ratingProfileConfig)
      : {}

  return interpolateMultipleRecords<number | BigNumber | boolean | undefined>(
    kpiFormula,
    datapoints,
    anomalyAmountsByKey,
    anomalyOkValuesByKey,
    missingDependentAnomalies,
    kpiDependencyScores,
    kpiBenchmarks,
  )
}

function getKpiDependencyScores(
  kpiScores: Array<KpiMathVariable> | undefined,
  datapoints: RatingDatapoints,
  anomalies: Array<RatingAnomaly>,
  ratingProfileConfig?: RatingProfileConfig,
  kpisById: Record<CUID, Kpi> = {},
  overriddenKpisById: Record<CUID, KpiOverride> = {},
): Record<KpiMathVariable, number | BigNumber | undefined> {
  return (kpiScores || [])?.reduce(
    (scores, label) => {
      const kpiId = kpiMathVariableToKpiId(label)
      const enabledLabel = kpiIdToEnabledVariable(kpiId)
      const kpiDependency = kpisById[kpiId]

      if (!kpiDependency) {
        return scores
      }

      // "enabled" is initially not set, which counts as enabled, so we can't just check
      // truthiness and must check for false explicitly
      const kpiIsDisabled = ratingProfileConfig?.kpis[kpiId]?.enabled === false

      if (kpiIsDisabled) {
        scores[label] = undefined
        scores[enabledLabel] = 0
      } else {
        scores[enabledLabel] = 1
        scores[label] =
          overriddenKpisById[kpiId]?.value ??
          calculateKPIFormula(kpiDependency, datapoints, anomalies, kpisById, ratingProfileConfig, overriddenKpisById)
            ?.value
      }

      return scores
    },
    {} as Record<KpiMathVariable | KpiEnabledVariable, number | BigNumber | undefined>,
  )
}

/**
 * Maps anomaly names to `undefined` to initialize unedefined anomalies so we can
 * check whether they are defined without errors.
 * (MathJS can evaluate `foo(undefined)` but not `foo(thisGenuinelyDoesNotExist)`).
 */
const mapUndefinedAnomalies = (
  anomalyDependencies: Array<RatingAnomaly["id"]>,
  existingAnomalies: Record<RatingAnomaly["id"], unknown>,
  existingAnomalyOks: Record<RatingAnomaly["id"], boolean>,
) => {
  const missingNames = anomalyDependencies.filter((name) => !existingAnomalies[name])
  const missingOks = anomalyDependencies.filter((name) => !existingAnomalyOks[name])
  return Object.fromEntries([
    // anomalyFoo: undefined, etc
    ...missingNames.map((name) => [name, undefined]),
    // anomalyFooIsOk: undefined, etc
    ...missingOks.map((name) => [anomalyIsOkKey(name), undefined]),
  ])
}

const anomalyIsOkKey = (anomalyId: RatingAnomaly["id"]) => `${anomalyId}IsOk`

function getKpiBenchmarks(
  kpiScores: Array<KpiMathVariable>,
  ratingProfileConfig: RatingProfileConfig,
): Record<KpiBenchmarkVariable, number | undefined> {
  const benchmarks: Record<KpiBenchmarkVariable, number | undefined> = {}

  for (const kpiScoreLabel of kpiScores) {
    const id = kpiMathVariableToKpiId(kpiScoreLabel)
    const kpiConfig = ratingProfileConfig.kpis[id]

    for (let benchmarkIndex = 0; benchmarkIndex < 5; benchmarkIndex++) {
      const key = `benchmark_${benchmarkIndex}` as BenchmarkKey
      const benchmark = kpiConfig ? Number(kpiConfig[key]) : undefined

      benchmarks[`${kpiScoreLabel}_benchmark_${benchmarkIndex}`] = !Number.isNaN(benchmark) ? benchmark : undefined
    }
  }

  return benchmarks
}

const interpolateMultipleRecords = <T>(formula: string, ...allKeyValues: Array<Record<string, T>>) => {
  let result = formula

  for (const record of allKeyValues) {
    result = interpolateSingleRecord(result, record)
  }

  return result
}

const wordWrapper = "variable_word_wrapper"

export const interpolateSingleRecord = <T>(formula: string, keyValues: Record<string, T>) => {
  const keys = Object.keys(keyValues)

  if (keys.length === 0) {
    return formula
  }

  const node = math.parse(formula)
  const transformed = node
    .transform((node) => {
      if ("isSymbolNode" in node && node.isSymbolNode && Object.hasOwn(keyValues, node.name)) {
        // We add a prefix and a suffix to the key to make it easier to replace the values with the regex below
        // \b regex word boundary will not match certain variables if they contain exotic characters (æøå etc)
        return new math.SymbolNode(`${wordWrapper}-${node.name}-${wordWrapper}`)
      }
      return node
    })
    .toString()

  return keys.reduce((result, key) => {
    return result.replace(
      new RegExp(`${wordWrapper}-${key}-${wordWrapper}`, "g"),
      keyValues[key]?.toString() || "undefined",
    )
  }, transformed)
}
