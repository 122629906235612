import { RatingState } from "@esgt/event-store"
import { submissionProgressPerspective } from "@esgt/helpers"
import { DataFilesProcessingStatus, RatingProfileConfig, RatingType } from "@esgt/types"
import { RatingProfileAndRevisionQuery } from "lib/generated/graphql"
import { UseMethodResult } from "lib/hooks"
import { createContext } from "react"

export const RatingContext = createContext<{
  ratingId: string
  ratingState?: RatingState
  ratingProfile: RatingProfileAndRevisionQuery["ratingProfile"]
  method: UseMethodResult["method"]
  ratingProfileConfig: RatingProfileConfig
  fetching: boolean
  isReady: boolean
  isEditable: boolean
  isReadyForCompletion: boolean
  submissionProgress?: ReturnType<typeof submissionProgressPerspective>
  ratingType: RatingType
  isFree: boolean
  upgradedToFull: boolean
  dataFilesProcessingStatus: DataFilesProcessingStatus
}>({ fetching: false } as any)
