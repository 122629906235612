import { Text } from "@chakra-ui/react"
import { TranslatedText } from "@esgt/types"
import { i18nFormatting } from "components/i18nFormatting"
import { I18nKeys } from "lib/i18n/nb"
import { getLanguage } from "lib/i18n/utils"
import { useTranslation } from "react-i18next"
import ReactMarkdown, { Components } from "react-markdown"
import gfm from "remark-gfm"

type TextProps = {
  textSize?: "sm" | "md" | "lg" | "xl"
  customFormatting?: Components
  inlineOnly?: boolean
}

type GfmTextProps = TextProps & {
  children: string
}

export const GfmText: React.FC<GfmTextProps> = (props) => {
  const { children, customFormatting = {}, textSize, inlineOnly } = props
  const textSizeFormatting = {
    p: ({ node: _, ...props }) => <Text mb="16px" size={textSize} {...props} as={inlineOnly ? "span" : undefined} />,
  }

  return (
    <ReactMarkdown remarkPlugins={[gfm]} components={{ ...i18nFormatting, ...textSizeFormatting, ...customFormatting }}>
      {children}
    </ReactMarkdown>
  )
}

type I18nTextProps = TextProps & { i18nKey: I18nKeys }

export const I18nText: React.FC<I18nTextProps> = (props) => {
  const { i18nKey, ...gfmProps } = props
  const { t } = useTranslation()

  return <GfmText {...gfmProps}>{t(i18nKey)}</GfmText>
}

type MethodI18nTranslationProps = { text: TranslatedText }

type MethodI18nTextProps = TextProps & MethodI18nTranslationProps

/** As I18nText, but for translations stored in the versioned method config. */
export const MethodI18nText: React.FC<MethodI18nTextProps> = (props) => {
  const { text, ...gfmProps } = props
  const { i18n } = useTranslation()

  return <GfmText {...gfmProps}>{getLanguage(text, i18n)}</GfmText>
}

/** Localized text, without formatting. */
export const MethodI18nFragment: React.FC<MethodI18nTranslationProps> = ({ text }) => {
  const { i18n } = useTranslation()
  return <>{getLanguage(text, i18n)}</>
}
