import { RatingProfileConfig } from "@esgt/types"
import { UseMethodResult } from "lib/hooks"
import { createContext } from "react"
import { UseQueryExecute } from "urql"

export const RevisionEditingContext = createContext<{
  fetching: boolean
  isSaving: boolean
  refetch: UseQueryExecute
  baselineConfig: RatingProfileConfig
  revisionConfig: RatingProfileConfig
  method: UseMethodResult["method"]
  isReadOnly: boolean
  ratingProfile: {
    methodVersion: string
    businessArea: string
  }
  isBaselineProfile: boolean
  setValue(itemType: string, itemId: string, key: string, value: any, saveImmediately?: boolean): void
  onSave(): Promise<void>
}>({} as any)
