import { Button, Flex, Input, Text } from "@chakra-ui/react"
import { MinimalContactInfo } from "@esgt/types"
import { ContributorsInfoTooltipIcon } from "components/Tooltips"
import { EmailInput } from "components/common/Contact/EmailInput"
import { NewRatingSubstep } from "pages/Ratings/NewRating/NewRatingStep"
import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { FadedLabel } from "../FadedLabel"

export type RatingContactInfo = {
  email: string
  name: string
  phone?: string
}

interface RatingContributorsFormProps {
  primaryContact: Partial<RatingContactInfo>
  contributors: Array<MinimalContactInfo>
  setContributors: (contributors: Array<MinimalContactInfo>) => void
  isSelfServiceRating?: boolean
}

export const RatingContributorsForm = ({
  primaryContact,
  contributors,
  setContributors,
  isSelfServiceRating,
}: RatingContributorsFormProps) => {
  const addContributor = useCallback(
    () => setContributors([...contributors, { name: "", email: "" }]),
    [contributors, setContributors],
  )

  const updateContributor = useCallback(
    (updateIndex: number, values: MinimalContactInfo) => {
      setContributors(replaceAt(updateIndex, contributors, values))
    },
    [contributors, setContributors],
  )

  return (
    <NewRatingSubstep
      title={
        <>
          {"Kontaktperson"} <ContributorsInfoTooltipIcon />
        </>
      }
    >
      <Flex direction="column" gap={6} alignItems="flex-start">
        <Flex as="ul" direction="column" gap={2} justifyContent="space-between" width="100%">
          <Flex as="li" gap={4}>
            <Flex basis={isSelfServiceRating ? "50%" : "38%"}>
              <Text fontWeight="semibold">Navn</Text>
            </Flex>

            <Flex basis={isSelfServiceRating ? "50%" : "38%"}>
              <Text fontWeight="semibold">E-post</Text>
            </Flex>

            {!isSelfServiceRating && <Flex basis="24%" />}
          </Flex>

          {isSelfServiceRating ? (
            <ContributorInputRow contributor={primaryContact} isReadOnly />
          ) : (
            <ContributorInputRow contributor={primaryContact} label="Hovedkontaktperson" isReadOnly />
          )}

          {contributors.map((contributor, index) => (
            <ContributorInputRow
              contributor={contributor}
              key={index}
              identifier={index}
              onUpdate={updateContributor}
            />
          ))}
        </Flex>
      </Flex>
      {!isSelfServiceRating && (
        <Button variant="link" onClick={addContributor}>
          Legg til flere kontaktpersoner
        </Button>
      )}
    </NewRatingSubstep>
  )
}

type DisabledContributorInputRowProps = {
  contributor: Partial<MinimalContactInfo>
  label?: string
  isReadOnly: true
  onUpdate?: never
  identifier?: never
  isSelfServiceRating?: boolean
}

type EnabledContributorInputRowProps = {
  contributor: Partial<MinimalContactInfo>
  label?: string
  onUpdate?: (id: number, values: MinimalContactInfo) => void
  identifier?: number
  isReadOnly?: never
  isSelfServiceRating?: boolean
}

type ContributorInputRowProps = DisabledContributorInputRowProps | EnabledContributorInputRowProps

const ContributorInputRow = ({
  contributor,
  label,
  isReadOnly,
  identifier,
  onUpdate,
  isSelfServiceRating,
}: ContributorInputRowProps) => {
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm()

  const nameKey = `${identifier}-name`
  const emailKey = `${identifier}-email`

  useEffect(() => {
    if (onUpdate) {
      const subscription = watch((values) => {
        onUpdate(identifier, {
          name: values[nameKey],
          email: values[emailKey],
        })
      })
      return () => {
        subscription.unsubscribe
      }
    }
  }, [emailKey, identifier, nameKey, onUpdate, watch])

  return (
    <Flex as="li" gap={4} alignItems="center">
      <Flex basis={isSelfServiceRating ? "50%" : "38%"}>
        <Input
          type="text"
          placeholder="Fornavn Etternavn"
          background={isReadOnly ? "gray.50" : undefined}
          readOnly={isReadOnly}
          {...register(nameKey, { value: contributor.name })}
        />
      </Flex>

      <Flex basis={isSelfServiceRating ? "50%" : "38%"}>
        <EmailInput
          inputKey={emailKey}
          value={contributor.email}
          setError={setError}
          clearErrors={clearErrors}
          register={register}
          errors={errors}
          isReadOnly={isReadOnly}
          allowEmpty={contributor.name.length === 0}
        />
      </Flex>

      {!isSelfServiceRating && (
        <Flex basis="24%">
          <FadedLabel highlighted>{label}</FadedLabel>
        </Flex>
      )}
    </Flex>
  )
}

function replaceAt<T>(updateIndex: number, array: Array<T>, newValue: T): Array<T> {
  return array.toSpliced(updateIndex, 1, newValue)
}
