import { Button } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useCallback } from "react"
import { Link } from "react-router-dom"
import { NewRatingStep, NewRatingStepFooter } from "../NewRatingStep"
import { NewRatingValues } from "../summary/NewRatingSummary"
import { CompanyInfoForm, RatingContactInfo } from "./CompanyInfoForm"
import { MeasurementDetailsForm, NewRatingMeasurementSettings } from "./MeasurementDetailsForm"

interface OrderInfoFormProps {
  values: NewRatingValues
  setValues: Dispatch<SetStateAction<NewRatingValues>>
  selectedOrganization
  setSelectedOrganization
}

export const NewRatingOrderInfoStep = ({
  values,
  setValues,
  selectedOrganization,
  setSelectedOrganization,
}: OrderInfoFormProps) => {
  const setPrimaryContact = useCallback(
    (update: RatingContactInfo) => {
      setValues((current) => ({ ...current, primaryContact: update }))
    },
    [setValues],
  )

  const setMeasurementValues = useCallback(
    (updates: NewRatingMeasurementSettings) => {
      setValues((current) => ({ ...current, measurement: updates }))
    },
    [setValues],
  )

  return (
    <NewRatingStep>
      <CompanyInfoForm
        primaryContact={values.primaryContact}
        setPrimaryContact={setPrimaryContact}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
      />

      <MeasurementDetailsForm
        values={values.measurement}
        setValues={setMeasurementValues}
        selectedOrganization={selectedOrganization}
      />

      <NewRatingStepFooter>
        <Button as={Link} size="lg" variant="outline" to="../../">
          Avbryt
        </Button>

        <Button as={Link} size="lg" to="../roles" colorScheme="green">
          Neste: Roller
        </Button>
      </NewRatingStepFooter>
    </NewRatingStep>
  )
}
