import { QuestionOutlineIcon } from "@chakra-ui/icons"
import { Box, BoxProps, CloseButton, Flex, Heading, HeadingProps, Link, useDisclosure } from "@chakra-ui/react"
import { I18nText } from "components/I18nText"
import { I18nKeys } from "lib/i18n/nb"
import { useEffect } from "react"
import { useLocalstorageState } from "rooks"

interface InfoHeadingPanelProps extends HeadingProps {
  helpText: I18nKeys
  // Defaults to gray.50
  helpTextBg?: BoxProps["bg"]
}
export const InfoHeadingPanel: React.FC<InfoHeadingPanelProps> = (props) => {
  const { helpText, helpTextBg = "gray.50", ...headingProps } = props
  const [helpTextVisibility, setHelpTextVisibility] = useLocalstorageState("helpTextVisibility", {})
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: helpTextVisibility && helpTextVisibility[helpText] !== false,
  })

  useEffect(() => {
    setHelpTextVisibility({ ...helpTextVisibility, [helpText]: isOpen })
  }, [isOpen])

  return (
    <Flex direction="column" flex="1">
      <Heading {...headingProps}>
        {props.children}
        <span style={{ whiteSpace: "nowrap" }}> </span>
        <Link onClick={onOpen}>
          <QuestionOutlineIcon verticalAlign="-15%" color={isOpen ? "gray.400" : "inherit"} />
        </Link>
      </Heading>

      {isOpen && (
        <Box bg={helpTextBg} mt="24px" mb="24px" borderRadius="8px" p="32px 48px 16px 32px" pos="relative">
          <CloseButton onClick={onClose} pos="absolute" right="16px" top="16px" />
          <I18nText i18nKey={helpText} textSize="lg" />
        </Box>
      )}
    </Flex>
  )
}
