import { Divider, Flex, Heading, Link, Text } from "@chakra-ui/react"
import { MethodVersionId } from "@esgt/types"
import { ContentBox } from "components/ContentBox"
import { SiteTitle } from "components/SiteTitle"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { User, useRatingContributorsQuery } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { CancelRatingButton } from "./CancelRatingButton"
import { OrderContacts } from "./OrderContacts"
import { OrderPeriod } from "./OrderPeriod"
import { OrderProfile } from "./OrderProfile"
import { OrderRaters } from "./OrderRaters"
import { UpgradeRatingToFullButton } from "./UpgradeRatingToFullButton"

export const RatingSettings = () => {
  const { ratingId, ratingState, ratingProfile } = useRating()

  useBreadcrumb("Målingsinnstillinger", `/ratings/${ratingId}/order`)

  const [contributorsQuery, refetchContributors] = useRatingContributorsQuery({
    variables: { key: ratingId },
    requestPolicy: "network-only",
  })
  const raters: User[] = contributorsQuery.data?.ratingContributors?.raters
  const ratingOwner: User | undefined = contributorsQuery.data?.ratingContributors?.owner
  const contactPerson: User = contributorsQuery.data?.ratingContributors?.contactPerson
  const contributors: User[] = contributorsQuery.data?.ratingContributors?.contributors || []
  return (
    <Flex gap="64px">
      <Flex direction="column" width="65%" gap={12} paddingBottom="92px">
        <SiteTitle title={`Målingsinnstillinger - ${ratingState.brreg.navn}`} />
        <OrderPeriod ratingId={ratingId} ratingState={ratingState} />

        <OrderProfile
          ratingId={ratingId}
          ratingState={ratingState}
          methodVersion={ratingProfile?.methodVersion as MethodVersionId}
        />

        <OrderRaters
          ratingId={ratingId}
          raters={raters}
          ratingOwner={ratingOwner}
          refetchRaters={refetchContributors}
          isLoading={contributorsQuery.fetching}
        />

        <OrderContacts
          ratingId={ratingId}
          contactPerson={contactPerson}
          contributors={contributors}
          refetchContacts={refetchContributors}
          isLoading={contributorsQuery.fetching}
        />

        <UpgradeRatingToFullButton />

        <CancelRatingButton />
      </Flex>
      <Flex width="35%">
        <ContentBox flex="1" bg="white">
          <Heading as="h4" size="h4" mb="24px">
            Org. informasjon
          </Heading>
          <Heading as="h6" size="h6" color="neutral.400">
            Bedriftsnavn
          </Heading>
          <Text size="lg" mt="8px">
            {ratingState.brreg.navn}
          </Text>
          <Divider borderColor="neutral.200" my="16px" />
          <Heading as="h6" size="h6" color="neutral.400">
            Org. nr.
          </Heading>
          <Text size="lg" mt="8px">
            {ratingState.brreg.organisasjonsnummer}
          </Text>
          <Divider borderColor="neutral.200" my="16px" />
          <Heading as="h6" size="h6" color="neutral.400">
            Næringskode
          </Heading>
          <Text size="lg" mt="8px">
            {ratingState?.brreg.naeringskode1 ? ratingState?.brreg.naeringskode1.kode : " - "}
            {" - "}
            {ratingState?.brreg.naeringskode1 ? ratingState?.brreg.naeringskode1.beskrivelse : ""}
          </Text>
          <Divider borderColor="neutral.200" my="16px" />
          <Heading as="h6" size="h6" color="neutral.400">
            Adresse
          </Heading>
          <Text size="lg" mt="8px">
            {ratingState.brreg.forretningsadresse.adresse}
            {", "}
            {ratingState.brreg.forretningsadresse.postnummer} {ratingState.brreg.forretningsadresse.poststed}
          </Text>
          <Divider borderColor="neutral.200" my="16px" />
          <Heading as="h6" size="h6" color="neutral.400">
            Br. Reg.
          </Heading>
          <Text size="lg" mt="8px">
            <Link
              href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${ratingState?.orgNr}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Enhet
            </Link>
            {" | "}
            <Link
              href={`https://w2.brreg.no/eHandelPortal/ecomsys/velg.jsp?enhetsnr=${ratingState?.orgNr}&action=getList`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Utskrifter og attester
            </Link>
          </Text>
          <Divider borderColor="neutral.200" my="16px" />
          <Heading as="h6" size="h6" color="neutral.400">
            Måling opprettet
          </Heading>
          <Text size="lg" mt="8px">
            {ratingState.ts && new Date(ratingState.ts).toLocaleString()}
          </Text>
          <Divider borderColor="neutral.200" my="16px" />
        </ContentBox>
      </Flex>
    </Flex>
  )
}
