import { IconType } from "react-icons"

export const IconSearchLgBlue: IconType = (props) => {
  return (
    <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Search Large</title>
      <path
        d="M79.8255 163.276C79.8255 163.276 107.986 167.795 130.07 148.009C152.154 128.223 158.23 96.0471 163.834 70.1364C169.438 44.2257 157.526 21.1802 135.519 13.7953C113.512 6.4103 103.99 25.1965 76.8406 31.1313C49.6908 37.066 41.4294 34.4717 22.3475 54.4618C3.26553 74.4519 7.66553 112.345 31.8514 136.45C56.0374 160.555 79.8255 163.276 79.8255 163.276Z"
        fill="#D2E5FA"
      />
      <path
        d="M159.343 114.445C159.343 114.445 161.103 115.994 160.786 116.61C160.469 117.226 154.098 123.724 154.098 123.724L152.965 122.077L156.573 115.579L159.343 114.445Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M150.932 106.435C150.932 106.435 158.725 113.313 158.725 113.725C158.725 114.136 155.839 116.819 155.839 116.819L150.168 115.168L150.478 108.775"
        fill="white"
      />
      <path
        d="M150.932 106.435C150.932 106.435 158.725 113.313 158.725 113.725C158.725 114.136 155.839 116.819 155.839 116.819L150.168 115.168L150.478 108.775"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M157.385 111.768L155.016 117.023L155.325 117.744L159.965 113.827C159.965 113.827 158.933 111.662 157.385 111.768Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M130.313 116.512C129.556 116.603 128.791 116.418 128.159 115.991L114.195 106.522C113.851 106.289 113.556 105.99 113.327 105.642C113.098 105.295 112.94 104.906 112.862 104.497C112.783 104.088 112.786 103.668 112.87 103.261C112.954 102.853 113.117 102.466 113.35 102.122C113.584 101.778 113.883 101.483 114.23 101.254C114.578 101.025 114.967 100.867 115.375 100.788C115.784 100.71 116.204 100.713 116.612 100.797C117.019 100.881 117.406 101.044 117.75 101.277L131.714 110.746C132.248 111.109 132.658 111.626 132.89 112.229C133.122 112.831 133.164 113.49 133.011 114.117C132.857 114.745 132.516 115.31 132.033 115.738C131.55 116.166 130.947 116.436 130.306 116.512H130.313Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.438 117.374C123.438 117.374 124.293 118.916 124.634 119.352C124.976 119.789 126.722 120.173 126.722 120.173C126.722 120.173 128.563 125.002 128.922 125.864C129.281 126.727 130.041 128.223 130.397 128.328C130.752 128.434 133.35 129.613 133.969 128.79C134.589 127.966 134.49 125.065 134.49 125.065L129.21 119.87L128.834 118.163L123.438 117.374Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M159.837 139.61C158.239 139.8 156.625 139.408 155.293 138.505L125.042 117.997C124.3 117.511 123.662 116.882 123.166 116.147C122.669 115.412 122.324 114.585 122.15 113.715C121.975 112.845 121.976 111.95 122.152 111.08C122.327 110.21 122.674 109.384 123.172 108.65C123.669 107.916 124.308 107.288 125.051 106.803C125.794 106.318 126.626 105.986 127.498 105.825C128.371 105.665 129.266 105.68 130.133 105.869C131 106.058 131.82 106.417 132.547 106.927L162.798 127.424C163.927 128.188 164.795 129.28 165.287 130.552C165.778 131.824 165.869 133.216 165.547 134.541C165.224 135.866 164.505 137.061 163.485 137.966C162.464 138.871 161.191 139.442 159.837 139.603V139.61Z"
        fill="#1A79E6"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M124.339 119.363C124.339 119.363 123.551 117.325 123.448 117.121C123.346 116.917 122.706 116.065 122.808 115.805C122.91 115.544 123.213 115.4 123.684 115.738C124.156 116.076 125.04 117.998 125.04 117.998C125.04 117.998 126.423 117.294 127.032 117.899C127.641 118.505 127.775 120.131 127.775 120.131"
        fill="white"
      />
      <path
        d="M124.339 119.363C124.339 119.363 123.551 117.325 123.448 117.121C123.346 116.917 122.706 116.065 122.808 115.805C122.91 115.544 123.213 115.4 123.684 115.738C124.156 116.076 125.04 117.998 125.04 117.998C125.04 117.998 126.423 117.294 127.032 117.899C127.641 118.505 127.775 120.131 127.775 120.131"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M84.0098 45.9396C76.8539 46.7933 70.1118 49.75 64.6363 54.4357C59.1608 59.1214 55.1979 65.3256 53.2487 72.2637C51.2995 79.2018 51.4515 86.5621 53.6856 93.4137C55.9197 100.265 60.1356 106.301 65.7999 110.756C71.4642 115.212 78.3226 117.887 85.5077 118.445C92.6929 119.002 99.8819 117.417 106.166 113.888C112.449 110.359 117.546 105.047 120.81 98.6216C124.074 92.1965 125.36 84.9478 124.504 77.792C123.357 68.1989 118.446 59.4541 110.852 53.4809C103.259 47.5076 93.6033 44.795 84.0098 45.9396ZM92.1445 114.133C85.8131 114.888 79.4 113.748 73.7162 110.858C68.0323 107.969 63.333 103.458 60.2124 97.8979C57.0918 92.3374 55.6902 85.9765 56.1847 79.6195C56.6791 73.2624 59.0475 67.1947 62.9904 62.1837C66.9333 57.1726 72.2735 53.4433 78.3359 51.4672C84.3982 49.4912 90.9103 49.3571 97.0488 51.0821C103.187 52.807 108.677 56.3134 112.822 61.158C116.968 66.0025 119.584 71.9676 120.34 78.2989C121.352 86.7896 118.95 95.3347 113.663 102.055C108.375 108.775 100.635 113.119 92.1445 114.133Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.5796 45.3341C75.423 46.1849 68.6795 49.139 63.202 53.8227C57.7244 58.5064 53.7589 64.7093 51.8071 71.6469C49.8552 78.5845 50.0046 85.9452 52.2363 92.7979C54.4681 99.6506 58.682 105.688 64.3451 110.145C70.0082 114.603 76.866 117.281 84.0512 117.84C91.2364 118.4 98.4262 116.816 104.711 113.289C110.996 109.763 116.094 104.451 119.36 98.0265C122.626 91.6021 123.914 84.3534 123.06 77.1972C121.915 67.6048 117.007 58.8597 109.417 52.8847C101.826 46.9096 92.1725 44.1937 82.5796 45.3341ZM90.7143 113.527C84.3829 114.282 77.9698 113.143 72.286 110.253C66.6021 107.363 61.9028 102.853 58.7822 97.2925C55.6617 91.732 54.26 85.3711 54.7545 79.014C55.2489 72.657 57.6174 66.5893 61.5602 61.5782C65.5031 56.5672 70.8433 52.8379 76.9057 50.8618C82.968 48.8857 89.4801 48.7517 95.6187 50.4766C101.757 52.2016 107.246 55.708 111.392 60.5525C115.538 65.3971 118.154 71.3622 118.91 77.6935C119.922 86.1842 117.52 94.7293 112.233 101.449C106.945 108.169 99.2049 112.514 90.7143 113.527Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.087 96.3246C136.087 96.3246 135.189 98.8414 135.249 99.3201C135.309 99.7988 135.548 99.9819 135.249 100.281C134.95 100.58 133.992 101.298 134.112 101.717C134.273 102.067 134.514 102.375 134.816 102.615C134.816 102.615 135.474 105.251 136.132 105.55C136.791 105.85 137.811 105.61 137.811 105.61L138.515 107.529L143.549 106.272L143.25 103.217C143.25 103.217 144.746 100.341 144.387 98.602C144.028 96.8631 140.912 95.1876 138.874 95.1876C136.836 95.1876 136.266 95.8459 136.087 96.3246Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M136.117 103.341C136.117 103.341 136.902 103.239 137.141 102.76"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M136.189 100.267C136.189 100.475 136.119 100.644 136.034 100.644C135.95 100.644 135.883 100.475 135.883 100.267C135.883 100.06 135.95 99.8906 136.034 99.8906C136.119 99.8906 136.189 100.06 136.189 100.267Z"
        fill="#263238"
      />
      <path
        d="M135.812 99.4154C135.812 99.4154 136.256 98.8698 136.516 99.5492"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M137.281 97.2859C137.281 97.2859 135.303 97.4055 135.169 95.547C135.035 93.6884 137.745 92.6711 138.763 92.6711C139.78 92.6711 139.062 93.692 139.062 93.692C139.062 93.692 141.579 92.9703 142.23 93.6321C142.881 94.2939 142.35 94.8289 142.35 94.8289C142.35 94.8289 145.644 96.2052 145.644 98.6023C145.644 100.999 143.909 103.337 143.61 103.815C143.311 104.294 142.589 104.713 141.273 104.773C139.956 104.833 139.161 104.175 138.875 103.277L138.576 102.376C138.576 102.376 139.593 101.299 139.115 100.401C138.636 99.5035 138.337 100.049 138.217 100.401C138.097 100.753 138.337 101.105 138.097 101.105C137.858 101.105 138.038 100.806 137.393 100.148C136.749 99.4894 136.193 99.1867 136.433 98.5284C136.636 98.0652 136.923 97.6438 137.281 97.2859Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M137.109 107.237L136.395 108.817L145.49 106.748L144.438 105.435L137.109 107.237Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M140.761 161.234C140.761 161.234 138.051 161.881 136.474 161.881C134.897 161.881 134.207 161.8 134.207 162.325C134.207 162.849 136.717 163.821 138.171 164.388C139.624 164.954 142.458 165.764 142.701 165.683C142.944 165.602 143.429 162.649 143.429 162.649L140.761 161.234Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M134.37 162.019C134.317 162.05 134.274 162.095 134.245 162.149C134.217 162.203 134.204 162.264 134.208 162.325C134.208 162.853 136.717 163.821 138.171 164.388C139.625 164.954 142.459 165.764 142.701 165.683C142.758 165.665 142.825 165.493 142.899 165.246C140.065 164.68 135.538 162.575 134.37 162.019Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M135.909 126.656C135.891 128.614 135.972 130.571 136.151 132.521C136.433 135.228 137.728 147.118 137.848 148.98C137.968 150.842 140.763 161.233 140.763 161.233L143.431 162.641C143.431 162.641 144.765 151.075 144.765 149.135V145.9C144.765 145.9 147.109 136.716 147.838 134.534C148.566 132.352 148.929 128.022 148.852 125.597C146.714 126.429 144.455 126.904 142.164 127.005C140.072 127.124 137.974 127.007 135.909 126.656Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M150.686 109.891L149.599 118.624C149.599 118.624 149.173 125.235 149.053 125.844C148.933 126.453 148.384 126.752 145.885 127.604C143.386 128.455 136.663 127.846 136.117 127.297C135.571 126.748 135.452 121.415 135.452 121.415C135.452 121.415 134.723 124.509 134.177 124.935C133.632 125.361 131.569 125.178 130.598 124.27C129.626 123.362 130.781 112.925 130.717 111.95C130.654 110.975 136.663 108.613 136.663 108.613C136.663 108.613 145.009 106.698 145.463 106.744C145.917 106.79 150.542 105.825 150.905 106.431C151.267 107.036 151.513 107.402 151.513 107.402"
        fill="white"
      />
      <path
        d="M150.686 109.891L149.599 118.624C149.599 118.624 149.173 125.235 149.053 125.844C148.933 126.453 148.384 126.752 145.885 127.604C143.386 128.455 136.663 127.846 136.117 127.297C135.571 126.748 135.452 121.415 135.452 121.415C135.452 121.415 134.723 124.509 134.177 124.935C133.632 125.361 131.569 125.178 130.598 124.27C129.626 123.362 130.781 112.925 130.717 111.95C130.654 110.975 136.663 108.613 136.663 108.613C136.663 108.613 145.009 106.698 145.463 106.744C145.917 106.79 150.542 105.825 150.905 106.431C151.267 107.036 151.513 107.402 151.513 107.402"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path d="M135.465 121.419L133.824 113.471" stroke="#263238" strokeMiterlimit="10" />
      <path d="M144.325 110.503L140.555 121.813" stroke="#263238" strokeMiterlimit="10" />
      <path d="M143.297 117.871L140.555 124.728" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M130.175 121.729C130.083 121.858 130.035 122.013 130.038 122.172C130.038 122.443 129.495 124.636 129.731 125.062C129.967 125.488 130.956 126.086 132.547 126.153C134.138 126.22 134.758 125.984 134.895 125.572C135.122 124.702 135.281 123.814 135.37 122.918C135.339 122.683 135.135 122.816 135.135 122.816C134.093 123.03 133.014 122.972 132.002 122.647C130.414 122.102 130.175 121.729 130.175 121.729Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M136.109 128.959C136.109 128.959 136.19 135.756 136.352 139.758C136.514 143.761 137.162 147.242 137.408 148.048C137.655 148.854 143.153 155.894 144.406 157.394C145.659 158.893 148.084 161.153 148.084 161.153L150.823 159.657C150.823 159.657 147.275 154.602 146.145 152.265C145.015 149.928 143.03 146.038 143.03 146.038L144.279 134.816L146.342 133.686"
        fill="#263238"
      />
      <path
        d="M136.109 128.959C136.109 128.959 136.19 135.756 136.352 139.758C136.514 143.761 137.162 147.242 137.408 148.048C137.655 148.854 143.153 155.894 144.406 157.394C145.659 158.893 148.084 161.153 148.084 161.153L150.823 159.657C150.823 159.657 147.275 154.602 146.145 152.265C145.015 149.928 143.03 146.038 143.03 146.038L144.279 134.816L146.342 133.686"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M145.896 134.242L144.21 135.369L143.023 146.027L144.762 149.142"
        stroke="#696969"
        strokeMiterlimit="10"
      />
      <path
        d="M148.126 161.047C148.126 161.047 146.823 162.979 146.33 163.426C145.838 163.873 143.905 165.045 143.634 165.313C143.363 165.581 144.848 165.809 145.926 165.809C147.003 165.809 148.351 165.584 149.023 165.179C149.695 164.774 152.212 162.979 152.526 162.666C152.839 162.353 150.818 159.656 150.818 159.656L148.126 161.047Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M152.525 162.666C152.634 162.557 152.468 162.174 152.204 161.716C151.222 162.747 149.036 164.951 148.125 165.236C147.111 165.535 144.531 165.334 143.711 165.261L143.633 165.324C143.366 165.595 144.848 165.82 145.925 165.82C147.002 165.82 148.35 165.595 149.022 165.19C149.695 164.785 152.212 162.976 152.525 162.666Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
