import { Flex, Heading } from "@chakra-ui/react"
import { PeriodMonth } from "@esgt/types"
import { validatePeriod } from "@esgt/utils"
import { ContentBox } from "components/ContentBox"
import { RatingPeriodSelector } from "components/RatingPeriodSelector"
import { RatingPeriodInfoTooltipIcon } from "components/Tooltips"
import { useEffect, useMemo } from "react"
import { FieldValues, useForm } from "react-hook-form"

export type NewRatingPeriodSettings = {
  periodStart: PeriodMonth
  periodEnd: PeriodMonth
  ratingProfileId: number
}

interface RatingPeriodFormProps {
  values: Partial<NewRatingPeriodSettings>
  setValues: (v: Partial<NewRatingPeriodSettings>) => void
}

export const RatingPeriodForm = ({ values, setValues }: RatingPeriodFormProps) => {
  const { register, watch } = useForm({
    defaultValues: {
      periodStartYear: values.periodStart.year,
      periodStartMonth: 1,
      periodLengthMonths: 12,
    } as FieldValues,
  })

  const periodValidity = useMemo(
    () =>
      validatePeriod({
        start: values.periodStart,
        end: values.periodEnd,
      }),
    [values.periodStart, values.periodEnd],
  )

  useEffect(() => {
    const subscription = watch((value) => {
      setValues({
        periodStart: {
          year: value.periodStartYear,
          month: value.periodStartMonth,
        },
        periodEnd: {
          year: value.periodStartYear,
          month: value.periodStartMonth + value.periodLengthMonths - 1,
        },
      })
    })
    return () => subscription.unsubscribe()
  }, [setValues, watch])

  return (
    <ContentBox padding={0}>
      <Flex gap={7} p={8} direction={"column"} as="form">
        <Flex justifyContent="space-between">
          <Heading as="h2" size="md">
            Velg periode <RatingPeriodInfoTooltipIcon />
          </Heading>
        </Flex>

        <Flex direction="column" gap={4} width="50%">
          <RatingPeriodSelector
            register={register}
            startYear={values.periodStart.year}
            startMonth={values.periodStart.month}
            periodValidity={periodValidity}
            isSelfServiceRating={true}
          />
        </Flex>
      </Flex>
    </ContentBox>
  )
}
