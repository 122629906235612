import { Box, Heading } from "@chakra-ui/react"
import styled from "styled-components"
import { Page } from "./components"

const List = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
`
const ListItem = styled.li`
	margin: 0 0 2mm;
	display: flex;
`

const Link = styled.a`
	text-decoration: none;
	width: 88%;
`

const Hidden = styled.span`
	height: 0;
	width: 0;
	opacity: 0;
`
const Reference = styled.span`
  color: #999999;
	font-size: 8pt;
	padding-right: 3mm;
	text-align: right;
	width: 12%;
`

export const EndNotes: React.FC = () => {
  return (
    <Page>
      <Heading>SLUTTNOTER</Heading>
      <Box fontSize="15px" mb="4mm" fontWeight="400">
        <List role="list">
          <ListItem id="reference-1">
            <Reference>1</Reference>
            <Link>
              Pignataro, P. (2013) Financial modeling and valuation: A practical guide to investment banking and private
              equity. Hoboken, New Jersey: John Wiley & Sons Inc. Side 6.
            </Link>
          </ListItem>

          <ListItem id="reference-2">
            <Reference>2</Reference>
            <Link>
              Pignataro, P. (2013) Financial modeling and valuation: A practical guide to investment banking and private
              equity. Hoboken, New Jersey: John Wiley & Sons Inc. Side 8.
            </Link>
          </ListItem>

          <ListItem id="reference-3">
            <Reference>3</Reference>
            <Link>
              Pignataro, P. (2013) Financial modeling and valuation: A practical guide to investment banking and private
              equity. Hoboken, New Jersey: John Wiley & Sons Inc. Side 10.
            </Link>
          </ListItem>

          <ListItem id="reference-4">
            <Hidden id="reference-5" />
            <Hidden id="reference-6" />
            <Hidden id="reference-7" />
            <Hidden id="reference-8" />
            <Reference>4, 5, 6, 7, 8</Reference>
            Norsk Standard NS 4101 Regnskapsterminologi
          </ListItem>

          <ListItem id="reference-9">
            <Reference>9</Reference>
            <Link href="https://www.altinn.no/globalassets/dokumentmaler/okonomiske_nokkeltall.doc">
              https://www.altinn.no/globalassets/dokumentmaler/okonomiske_nokkeltall.doc
            </Link>
          </ListItem>

          <ListItem id="reference-10">
            <Hidden id="reference-11" />
            <Hidden id="reference-12" />
            <Hidden id="reference-13" />
            <Hidden id="reference-14" />
            <Reference>10, 11, 12, 13, 14</Reference>
            Norsk Standard NS 4101 Regnskapsterminologi
          </ListItem>

          <ListItem id="reference-15">
            <Reference>15</Reference>
            <Link href="https://www.emisoft.com/kunnskapssenter/ghg-protokollen/hva-er-scope-2/">
              Hva er Scope 2? - Emisoft
            </Link>
          </ListItem>

          <ListItem id="reference-16">
            <Reference>16</Reference>
            <Link>Taksonomiforordningen, artikkel 19.</Link>
          </ListItem>

          <ListItem id="reference-17">
            <Reference>17</Reference>
            <Link href="https://biogassnorge.no/sirkulaerokonomi/">Sirkulærøkonomi - Biogass Norge</Link>
          </ListItem>

          <ListItem id="reference-18">
            <Reference>18</Reference>
            <Link href="https://www.regjeringen.no/contentassets/f6c799ac7c474e5b8f561d1e72d474da/t-1573n.pdf">
              Nasjonal strategi for ein grøn, sirkulær økonomi (regjeringen.no)
            </Link>
          </ListItem>

          <ListItem id="reference-19">
            <Reference>19</Reference>
            <Link href="https://biogassnorge.no/sirkulaerokonomi/">Sirkulærøkonomi - Biogass Norge</Link>
          </ListItem>

          <ListItem id="reference-20">
            <Reference>20</Reference>
            <Link href="https://biogassnorge.no/sirkulaerokonomi/">Sirkulærøkonomi - Biogass Norge</Link>
          </ListItem>

          <ListItem id="reference-21">
            <Reference>21</Reference>
            <Link href="https://www.grontpunkt.no/nyhet/rapportering-av-avfallsforebygging-og-emballasjeoptimering/">
              {/* eslint-disable-next-line max-len */}
              https://www.grontpunkt.no/nyhet/rapportering-av-avfallsforebygging-og-emballasjeoptimering/
            </Link>
          </ListItem>

          <ListItem id="reference-22">
            <Reference>22</Reference>
            <Link href="https://www.epa.gov/climateleadership/scope-1-and-scope-2-inventory-guidance">
              Scope 1 and Scope 2 Inventory Guidance | US EPA
            </Link>
          </ListItem>

          <ListItem id="reference-23">
            <Reference>23</Reference>
            <Link href="https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm">
              EMAS – Environment - European Commission (europa.eu)
            </Link>
          </ListItem>

          <ListItem id="reference-24">
            <Reference>24</Reference>
            <Link href="https://www.epa.gov/climateleadership/scope-1-and-scope-2-inventory-guidance">
              Scope 1 and Scope 2 Inventory Guidance | US EPA
            </Link>
          </ListItem>

          <ListItem id="reference-25">
            <Reference>25</Reference>
            <Link href="https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm">
              EMAS – Environment - European Commission (europa.eu)
            </Link>
          </ListItem>

          <ListItem id="reference-26">
            <Reference>26</Reference>
            <Link href="https://www.epa.gov/climateleadership/scope-1-and-scope-2-inventory-guidance">
              Scope 1 and Scope 2 Inventory Guidance | US EPA
            </Link>
          </ListItem>

          <ListItem id="reference-27">
            <Reference>27</Reference>
            <Link href="https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm">
              EMAS – Environment - European Commission (europa.eu)
            </Link>
          </ListItem>

          <ListItem id="reference-28">
            <Reference>28</Reference>
            <Link href="https://www.epa.gov/climateleadership/scope-1-and-scope-2-inventory-guidance">
              Scope 1 and Scope 2 Inventory Guidance | US EPA
            </Link>
          </ListItem>

          <ListItem id="reference-29">
            <Reference>29</Reference>
            <Link href="https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm">
              EMAS – Environment - European Commission (europa.eu)
            </Link>
          </ListItem>

          <ListItem id="reference-30">
            <Reference>30</Reference>
            <Link>
              The Greenhouse Gas Protocol (2015). A Corporate Accounting and Reporting Standard, Revised Edition. S. 27.
            </Link>
          </ListItem>

          <ListItem id="reference-31">
            <Reference>31</Reference>
            <Link href="https://ghgprotocol.org/sites/default/files/ghgp/standards/Scope%202%20Guidance_Final_0.pdf">
              Scope 2 Guidance_Final_0.pdf (ghgprotocol.org)
            </Link>
          </ListItem>

          <ListItem id="reference-32">
            <Reference>32</Reference>
            <Link href="https://www.emisoft.com/kunnskapssenter/ghg-protokollen/hva-er-scope-2/">
              Hva er Scope 2? - Emisoft
            </Link>
          </ListItem>

          <ListItem id="reference-33">
            <Reference>33</Reference>
            <Link>
              The Greenhouse Gas Protocol (2015). A Corporate Accounting and Reporting Standard, Revised Edition. S. 27.
            </Link>
          </ListItem>

          <ListItem id="reference-34">
            <Reference>34</Reference>
            <Link href="https://ghgprotocol.org/sites/default/files/ghgp/standards/Scope%202%20Guidance_Final_0.pdf">
              Scope 2 Guidance_Final_0.pdf (ghgprotocol.org)
            </Link>
          </ListItem>

          <ListItem id="reference-35">
            <Reference>35</Reference>
            <Link>
              The Greenhouse Gas Protocol (2015). A Corporate Accounting and Reporting Standard, Revised Edition. S. 27.
            </Link>
          </ListItem>

          <ListItem id="reference-36">
            <Reference>36</Reference>
            <Link>
              The Greenhouse Gas Protocol (2015). A Corporate Accounting and Reporting Standard, Revised Edition. S. 27.
            </Link>
          </ListItem>

          <ListItem id="reference-37">
            <Reference>37</Reference>
            <Link>
              The Greenhouse Gas Protocol (2015). A Corporate Accounting and Reporting Standard, Revised Edition. S. 27.
            </Link>
          </ListItem>

          <ListItem id="reference-38">
            <Reference>38</Reference>
            <Link href="https://www.sas.no/barekraft/klimakalkulator/">
              https://www.sas.no/barekraft/klimakalkulator/
            </Link>
          </ListItem>

          {theRest.map((item) => (
            <ListItem key={item.refId} id={`reference-${item.refId}`}>
              <Reference>{item.refId}</Reference>
              <Link href={item.url}>{item.text}</Link>
            </ListItem>
          ))}
        </List>
      </Box>
    </Page>
  )
}

const theRest = [
  {
    refId: 39,
    text: "https://www.vegvesen.no/kjoretoy/kjop-og-salg/kjoretoyopplysninger/sjekk-kjoretoyopplysninger/",
    url: "https://www.vegvesen.no/kjoretoy/kjop-og-salg/kjoretoyopplysninger/sjekk-kjoretoyopplysninger/",
  },
  {
    refId: 40,
    text: "https://www.ssb.no/transport-og-reiseliv/artikler-og-publikasjoner/mindre-utslipp-fra-veitrafikk-fly-og-tog?tabell=439538",
    url: "https://www.ssb.no/transport-og-reiseliv/artikler-og-publikasjoner/mindre-utslipp-fra-veitrafikk-fly-og-tog?tabell=439538",
  },
  {
    refId: 41,
    text: "Scope 3 Inventory Guidance | US EPA",
    url: "https://www.epa.gov/climateleadership/scope-3-inventory-guidance",
  },
  {
    refId: 42,
    text: "EMAS – Environment - European Commission (europa.eu)",
    url: "https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm",
  },
  {
    refId: 43,
    text: "https://www.sas.no/barekraft/klimakalkulator/",
    url: "https://www.sas.no/barekraft/klimakalkulator/",
  },
  {
    refId: 44,
    text: "https://www.vegvesen.no/kjoretoy/kjop-og-salg/kjoretoyopplysninger/sjekk-kjoretoyopplysninger/",
    url: "https://www.vegvesen.no/kjoretoy/kjop-og-salg/kjoretoyopplysninger/sjekk-kjoretoyopplysninger/",
  },
  {
    refId: 45,
    text: "https://www.volvotrucks.no/no-no/services/optimized-business/fleet-management/dynafleet-Fuel-environment.html",
    url: "https://www.volvotrucks.no/no-no/services/optimized-business/fleet-management/dynafleet-Fuel-environment.html",
  },
  {
    refId: 46,
    text: "https://www.sas.no/barekraft/klimakalkulator/",
    url: "https://www.sas.no/barekraft/klimakalkulator/",
  },
  {
    refId: 47,
    text: "Scope 3 Inventory Guidance | US EPA",
    url: "https://www.epa.gov/climateleadership/scope-3-inventory-guidance",
  },
  {
    refId: 48,
    text: "EMAS – Environment - European Commission (europa.eu)",
    url: "https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm",
  },
  {
    refId: 49,
    text: "https://www.volvotrucks.no/no-no/services/optimized-business/fleet-management/dynafleet-Fuel-environment.html",
    url: "https://www.volvotrucks.no/no-no/services/optimized-business/fleet-management/dynafleet-Fuel-environment.html",
  },
  {
    refId: 50,
    text: "https://www.sas.no/barekraft/klimakalkulator/",
    url: "https://www.sas.no/barekraft/klimakalkulator/",
  },
  {
    refId: 51,
    text: "Scope 3 Inventory Guidance | US EPA",
    url: "https://www.epa.gov/climateleadership/scope-3-inventory-guidance",
  },
  {
    refId: 52,
    text: "EMAS – Environment - European Commission (europa.eu)",
    url: "https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm",
  },
  {
    refId: 53,
    text: "Scope 3 Inventory Guidance | US EPA",
    url: "https://www.epa.gov/climateleadership/scope-3-inventory-guidance",
  },
  {
    refId: 54,
    text: "EMAS – Environment - European Commission (europa.eu)",
    url: "https://ec.europa.eu/environment/emas/emas_for_you/news/news21_en.htm",
  },
  {
    refId: 55,
    text: "Taksonomiforordningen artikkel 3, 8 og 9.",
    url: undefined,
  },
  {
    refId: 56,
    text: "Taksonomiforordningen artikkel 3, 8 og 9.",
    url: undefined,
  },
  {
    refId: 57,
    text: "Forskrift om energimerking av bygninger og energivurdering av tekniske anlegg (energimerkeforskriften for bygninger) - Lovdata",
    url: undefined,
  },
  {
    refId: 58,
    text: "Fakta om likestilling - Statistisk sentralbyrå (ssb.no)",
    url: "https://www.ssb.no/befolkning/faktaside/likestilling",
  },
  {
    refId: 59,
    text: "Fakta om likestilling - Statistisk sentralbyrå (ssb.no)",
    url: "https://www.ssb.no/befolkning/faktaside/likestilling",
  },
  {
    refId: 60,
    text: "https://lovdata.no/dokument/NL/lov/2005-06-17-62/KAPITTEL_13#%C2%A712-5",
    url: "https://lovdata.no/dokument/NL/lov/2005-06-17-62/KAPITTEL_13#%C2%A712-5",
  },
  {
    refId: 61,
    text: "https://www.fn.no/tema/fattigdom/fattigdom",
    url: "https://www.fn.no/tema/fattigdom/fattigdom",
  },
  {
    refId: 62,
    text: "https://www.fn.no/tema/fattigdom/fattigdom",
    url: "https://www.fn.no/tema/fattigdom/fattigdom",
  },
  {
    refId: 63,
    text: "https://www.fn.no/tema/fattigdom/fattigdom",
    url: "https://www.fn.no/tema/fattigdom/fattigdom",
  },
  {
    refId: 64,
    text: "https://lovdata.no/dokument/NL/lov/2005-06-17-62/KAPITTEL_11#KAPITTEL_11",
    url: "https://lovdata.no/dokument/NL/lov/2005-06-17-62/KAPITTEL_11#KAPITTEL_11",
  },
  {
    refId: 65,
    text: "https://lovdata.no/dokument/LTI/lov/2005-06-17-62",
    url: "https://lovdata.no/dokument/LTI/lov/2005-06-17-62",
  },
  {
    refId: 66,
    text: "https://lovdata.no/dokument/LTI/lov/2005-06-17-62",
    url: "https://lovdata.no/dokument/LTI/lov/2005-06-17-62",
  },
  {
    refId: 67,
    text: "https://www.fn.no/om-fn/avtaler/arbeidsliv/ilo-konvensjoner",
    url: "https://www.fn.no/om-fn/avtaler/arbeidsliv/ilo-konvensjoner",
  },
  {
    refId: 68,
    text: "Anbefalte faglige normer for inneklima. Revisjon av kunnskapsgrunnlag og normer – 2015 - FHI",
    url: "https://www.fhi.no/publ/2015/anbefalte-faglige-normer-for-innekl/",
  },
  {
    refId: 69,
    text: "Sundell, J. (2004) On the history of indoor air quality and health, Indoor Air, 14(7), p. 51-58.",
    url: undefined,
  },
  {
    refId: 70,
    text: "http://93.89.112.77/eInnsyn/RegistryEntry/ShowDocument?registryEntryId=159354&documentId=235431",
    url: "http://93.89.112.77/eInnsyn/RegistryEntry/ShowDocument?registryEntryId=159354&documentId=235431",
  },
  {
    refId: 71,
    text: "https://lovdata.no/dokument/NL/lov/1989-06-16-65",
    url: "https://lovdata.no/dokument/NL/lov/1989-06-16-65",
  },
  {
    refId: 72,
    text: "https://lovdata.no/dokument/NL/lov/1814-05-17-nn",
    url: "https://lovdata.no/dokument/NL/lov/1814-05-17-nn",
  },
  {
    refId: 73,
    text: "https://lovdata.no/dokument/NL/lov/2003-12-19-124",
    url: "https://lovdata.no/dokument/NL/lov/2003-12-19-124",
  },
  {
    refId: 74,
    text: "https://www.datatilsynet.no/regelverk-og-verktoy/lover-og-regler/",
    url: "https://www.datatilsynet.no/regelverk-og-verktoy/lover-og-regler/",
  },
  {
    refId: 75,
    text: "https://www.fn.no/Statistikk/korrupsjon",
    url: "https://www.fn.no/Statistikk/korrupsjon",
  },
  {
    refId: 76,
    text: "https://lovdata.no/dokument/NL/lov/2018-06-01-23",
    url: "https://lovdata.no/dokument/NL/lov/2018-06-01-23",
  },
  {
    refId: 78,
    text: "https://www.oecd.org/finance/esg-investing.htm",
    url: "https://www.oecd.org/finance/esg-investing.htm",
  },
  {
    refId: 79,
    text: "Council gives final green light to corporate sustainability reporting directive - Consilium (europa.eu)",
    url: "https://www.consilium.europa.eu/en/press/press-releases/2022/11/28/council-gives-final-green-light-to-corporate-sustainability-reporting-directive/",
  },
]
