import { Flex, Heading } from "@chakra-ui/react"
import { MONTH_STRINGS, ValidatorResult } from "@esgt/utils"
import { useRatingProfileNameAndDescriptionByIdQuery } from "lib/generated/graphql"
import { NewRatingMeasurementSettings } from "../orderInfo/MeasurementDetailsForm"
import { MissingWarning, Warning } from "./Warning"

interface DetailsSummaryProps {
  measurement: Partial<NewRatingMeasurementSettings>
  periodValidity: ValidatorResult
}

export const DetailsSummary = ({ measurement, periodValidity }: DetailsSummaryProps) => {
  const [{ data }] = useRatingProfileNameAndDescriptionByIdQuery({
    variables: { ratingProfileId: measurement.ratingProfileId },
  })

  const ratingProfile = data?.ratingProfile ? `${data?.ratingProfile?.name} ${data?.ratingProfile?.description}` : null

  return (
    <Flex direction="row" gap={4} justifyContent="space-between">
      <Flex basis="50%" direction="column" gap={2}>
        <Flex basis="50%" direction="row" gap={2}>
          <Flex basis="50%" direction="column" gap={1}>
            <Heading as="h3" size="sm" width="5ch">
              Fra
            </Heading>

            <MissingWarning level="error" label="start-dato">
              {measurement.periodStart.year}-{MONTH_STRINGS[measurement.periodStart.month]}
            </MissingWarning>
          </Flex>

          <Flex basis="50%" direction="column" gap={1}>
            <Heading as="h3" size="sm" width="5ch">
              Til
            </Heading>

            <MissingWarning level="error" label="slutt-dato">
              {measurement.periodEnd.year}-{MONTH_STRINGS[measurement.periodEnd.month]}
            </MissingWarning>
          </Flex>
        </Flex>

        {!periodValidity.valid && <Warning level="error">Ugyldig periode: må være i fortiden</Warning>}
      </Flex>

      <Flex basis="50%" direction="column" gap={1}>
        <Heading as="h3" size="sm">
          Profil
        </Heading>

        <MissingWarning level="error" label="målingsprofil">
          {ratingProfile || measurement.ratingProfileId}
        </MissingWarning>
      </Flex>
    </Flex>
  )
}
