import { IconType } from "react-icons"

export const IconGenericError: IconType = (props) => {
  return (
    <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Generic Error</title>
      <path
        d="M79.8266 163.275C79.8266 163.275 107.987 167.795 130.071 148.009C152.156 128.223 158.231 96.0467 163.835 70.136C169.439 44.2253 157.527 21.1799 135.52 13.7949C113.513 6.40993 103.991 25.1962 76.8416 31.1309C49.6918 37.0656 41.4304 34.4714 22.3485 54.4614C3.26657 74.4515 7.66657 112.344 31.8525 136.449C56.0384 160.554 79.8266 163.275 79.8266 163.275Z"
        fill="#E0F0EC"
      />
      <path
        d="M159.343 114.446C159.343 114.446 161.103 115.995 160.787 116.611C160.47 117.227 154.099 123.724 154.099 123.724L152.965 122.077L156.573 115.579L159.343 114.446Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M150.931 106.434C150.931 106.434 158.724 113.312 158.724 113.724C158.724 114.136 155.837 116.818 155.837 116.818L150.167 115.167L150.477 108.775"
        fill="white"
      />
      <path
        d="M150.931 106.434C150.931 106.434 158.724 113.312 158.724 113.724C158.724 114.136 155.837 116.818 155.837 116.818L150.167 115.167L150.477 108.775"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M157.383 111.767L155.014 117.022L155.323 117.744L159.963 113.826C159.963 113.826 158.931 111.661 157.383 111.767Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M123.436 117.374C123.436 117.374 124.291 118.916 124.633 119.353C124.974 119.789 126.72 120.173 126.72 120.173C126.72 120.173 128.561 125.002 128.92 125.865C129.279 126.727 130.039 128.223 130.395 128.329C130.75 128.434 133.348 129.613 133.968 128.79C134.587 127.966 134.489 125.066 134.489 125.066L129.209 119.87L128.832 118.163L123.436 117.374Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M124.341 119.363C124.341 119.363 123.552 117.325 123.45 117.121C123.348 116.917 122.707 116.065 122.809 115.804C122.911 115.544 123.214 115.4 123.686 115.738C124.157 116.075 125.041 117.997 125.041 117.997C125.041 117.997 126.424 117.293 127.033 117.899C127.642 118.504 127.776 120.131 127.776 120.131"
        fill="white"
      />
      <path
        d="M124.341 119.363C124.341 119.363 123.552 117.325 123.45 117.121C123.348 116.917 122.707 116.065 122.809 115.804C122.911 115.544 123.214 115.4 123.686 115.738C124.157 116.075 125.041 117.997 125.041 117.997C125.041 117.997 126.424 117.293 127.033 117.899C127.642 118.504 127.776 120.131 127.776 120.131"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M19.6666 124.5H122.167M149.5 124.5H156.333"
        stroke="#b3b3b3"
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6666 93.0667V5.6C19.6666 4.51261 20.0986 3.46976 20.8675 2.70086C21.6364 1.93196 22.6792 1.5 23.7666 1.5H152.233C153.321 1.5 154.364 1.93196 155.132 2.70086C155.901 3.46976 156.333 4.51261 156.333 5.6V93.0667C156.333 94.154 155.901 95.1969 155.132 95.9658C154.364 96.7347 153.321 97.1667 152.233 97.1667H23.7666C22.6792 97.1667 21.6364 96.7347 20.8675 95.9658C20.0986 95.1969 19.6666 94.154 19.6666 93.0667V93.0667Z"
        stroke="#b3b3b3"
        strokeWidth="4.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.3333 42.8H73.0667V38.6667H77.3333V42.8ZM102.933 42.8H98.6666V38.6667H102.933V42.8Z"
        fill="#b3b3b3"
      />
      <path
        d="M94.1625 60.4576C96.0879 61.3903 97.7628 62.7444 99.0544 64.4128L102.468 61.9328C95.2144 52.5625 80.7078 52.5625 73.4545 61.9328L76.8678 64.4128C78.1594 62.7444 79.8343 61.3903 81.7597 60.4576C83.6852 59.525 85.8084 59.0394 87.9611 59.0394C90.1138 59.0394 92.237 59.525 94.1625 60.4576Z"
        fill="#b3b3b3"
      />
      <path
        d="M136.087 96.3248C136.087 96.3248 135.189 98.8416 135.249 99.3203C135.309 99.7991 135.548 99.9821 135.249 100.281C134.95 100.581 133.992 101.299 134.112 101.717C134.273 102.068 134.514 102.375 134.816 102.615C134.816 102.615 135.474 105.252 136.132 105.551C136.791 105.85 137.812 105.611 137.812 105.611L138.516 107.529L143.549 106.272L143.25 103.217C143.25 103.217 144.746 100.341 144.387 98.6023C144.028 96.8634 140.913 95.1879 138.875 95.1879C136.836 95.1879 136.266 95.8461 136.087 96.3248Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path d="M136.118 103.34C136.118 103.34 136.903 103.238 137.143 102.759" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M136.189 100.267C136.189 100.475 136.118 100.644 136.034 100.644C135.949 100.644 135.883 100.475 135.883 100.267C135.883 100.06 135.949 99.8906 136.034 99.8906C136.118 99.8906 136.189 100.06 136.189 100.267Z"
        fill="#263238"
      />
      <path
        d="M135.812 99.4154C135.812 99.4154 136.256 98.8698 136.516 99.5491"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M137.28 97.2857C137.28 97.2857 135.302 97.4054 135.168 95.5469C135.034 93.6883 137.745 92.671 138.762 92.671C139.779 92.671 139.061 93.6918 139.061 93.6918C139.061 93.6918 141.578 92.9702 142.229 93.632C142.88 94.2937 142.349 94.8288 142.349 94.8288C142.349 94.8288 145.644 96.2051 145.644 98.6022C145.644 100.999 143.908 103.337 143.609 103.815C143.31 104.294 142.588 104.713 141.272 104.773C139.955 104.833 139.16 104.174 138.875 103.277L138.575 102.376C138.575 102.376 139.593 101.299 139.114 100.401C138.635 99.5033 138.336 100.049 138.216 100.401C138.097 100.753 138.336 101.105 138.097 101.105C137.857 101.105 138.037 100.806 137.393 100.148C136.748 99.4893 136.192 99.1865 136.432 98.5283C136.635 98.0651 136.923 97.6437 137.28 97.2857Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M137.108 107.237L136.393 108.817L145.489 106.748L144.436 105.435L137.108 107.237Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M140.761 161.234C140.761 161.234 138.051 161.881 136.474 161.881C134.897 161.881 134.207 161.8 134.207 162.325C134.207 162.849 136.717 163.821 138.171 164.388C139.624 164.954 142.458 165.764 142.701 165.683C142.944 165.602 143.429 162.649 143.429 162.649L140.761 161.234Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M134.369 162.019C134.316 162.05 134.273 162.095 134.245 162.149C134.216 162.203 134.203 162.264 134.207 162.325C134.207 162.853 136.717 163.821 138.171 164.388C139.624 164.954 142.458 165.764 142.701 165.683C142.757 165.665 142.824 165.493 142.898 165.246C140.064 164.68 135.538 162.575 134.369 162.019Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M135.907 126.657C135.89 128.614 135.971 130.571 136.15 132.521C136.432 135.228 137.727 147.118 137.847 148.98C137.966 150.843 140.761 161.234 140.761 161.234L143.429 162.642C143.429 162.642 144.763 151.075 144.763 149.135V145.9C144.763 145.9 147.108 136.717 147.836 134.534C148.565 132.352 148.928 128.022 148.85 125.597C146.713 126.429 144.454 126.905 142.162 127.005C140.071 127.125 137.972 127.008 135.907 126.657Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M150.688 109.891L149.6 118.624C149.6 118.624 149.174 125.235 149.054 125.844C148.935 126.452 148.386 126.752 145.886 127.604C143.387 128.455 136.664 127.846 136.118 127.297C135.573 126.748 135.453 121.415 135.453 121.415C135.453 121.415 134.724 124.509 134.179 124.935C133.633 125.361 131.571 125.178 130.599 124.27C129.627 123.362 130.782 112.925 130.719 111.95C130.655 110.975 136.664 108.613 136.664 108.613C136.664 108.613 145.01 106.698 145.464 106.744C145.918 106.79 150.543 105.825 150.906 106.431C151.268 107.036 151.515 107.402 151.515 107.402"
        fill="white"
      />
      <path
        d="M150.688 109.891L149.6 118.624C149.6 118.624 149.174 125.235 149.054 125.844C148.935 126.452 148.386 126.752 145.886 127.604C143.387 128.455 136.664 127.846 136.118 127.297C135.573 126.748 135.453 121.415 135.453 121.415C135.453 121.415 134.724 124.509 134.179 124.935C133.633 125.361 131.571 125.178 130.599 124.27C129.627 123.362 130.782 112.925 130.719 111.95C130.655 110.975 136.664 108.613 136.664 108.613C136.664 108.613 145.01 106.698 145.464 106.744C145.918 106.79 150.543 105.825 150.906 106.431C151.268 107.036 151.515 107.402 151.515 107.402"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path d="M135.464 121.419L133.823 113.471" stroke="#263238" strokeMiterlimit="10" />
      <path d="M144.324 110.503L140.554 121.813" stroke="#263238" strokeMiterlimit="10" />
      <path d="M143.296 117.871L140.554 124.728" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M130.173 121.729C130.081 121.858 130.033 122.013 130.036 122.172C130.036 122.443 129.494 124.636 129.73 125.062C129.965 125.488 130.955 126.086 132.546 126.153C134.137 126.22 134.756 125.984 134.893 125.572C135.121 124.702 135.28 123.814 135.369 122.918C135.337 122.683 135.133 122.816 135.133 122.816C134.091 123.03 133.012 122.972 132 122.647C130.412 122.102 130.173 121.729 130.173 121.729Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M136.108 128.959C136.108 128.959 136.189 135.756 136.351 139.758C136.513 143.76 137.16 147.242 137.407 148.048C137.653 148.854 143.151 155.894 144.404 157.393C145.658 158.893 148.083 161.153 148.083 161.153L150.821 159.657C150.821 159.657 147.273 154.602 146.143 152.265C145.013 149.927 143.028 146.038 143.028 146.038L144.278 134.816L146.34 133.686"
        fill="#263238"
      />
      <path
        d="M136.108 128.959C136.108 128.959 136.189 135.756 136.351 139.758C136.513 143.76 137.16 147.242 137.407 148.048C137.653 148.854 143.151 155.894 144.404 157.393C145.658 158.893 148.083 161.153 148.083 161.153L150.821 159.657C150.821 159.657 147.273 154.602 146.143 152.265C145.013 149.927 143.028 146.038 143.028 146.038L144.278 134.816L146.34 133.686"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M145.897 134.242L144.211 135.369L143.025 146.027L144.764 149.142"
        stroke="#696969"
        strokeMiterlimit="10"
      />
      <path
        d="M148.125 161.047C148.125 161.047 146.823 162.979 146.33 163.427C145.837 163.874 143.905 165.046 143.634 165.313C143.362 165.581 144.848 165.81 145.925 165.81C147.002 165.81 148.35 165.584 149.023 165.179C149.695 164.775 152.212 162.979 152.525 162.666C152.838 162.353 150.818 159.657 150.818 159.657L148.125 161.047Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M152.525 162.666C152.634 162.557 152.469 162.173 152.205 161.716C151.223 162.747 149.037 164.951 148.125 165.236C147.111 165.535 144.531 165.334 143.711 165.26L143.634 165.324C143.366 165.595 144.848 165.82 145.925 165.82C147.002 165.82 148.35 165.595 149.023 165.19C149.695 164.785 152.212 162.976 152.525 162.666Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
