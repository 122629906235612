import { Button, Flex, FlexProps, Heading } from "@chakra-ui/react"
import { doesUserHavePermission } from "@esgt/auth"
import { ContentBox } from "components/ContentBox"
import { InfoTooltipIcon } from "components/Tooltips/InfoTooltipIcon"
import { I18nKeys } from "lib/i18n/nb"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { useRating } from "lib/providers/RatingProvider"
import { ReactNode, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "usehooks-ts"

interface OrderPropertyRowProps extends FlexProps {
  children: ReactNode
  title: string
  infoBody?: I18nKeys
  isEditing?: boolean
  hasChanged?: boolean
  onEditStart?: () => void
  onEditEnd?: () => void
  onSubmit?: () => void
  isDisabled?: boolean
}

export const OrderPropertyRow = ({
  title,
  children,
  infoBody,
  isEditing,
  hasChanged,
  onEditStart,
  onEditEnd,
  onSubmit,
  isDisabled,
  ...flexProps
}: OrderPropertyRowProps) => {
  const { isEditable, ratingId, ratingState } = useRating()

  const { user } = useCurrentUser()
  const canUpdate =
    doesUserHavePermission(user, "rating:update", ratingId) ||
    doesUserHavePermission(user, "account:rating:update_all", ratingState?.accountId)

  const { width } = useWindowSize()
  const isWide = width > 1000
  const buttonClicked = useCallback(() => {
    if (!isEditing && onEditStart) {
      onEditStart()
    } else if (isEditing && onSubmit && hasChanged) {
      onSubmit()
    } else {
      onEditEnd()
    }
  }, [isEditing, onEditStart, onSubmit, hasChanged, onEditEnd])

  const { t } = useTranslation()

  return (
    <Flex gap={isWide ? "35px" : "16px"} direction={isWide ? "row" : "column"}>
      <ContentBox direction="column" gap={6} {...flexProps} basis="65%" flexGrow={1} position="relative">
        <Heading as="h2" size="md">
          {title}
          <InfoTooltipIcon label={t(infoBody)} placement="right" />
        </Heading>

        {canUpdate && isEditable && onEditStart && (
          <Button
            size="sm"
            position="absolute"
            top="32px"
            right="32px"
            variant="outline"
            background={isEditing && !hasChanged ? "neutral.100" : ""}
            onClick={buttonClicked}
            isDisabled={isDisabled}
          >
            {isEditing ? (hasChanged ? "Lagre" : "Avbryt") : "Endre"}
          </Button>
        )}

        {children}
      </ContentBox>
    </Flex>
  )
}
