import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"
import { DataPointSource } from "@esgt/helpers"
import { Question, RatingUpload } from "@esgt/types"
import { datapointPrecisions } from "@esgt/utils"
import { DocumentIcon } from "assets/icons/DocumentIcon"
import { I18nText, MethodI18nFragment } from "components/I18nText"
import { InputNumber } from "components/Input/Number"
import { InfoTooltipIcon } from "components/Tooltips/InfoTooltipIcon"
import { config } from "lib/helpers/config"
import { formatValue } from "lib/helpers/formatValue"
import { I18nKeys } from "lib/i18n/nb"
import { useAuth } from "lib/providers/AuthProvider"
import { useRating } from "lib/providers/RatingProvider"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface DatapointProps {
  ratingId: string
  name: string
  autoFocus: boolean
  isLoading: boolean
  source: DataPointSource
  sourceQuestion: Question
  questionUploads: Array<RatingUpload>
  questionAnswers?: Record<string, any>
  parentQuestionAnswers?: boolean
  allUploads: Array<RatingUpload>
}

export const Datapoint = ({
  ratingId,
  name,
  autoFocus,
  isLoading,
  source,
  sourceQuestion,
  questionUploads,
  questionAnswers,
  parentQuestionAnswers,
  allUploads,
}: DatapointProps) => {
  const { control } = useFormContext<Record<string, any>>()
  const { i18n } = useTranslation()
  const { isEditable } = useRating()

  const shouldLookDisabled =
    sourceQuestion && (questionAnswers || parentQuestionAnswers) && questionAnswers?.primaryAnswer !== "true"

  return (
    <AccordionItem isDisabled={!sourceQuestion && !source}>
      <Grid as="h3" gap="16px" templateColumns="1fr 170px 32px" alignItems="center" ml="32px" mr="16px">
        <Text size="lg" color={sourceQuestion && questionAnswers?.primaryAnswer !== "true" ? "neutral.500" : "inherit"}>
          {name}
          {i18n.exists(`datapoints.${name}`) && (
            <InfoTooltipIcon
              label={<I18nText inlineOnly i18nKey={`datapoints.${name}` as I18nKeys} />}
              placement="right"
            />
          )}
        </Text>

        <Flex padding="16px 0">
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <InputNumber
                {...field}
                size="sm"
                isDisabled={isLoading || !isEditable}
                opacity={shouldLookDisabled ? 0.4 : 1}
                precision={datapointPrecisions(name)}
                autoFocus={autoFocus}
                textAlign="right"
                padding="11px"
                fontSize="lg"
                boxSizing="content-box"
                thousands
              />
            )}
          />
        </Flex>

        {sourceQuestion || source ? (
          <AccordionButton justifyContent="center">
            <AccordionIcon />
          </AccordionButton>
        ) : (
          <div />
        )}
      </Grid>

      {sourceQuestion && (
        <AccordionPanel padding={0} bg="neutral.50">
          <Flex direction="column">
            <Heading as="h4" size="h6" m="16px 32px">
              {`${sourceQuestion.questionNumber} `}
              <MethodI18nFragment text={sourceQuestion.text} />
              {questionAnswers?.primaryAnswer !== undefined && (
                <>
                  {" | "}
                  {questionAnswers?.primaryAnswer === "true" ? "Ja" : "Nei"}
                </>
              )}
            </Heading>

            {questionAnswers?.primaryAnswer === "true" && sourceQuestion.textInputs && (
              <Flex direction="column">
                {sourceQuestion.textInputs.map((textInput, idx) => (
                  <Box key={idx} borderTop="1px solid" borderColor="neutral.100" p="16px 32px">
                    <Text color="neutral.700">
                      {textInput && (
                        <>
                          <MethodI18nFragment text={textInput?.text} />:
                        </>
                      )}
                    </Text>
                    <Text>{formatValue(questionAnswers[`input_${idx}`])}</Text>
                  </Box>
                ))}
              </Flex>
            )}

            {questionAnswers?.primaryAnswer === "true" && questionUploads && (
              <Flex direction="column" borderTop="1px solid" borderColor="neutral.100" p="16px 32px">
                <Heading mb="16px" size="h6">
                  Dokumentasjon
                </Heading>

                <UploadsList ratingId={ratingId} uploads={questionUploads} />
              </Flex>
            )}
          </Flex>
        </AccordionPanel>
      )}

      {source && !sourceQuestion && (
        <AccordionPanel padding={0} bg="neutral.50">
          <Flex direction="column" p="16px 32px">
            <Heading as="h4" size="h6">
              Kilde: {source}
            </Heading>
            {source === "Aldersfordelt saldoliste" && (
              <Box mt="8px">
                {allUploads && (
                  <UploadsList
                    ratingId={ratingId}
                    uploads={allUploads.filter((upload) => upload.scope === "aldersfordelt-saldoliste")}
                  />
                )}
              </Box>
            )}
          </Flex>
        </AccordionPanel>
      )}
    </AccordionItem>
  )
}

const UploadsList = ({ ratingId, uploads }: { ratingId: string; uploads: RatingUpload[] }) => {
  const { token } = useAuth()
  return (
    <Box>
      {uploads.map((upload) => (
        <Link
          key={upload.uploadId}
          href={`${config.API_URL}/download-rating-upload?ratingId=${ratingId}&uploadId=${upload.uploadId}&authToken=${token}`}
          target="_blank"
          rel="noreferrer"
          mb="12px"
          color="blue.500"
        >
          <Grid templateColumns="32px 1fr" alignItems="center">
            <DocumentIcon size={20} />
            <Text>{upload.filename}</Text>
          </Grid>
        </Link>
      ))}
    </Box>
  )
}
