import { RatingCommit, RatingState, periodFromRaw, ratingStateReducer } from "@esgt/event-store"
import { submissionProgressPerspective } from "@esgt/helpers"
import { reviveMathjsData } from "@esgt/helpers"
import {
  RatingProviderSubscriptionDocument,
  RatingProviderSubscriptionSubscription,
  RatingProviderSubscriptionSubscriptionVariables,
  RatingType,
  useRatingInstanceQuery,
  useRatingProfileAndRevisionQuery,
} from "lib/generated/graphql"
import { useHandleErrors } from "lib/helpers/useHandleErrors"
import { useMethod } from "lib/hooks"
import merge from "lodash.merge"
import { Reducer, useEffect, useMemo, useReducer, useRef, useState } from "react"
import { useWsClient } from "../WsClientProvider"
import { RatingContext } from "./RatingContext"

interface RatingProviderProps {
  ratingId: string
  children?: React.ReactNode
}

type ReducerAction =
  | {
      type: "commit"
      commit: RatingCommit
    }
  | {
      type: "init"
      state: RatingState
    }

export const RatingProvider: React.FC<RatingProviderProps> = ({ children, ratingId }) => {
  const wsClient = useWsClient()
  const handleErrors = useHandleErrors()

  const [initialHeadVersion, setInitialHeadVersion] = useState<number>(Infinity)
  const [headVersionProcessed, setHeadVersionProcessed] = useState<number>(0)
  const [retryCount, setRetryCount] = useState<number>(0)
  const [isReadyForCompletion, setIsReadyForCompletion] = useState<boolean>(false)

  const retryTimer = useRef<NodeJS.Timeout | undefined>()

  const [ratingInstanceQuery, executeRatingInstanceQuery] = useRatingInstanceQuery({
    variables: { key: ratingId },
    requestPolicy: "cache-first",
  })

  const ratingVersion = ratingInstanceQuery.data?.ratingInstance?.version || -1

  const [state, dispatch] = useReducer<Reducer<RatingState | undefined, ReducerAction>>((currentState, action) => {
    if (action.type === "init") {
      return {
        ...reviveMathjsData(action.state),
        period: periodFromRaw(action.state.period),
      }
    }
    const commit = action.commit

    return commit.events.reduce((m, ev) => ratingStateReducer(m, commit, ev), currentState)
  }, undefined)

  const [ratingProfileResult] = useRatingProfileAndRevisionQuery({
    variables: {
      ratingProfileId: state?.ratingProfileId || -1,
      // If not defined (older reports) the back-end will return the current active version of a rating profile
      revision: state?.ratingProfileVersion,
    },
    pause: !state?.ratingProfileId,
  })

  const ratingProfile = useMemo(() => {
    return ratingProfileResult.data?.ratingProfile
  }, [ratingProfileResult])

  const { method } = useMethod(
    (state?.ratingType === RatingType.Free && state?.methodId) ||
      ratingProfileResult.data?.ratingProfile?.revisionOrActiveRevision?.methodId,
  )

  const delayedRetry = async () => {
    await new Promise<void>((resolve) => {
      retryTimer.current = setTimeout(() => {
        retryTimer.current = undefined
        resolve()
      }, 2000)
    })

    setRetryCount(retryCount + 1)
  }

  // Fetch initial head version (we will postpone state updates until we reach this version in terms of commits)
  useEffect(() => {
    if (
      initialHeadVersion === Infinity &&
      ratingInstanceQuery.fetching === false &&
      ratingInstanceQuery.data?.ratingInstance?.version
    ) {
      setInitialHeadVersion(ratingVersion)
      setHeadVersionProcessed(ratingVersion)
    }

    if (!state && ratingInstanceQuery.data?.ratingInstance?.state) {
      dispatch({ type: "init", state: ratingInstanceQuery.data.ratingInstance.state })
    }
  }, [ratingInstanceQuery.fetching, ratingInstanceQuery.data, initialHeadVersion])

  useEffect(
    function subscribe() {
      if (ratingInstanceQuery.error) {
        executeRatingInstanceQuery()
      }

      if (ratingInstanceQuery.fetching) return

      const unsubscribe = wsClient.subscribe<
        RatingProviderSubscriptionSubscription,
        RatingProviderSubscriptionSubscriptionVariables
      >(
        {
          query: RatingProviderSubscriptionDocument.loc?.source.body || "",
          variables: { minVersion: ratingVersion + 1, key: ratingId },
        },
        {
          next: ({ data }) => {
            const commits = data?.ratingCommits

            if (!commits || !commits.length) return

            for (const commit of commits) {
              dispatch({ type: "commit", commit: commit as RatingCommit })
              setHeadVersionProcessed(commit.aggregateVersion)
            }
          },
          error: async (e) => {
            if (!handleErrors(e)) {
              console.log("RatingProvider connection error, delayed retry...", e)
              delayedRetry()
            }
          },
          complete: () => {},
        },
      )
      return () => {
        unsubscribe()
        clearTimeout(retryTimer.current as any as number)
      }
    },
    [ratingInstanceQuery.data],
  )

  const ratingProfileConfig = useMemo(() => {
    const combinedConfig = merge(
      {},
      ratingProfile?.baseline?.activeRevision?.config,
      ratingProfile?.revisionOrActiveRevision?.config,
    )
    return combinedConfig
  }, [ratingProfile])

  useEffect(() => {
    if (ratingProfileResult.fetching) return
    if (!ratingProfileResult.data) return
    if (!method) return
    if (!ratingProfileConfig) return
    if (!state || !state.datapoints) return

    const is = method.config.kpiDimensions.every((dimension) => {
      return dimension.categories.every((category) => {
        return category.kpiIds.every((id) => {
          if (ratingProfileConfig?.kpis?.[id] && ratingProfileConfig?.kpis?.[id].enabled === false) {
            return true
          }
          return state.approvedKpis[id]
        })
      })
    })

    if (is !== isReadyForCompletion) {
      setIsReadyForCompletion(is)
    }
  }, [ratingProfileConfig, state, ratingProfileResult, method])

  const isEditable = useMemo(() => {
    return !["ABORTED", "COMPLETED", "PROCESSING_DOCUMENTATION"].includes(state?.phase || "")
  }, [state])

  const ratingType = state?.ratingType
  const isFree = ratingType === RatingType.Free
  const upgradedToFull = state?.ratingUpgradedToFull
  const isReady = headVersionProcessed >= initialHeadVersion

  return (
    <RatingContext.Provider
      value={{
        ratingId,
        ratingState: state,
        ratingProfile,
        ratingProfileConfig,
        method,
        fetching: ratingInstanceQuery.fetching,
        isReady: isReady,
        submissionProgress:
          state && ratingProfile && method
            ? submissionProgressPerspective(state, method.config, ratingProfileConfig)
            : undefined,
        isEditable,
        isReadyForCompletion,
        ratingType,
        isFree,
        upgradedToFull,
        dataFilesProcessingStatus: state?.dataFilesProcessingStatus || {},
      }}
    >
      {children}
    </RatingContext.Provider>
  )
}
