import { Flex, Grid, Icon, Link, Text } from "@chakra-ui/react"
import { RatingState } from "@esgt/event-store"
import { Question } from "@esgt/types"
import { MethodI18nFragment } from "components/I18nText"
import { MarkdownText } from "components/MarkdownText"
import { config } from "lib/helpers/config"
import { formatValue } from "lib/helpers/formatValue"
import { getLanguage } from "lib/i18n/utils"
import { useAuth } from "lib/providers/AuthProvider"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"

interface AnswerProps {
  question: Question
  ratingState: RatingState
  ratingId: string
}

export const Answer: React.FC<AnswerProps> = ({ question, ratingState, ratingId }) => {
  const { i18n } = useTranslation()
  const { token } = useAuth()
  const primaryAnswer = question.noPrimaryValue ? "true" : ratingState.answerValues[question.id]?.primaryAnswer

  const expanded = primaryAnswer === "true"

  const uploads = ratingState?.uploads?.filter(
    (upload) => upload.ratingFileType === "generic" && upload.scope === `questions/${question.id}`,
  )

  if (!["true", "false"].includes(primaryAnswer)) {
    return null
  }

  return (
    <>
      <Icon as={expanded ? MdCheckBox : MdCheckBoxOutlineBlank} color="gray.400" boxSize="21px" mt="2px" />

      <Flex flexFlow="column nowrap" gap="6px">
        <Text as="div" fontSize="16px" color="gray.600">
          <MarkdownText markdown={`**${question.questionNumber}** - ${getLanguage(question.text, i18n)}`} />
        </Text>
        {expanded && (
          <>
            <Grid m="8px 0" gridTemplateColumns={"2fr 1fr"} gap="8px" alignItems="top">
              {question.textInputs?.map((textInput, i) => (
                <Fragment key={i}>
                  {textInput.text && (
                    <Text as="span" fontSize="14px" color="gray.500">
                      <MethodI18nFragment text={textInput.text} />
                    </Text>
                  )}

                  <Text as="span" minWidth="200px" fontSize="14px" color="green.600">
                    {formatValue(ratingState?.answerValues[question.id]?.[`input_${i}`])}{" "}
                    {textInput.unit && <MethodI18nFragment text={textInput.unit} />}
                  </Text>
                </Fragment>
              ))}
            </Grid>
            <Flex flexFlow="row wrap" gap="16px">
              {uploads?.map((upload) => (
                <Link
                  key={upload.uploadId}
                  href={`${config.API_URL}/download-rating-upload?ratingId=${ratingId}&uploadId=${upload.uploadId}&authToken=${token}`}
                  target="_blank"
                  rel="noreferrer"
                  mb="12px"
                  color="blue.500"
                >
                  <Text fontSize="14px" fontStyle="italic" key={upload.uploadId}>
                    {upload.filename}
                  </Text>
                </Link>
              ))}
            </Flex>
          </>
        )}
      </Flex>
    </>
  )
}
