import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { calculateOmpostering } from "@esgt/event-store"
import { DatafileAnomaly, OmposteringsRule } from "@esgt/types"
import { BalanceSheetWithEditing } from "components/BalanceSheetWithEditing"
import { useOmposteringMutation } from "lib/generated/graphql"
import { config } from "lib/helpers/config"
import { prettyFormatAmount } from "lib/helpers/misc"
import { useAuth } from "lib/providers/AuthProvider"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

export const OmposteringsModal = () => {
  const { anomalyId } = useParams()
  const { token } = useAuth()
  const {
    ratingId,
    ratingState: { anomalies, datapoints, uploads },
  } = useRating()
  const navigate = useNavigate()
  const { handleSubmit, register } = useForm({
    mode: "onChange",
  })

  const saldobalanse = useMemo(() => {
    return uploads.find((u) => u.ratingFileType === "generic" && u.scope === "saldobalanse")
  }, [uploads])

  const [mutationStatus, mutate] = useOmposteringMutation()
  const anomaly: DatafileAnomaly = useMemo(() => anomalies[anomalyId], [anomalies, anomalyId])
  const [omposteringsRule, setOmposteringsRule] = useState<OmposteringsRule>(anomaly.omposteringsRules[0])
  const close = () => navigate("..")

  const onSubmit = () => {
    mutate({
      omposteringsRule: {
        affectedDatapoints: omposteringsRule.affectedDatapoints,
        from: omposteringsRule.from,
        to: omposteringsRule.to,
      },
      amount: anomaly.amount.toNumber(),
      ratingId,
      anomalyId,
    }).then(close)
  }

  return (
    <Modal onClose={close} isOpen={true} size="full">
      <ModalOverlay />
      <ModalContent maxWidth="1250px" margin={10} as="form" minH="0" onSubmit={handleSubmit(onSubmit)}>
        <ModalCloseButton top={8} right={8} />
        <ModalBody p="0">
          <Flex height="80vh">
            <Flex width="625px" mb="32px" direction="column" p="32px" overflow="scroll">
              <Heading size="h4" color="neutral.500">
                {anomaly.concerning} {anomaly.message}
              </Heading>
              <Heading size="h3" mt="16px" mb="32px">
                Omklassifisering av {prettyFormatAmount(anomaly.amount.toNumber())}
              </Heading>
              <Flex direction="column" maxW="300px">
                <Text fontWeight="500" mb="4px">
                  Omklassifiseres til
                </Text>
                <Select
                  flex={1}
                  {...register("to")}
                  onChange={(event) =>
                    setOmposteringsRule(anomaly.omposteringsRules.find((e) => e.to === event.target.value))
                  }
                >
                  {anomaly.omposteringsRules.map((v) => (
                    <option key={v.to}>{v.to}</option>
                  ))}
                </Select>
              </Flex>
              <Table variant="roundedTop" mt={8}>
                <Thead>
                  <Tr>
                    <Th />
                    <Th>Datapunkt</Th>
                    <Th textAlign="right">Opprinnelig verdi</Th>
                    <Th textAlign="right">Ny verdi</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {omposteringsRule.from && (
                    <Tr>
                      <Td fontWeight="500">Fra</Td>
                      <Td>{omposteringsRule.from}</Td>
                      <Td textAlign="right">
                        <span>{prettyFormatAmount(Number(datapoints[omposteringsRule.from]))}</span>
                      </Td>
                      <Td textAlign="right">
                        {prettyFormatAmount(
                          calculateOmpostering(datapoints[omposteringsRule.from], anomaly.amount).toNumber(),
                        )}
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td fontWeight="500">Til</Td>
                    <Td>{omposteringsRule.to}</Td>
                    <Td textAlign="right">
                      <span>{prettyFormatAmount(Number(datapoints[omposteringsRule.to]))}</span>
                    </Td>
                    <Td textAlign="right">
                      {prettyFormatAmount(
                        calculateOmpostering(datapoints[omposteringsRule.to], anomaly.amount).toNumber(),
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td style={{ paddingTop: "32px" }} colSpan={4} fontWeight="500">
                      Påvirkede datapunkter:
                    </Td>
                  </Tr>
                  {omposteringsRule.affectedDatapoints.map((d, k) => (
                    <Tr key={k}>
                      <Td colSpan={2}>{d}</Td>
                      <Td textAlign="right">
                        <span>{prettyFormatAmount(Number(datapoints[d]))}</span>
                      </Td>
                      <Td textAlign="right">
                        {prettyFormatAmount(calculateOmpostering(datapoints[d], anomaly.amount).toNumber())}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
            <Flex
              direction="column"
              width="625px"
              bg="gray.50"
              borderLeft="1px solid"
              borderColor="gray.100"
              p="32px"
              borderRadius="0 6px 6px 0"
              overflow="auto"
            >
              <Flex flex={2}>
                <BalanceSheetWithEditing
                  changes={[
                    ...(omposteringsRule.from
                      ? [
                          {
                            datapoint: omposteringsRule.from as any,
                            amount: calculateOmpostering(datapoints[omposteringsRule.from], anomaly.amount),
                          },
                        ]
                      : []),
                    {
                      datapoint: omposteringsRule.to as any,
                      amount: calculateOmpostering(datapoints[omposteringsRule.to], anomaly.amount),
                    },
                    ...omposteringsRule.affectedDatapoints.map((c) => ({
                      amount: calculateOmpostering(datapoints[c], anomaly.amount),
                      datapoint: c as any,
                    })),
                  ]}
                />
              </Flex>
              <ModalFooter justifyContent="space-between" p={0} mt="32px">
                {saldobalanse && (
                  <Box>
                    <Text>Trenger du saldobalansen?</Text>
                    <Link
                      variant="blueUnderlined"
                      href={`${config.API_URL}/download-rating-upload?ratingId=${ratingId}&uploadId=${saldobalanse.uploadId}&authToken=${token}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Last den ned her
                    </Link>
                  </Box>
                )}
                <Flex>
                  {!mutationStatus.fetching && (
                    <Button variant="outline" size="lg" onClick={close} isLoading={mutationStatus.fetching}>
                      Avbryt
                    </Button>
                  )}
                  <Button colorScheme="green" size="lg" ml={3} type="submit" isLoading={mutationStatus.fetching}>
                    Fullfør
                  </Button>
                </Flex>
              </ModalFooter>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
