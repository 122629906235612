import { Input, InputProps } from "@chakra-ui/react"
import { forwardRef } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"

type Value = number | null

export interface InputNumberProps extends Omit<InputProps, "value" | "defaultValue" | "onChange" | "type"> {
  align?: "left" | "right" | "center"
  decimalSeparator?: string
  defaultValue?: Value
  fixedPrecisionScale?: boolean
  max?: number | string
  min?: number | string
  onChange?: (value: Value) => void
  precision?: number
  thousands?: boolean
  value?: Value
  hasError?: boolean
}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      textAlign = "right",
      decimalSeparator = ",",
      defaultValue,
      fixedPrecisionScale,
      isDisabled,
      hasError,
      max,
      min,
      onBlur,
      onChange,
      onFocus,
      precision = 0,
      thousands = false,
      value,
      ...props
    },
    ref,
  ) => {
    const thousandSeparator = thousands ? " " : undefined

    const isAllowed = ({ floatValue }: NumberFormatValues) => {
      if (floatValue === undefined) return true

      if (min !== undefined && Number(floatValue) < Number(min)) return false
      if (max !== undefined && Number(floatValue) > Number(max)) return false

      return true
    }

    const allowNegative = min === undefined || Number(min) < 0

    return (
      <NumericFormat
        customInput={Input}
        role="textbox"
        aria-invalid={hasError ? "true" : "false"}
        getInputRef={ref}
        valueIsNumericString={true}
        isDisabled={isDisabled}
        allowNegative={allowNegative}
        value={value}
        defaultValue={defaultValue}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={precision}
        fixedDecimalScale={fixedPrecisionScale}
        inputMode={precision === 0 ? "numeric" : "decimal"}
        isAllowed={isAllowed}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={({ floatValue }) => {
          onChange?.(floatValue ?? null)
        }}
        textAlign={textAlign}
        {...props}
      />
    )
  },
)
