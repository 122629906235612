import { Box, Heading } from "@chakra-ui/react"
import { useRating } from "lib/providers/RatingProvider"
import { Page } from "../components"
import { OrderInfo } from "./OrderInfo"

export const FreeIntro: React.FC = () => {
  const { ratingState } = useRating()
  if (!ratingState) return null
  const businessName = ratingState.brreg.navn
  return (
    <Page>
      <Heading mb={"4mm"} fontWeight={300} fontSize={"18pt"}>
        Ufullstendig og ikke-verifisert bærekraftsrapport
      </Heading>

      <Box mb="4mm" fontWeight="400">
        Denne bærekraftsrapporten er generert på data fra {businessName} og udokumenterte svar på spørsmål om
        virksomheten, rapporten er ufullstendig og ikke kontrollert for avvik, feil eller mangler. Rapporten er ikke
        verifisert av tredjepart.
      </Box>
      <Box mb="4mm">
        Rapporten gir ikke et fullverdig bilde på virksomhetens bærekraft, men et glimt av din virksomhets
        bæredyktighet, og et innblikk i hvordan ESG Trackr verktøyet fungerer.
      </Box>
      <Box mb="4mm">
        Denne rapporten gir en indikasjon på hvordan {businessName} scorer på bærekraft sett i sammenheng med måltall
        uten utregning og resultat. Rapporten mangler indikatorer og baseres på selvrapportering, og er derfor ikke
        etterprøvd eller etterprøvbar.
      </Box>
      <Box mb="4mm">
        Datagrunnlaget for denne rapporten kan benyttes til å svare ut en fullverdig og verifisert bærekraftsrapport, og
        er således et første steg for at en virksomhet skal kunne gjennomføre en bærekraftsrapport gjennom ESG Trackr.
      </Box>
      <OrderInfo />
    </Page>
  )
}
