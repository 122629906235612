import { Box, Heading } from "@chakra-ui/react"
import { useRatingAccount } from "lib/providers/RatingAccount"
import { Page } from "../components"
import { OrderInfo } from "./OrderInfo"

export const Intro: React.FC = () => {
  const { accountTitle } = useRatingAccount()

  return (
    <Page>
      <Heading>FORORD</Heading>
      <Box mb="4mm" fontWeight="400">
        Dere har bærekraftmålt deres virksomhet av en objektiv tredjepart på plattformen ESG Trackr.
      </Box>
      <Box mb="4mm">
        Målingen uttrykker deres virksomhets bærekraft på et forhåndsdefinert indikatorsett, hvor resultatene gis en
        referanse mot måltall der det eksisterer. Resultat presenteres oppsummert i en sol og per indikator.
      </Box>
      <Box mb="4mm">
        Målingen har legitimitet og gyldighet også over landegrensene siden resultatene er etterprøvbare og basert på
        objektivt konstaterbare fakta, med referanse til internasjonale rammeverk. Indikatorsettet er satt i relasjon
        til FN&apos;s bærekraftsmål (SDG - Sustainable Development Goals), ESG (Environmental, Social, Governance) Task
        force Climate-Related Financial Disclosers (TCFD), Global Reporting Initative (GRI), Nordic Sustainability
        Reporting Standards (NSRS) og European Sustainability Reporting Standard (ESRS).
      </Box>
      <Box mb="4mm">{accountTitle} har utført denne bærekraftmålingen.</Box>
      <OrderInfo />
    </Page>
  )
}
