import { CUID } from "@esgt/types"
import { createId, isCuid } from "@paralleldrive/cuid2"

export function isCUID(value: unknown): value is CUID {
  return typeof value === "string" && isCuid(value)
}

export function assertCUID(value: string): asserts value is CUID {
  if (!isCUID(value)) {
    throw new Error(`Value is not a CUID: "${value}"`)
  }
}

export function createCUID() {
  const cuid = createId()
  assertCUID(cuid)

  return cuid
}
