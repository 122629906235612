import { Button, Flex } from "@chakra-ui/react"
import { MinimalContactInfo, UserRatingRole } from "@esgt/types"
import { RatersInfoTooltipIcon } from "components/Tooltips"
import { useAccountEligibleRatersQuery } from "lib/generated/graphql"
import { useCurrentUser } from "lib/providers/CurrentUser"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import { useBoolean } from "usehooks-ts"
import { RaterOption } from "../../RaterOption"
import { NewRatingStep, NewRatingStepFooter, NewRatingSubstep } from "../NewRatingStep"
import { RatingContactInfo } from "../orderInfo/CompanyInfoForm"
import { RatingContributorsForm } from "./RatingContributorsForm"
import { RaterPicker } from "./raters/RaterPicker"
import { RaterTable } from "./raters/RaterTable"

interface RouteContributorsFormProps {
  contactPerson: Partial<RatingContactInfo>
  contributors: Array<MinimalContactInfo>
  setContributors: (contributors: Array<MinimalContactInfo>) => void
  raters: Array<RaterOption>
  setRaters: (raters: Array<RaterOption>) => void
}

export const NewRatingContributorsStep = ({
  contactPerson,
  contributors,
  setContributors,
  raters,
  setRaters,
}: RouteContributorsFormProps) => {
  const { user } = useCurrentUser()
  const [accountEligibleRatersQuery] = useAccountEligibleRatersQuery()
  const accountUsers = accountEligibleRatersQuery.data?.accountEligibleRaters.users

  const { value: isPickerOpen, setValue: setIsPickerOpen, setTrue: openRaterPicker } = useBoolean(false)

  useEffect(() => {
    if (user && raters.length === 0) {
      setRaters([{ user, role: UserRatingRole.Owner }])
    }
  }, [user, raters.length, setRaters])

  return (
    <NewRatingStep>
      <NewRatingSubstep
        as="form"
        title={
          <>
            Kontrollører <RatersInfoTooltipIcon />
          </>
        }
      >
        <Flex direction="column" gap={6} alignItems="flex-start">
          {user && accountUsers && (
            <>
              <RaterTable raters={raters} />

              <Button variant="link" onClick={openRaterPicker}>
                Legg til eller fjern kontrollør
              </Button>

              <RaterPicker
                isOpen={isPickerOpen}
                setIsOpen={setIsPickerOpen}
                users={accountUsers}
                raters={raters}
                setRaters={setRaters}
                ratingOwnerId={user.id}
              />
            </>
          )}
        </Flex>
      </NewRatingSubstep>

      <RatingContributorsForm
        primaryContact={contactPerson}
        contributors={contributors}
        setContributors={setContributors}
      />

      <NewRatingStepFooter>
        <Button variant="outline" as={Link} size="lg" to="../order">
          Tilbake
        </Button>

        <Button as={Link} size="lg" to="../summary" colorScheme="green">
          Neste: Oppsummering
        </Button>
      </NewRatingStepFooter>
    </NewRatingStep>
  )
}
