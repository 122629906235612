import { Button, ButtonProps, useToast } from "@chakra-ui/react"
import { useState } from "react"

interface DownloadButtonProps extends Omit<ButtonProps, "onClick" | "isLoading"> {
  downloadUrl: string
  downloadName: string
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ children, downloadUrl, downloadName, ...props }) => {
  const toast = useToast()
  const [isDownloading, setIsDownloading] = useState(false)

  const onDownload = async () => {
    setIsDownloading(true)
    try {
      const res = await fetch(downloadUrl)
      const blob = await res.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")

      a.href = url
      a.download = downloadName
      a.click()

      window.URL.revokeObjectURL(url)
    } catch (e) {
      console.error(e)
      toast({
        status: "error",
        title: "Feil ved nedlasting",
        description: "Det skjedde en feil ved nedlastingningen. Prøv igjen senere.",
      })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Button {...props} onClick={onDownload} isLoading={isDownloading} loadingText="Laster ned...">
      {children}
    </Button>
  )
}
