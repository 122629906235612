import {
  RequestRatingUploadProcessingMutationVariables,
  useRequestRatingUploadProcessingMutation,
  useSignFileUploadMutation,
} from "lib/generated/graphql"
import { useCallback, useEffect, useRef, useState } from "react"

interface UseUploadProps {
  id: string
  file: File
  processFileProps: Omit<RequestRatingUploadProcessingMutationVariables, "tempfileKey">
}

export enum UploadState {
  NEW = "NEW",
  PREPARING = "PREPARING",
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

export const useUpload = ({ id, file, processFileProps }: UseUploadProps) => {
  const [, signFileUpload] = useSignFileUploadMutation()
  const [, requestProcessing] = useRequestRatingUploadProcessingMutation()

  const request = useRef<XMLHttpRequest>(new XMLHttpRequest())
  const [progress, setProgress] = useState<{ loaded: number; total: number }>({
    loaded: 0,
    total: file.size,
  })
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.PREPARING)

  const handleUploadSuccess = async (tempfileKey: string) => {
    try {
      await requestProcessing({ ...processFileProps, tempfileKey })
    } catch (e) {
      console.error(e)
      setUploadState(UploadState.ERROR)
    }
  }

  const startUpload = useCallback(async () => {
    const { data } = await signFileUpload({})

    const { id, url } = data?.signFileUpload || {}

    if (!url || !id) {
      setUploadState(UploadState.ERROR)
      return
    }

    if (request.current.readyState === XMLHttpRequest.UNSENT) {
      setUploadState(UploadState.UPLOADING)

      request.current.upload.addEventListener("progress", ({ loaded, total }) => {
        setProgress({ loaded, total })
      })

      request.current.onreadystatechange = () => {
        if (request.current.readyState === XMLHttpRequest.DONE) {
          setUploadState(UploadState.PROCESSING)

          if (request.current.status === 200) {
            handleUploadSuccess(id)
          }
        }
      }

      request.current.open("PUT", url)
      request.current.send(file)
    }
  }, [request.current])

  useEffect(() => {
    request.current = new XMLHttpRequest()
  }, [id])

  return [{ uploadState, progress, request: request?.current }, startUpload] as const
}
