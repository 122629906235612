import {
  Button,
  Checkbox,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { IconArrowRight } from "components/Icons"
import { useRequestDocumentationAmendmentsMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { Link, useLocation, useNavigate } from "react-router-dom"

export const RequestAmendments: React.FC = () => {
  const { ratingId, ratingState } = useRating()
  const navigate = useNavigate()
  const _location = useLocation()

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      message: `\
Vi har oppdaget behov for ytterligere informasjon etter gjennomgang av samtlig underlag:
`,
      finances: ratingState.reopenedScopes?.includes("finances"),
      altinn: ratingState.reopenedScopes?.includes("altinn"),
      questions: ratingState.reopenedScopes?.includes("questions"),
      additionalQuestions: ratingState.reopenedScopes?.includes("additionalQuestions"),
    },
  })
  const {
    formState: { isValid },
    watch,
  } = form

  const [mutationStatus, mutate] = useRequestDocumentationAmendmentsMutation()

  const onSubmit = (values: any) => {
    const { message } = values
    mutate({
      message,
      ratingId,
      scopes: ["finances", "altinn", "questions", "additionalQuestions"].filter((scope) => values[scope]),
    }).then(close)
  }

  const close = () => navigate("../..")

  const allowSubmit = useMemo(() => {
    const values = form.getValues()
    return isValid && (values.finances || values.altinn || values.questions || values.additionalQuestions)
  }, [watch(), isValid])

  return (
    <ModalContent as="form" onSubmit={form.handleSubmit(onSubmit)}>
      <ModalHeader>
        Be om korrigert underlag{" "}
        <Button display="block" variant="link" as={Link} to=".." fontSize={16} color="blue.500">
          <IconArrowRight transform="rotate(180deg)" mr={0.5} /> Tilbake
        </Button>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text mb={3} fontWeight="bold">
          Kjære representant for {ratingState?.brreg?.navn}
        </Text>
        <Textarea {...form.register("message", { required: true })} rows={10} placeholder="Instrukser" />
        <Text mt={6} fontWeight={500}>
          Åpne opp for:
        </Text>
        <HStack my={2} gap="10px">
          <Checkbox {...form.register("finances")} isDisabled={ratingState.reopenedScopes?.includes("finances")}>
            Regnskap
          </Checkbox>
          <Checkbox {...form.register("altinn")} isDisabled={ratingState.reopenedScopes?.includes("altinn")}>
            Altinn
          </Checkbox>
          <Checkbox {...form.register("questions")} isDisabled={ratingState.reopenedScopes?.includes("questions")}>
            Spørsmål
          </Checkbox>
          <Checkbox {...form.register("additionalQuestions")} isDisabled>
            Tilleggsspørsmål
          </Checkbox>
        </HStack>
      </ModalBody>
      <ModalFooter gap={2}>
        {!mutationStatus.fetching && (
          <Button variant="outline" onClick={close} isLoading={mutationStatus.fetching}>
            Avbryt
          </Button>
        )}
        <Button colorScheme="green" type="submit" isDisabled={!allowSubmit} isLoading={mutationStatus.fetching}>
          Send e-post og åpne opp
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
