import { Flex, FlexProps, Heading, Text } from "@chakra-ui/react"
import { ContentBox } from "components/ContentBox"
import { InfoTag } from "components/InfoTag"
import { ReactNode } from "react"
import { useWindowSize } from "usehooks-ts"

export const NewRatingStep = (props: FlexProps) => {
  return (
    <Flex direction="column" gap={9} width="100%" {...props}>
      {props.children}
    </Flex>
  )
}

interface NewRatingSubstepProps extends Omit<FlexProps, "title"> {
  title?: string | ReactNode
  info?: string
}

export const NewRatingSubstep = ({ title, children, info, ...flexProps }: NewRatingSubstepProps) => {
  return (
    <ContentBox gap={6} {...flexProps}>
      {(title || info) && (
        <Flex justifyContent="space-between">
          {title ? (
            <Heading as="h2" size="md">
              {title}
            </Heading>
          ) : (
            // Show info on the right side still
            <div />
          )}

          {info && <InfoTag>{info}</InfoTag>}
        </Flex>
      )}

      {children}
    </ContentBox>
  )
}

interface NewRatingStepFooterProps {
  comment?: string
  children: ReactNode
}

export const NewRatingStepFooter = ({ comment, children }: NewRatingStepFooterProps) => {
  const { width } = useWindowSize()
  const isSquished = width < 650

  return (
    <Flex justifyContent="space-between" gap={4} basis="100%" wrap={isSquished ? "wrap" : undefined}>
      <Text fontSize="sm" maxWidth={400}>
        {comment}
      </Text>

      <Flex gap={4} justifyContent={isSquished ? "flex-end" : undefined} width={isSquished ? "100%" : undefined}>
        {children}
      </Flex>
    </Flex>
  )
}
