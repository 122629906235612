import { Question } from "@esgt/types"
import { MethodResult } from "lib/hooks"
import { useMemo } from "react"

export function useQuestionsByDatapoint(method: MethodResult): Record<string, Question> {
  return useMemo(() => {
    const questionsByDatapoint: Record<string, Question> = {}

    for (const question of method.config.questions) {
      // Datapoint set by question
      if (question.datapoint) {
        questionsByDatapoint[question.datapoint.name] = question
      }

      // Datapoint set by input in question
      if (question.textInputs) {
        for (const input of question.textInputs) {
          if (input.linkedDatapoint) {
            // set some of the flags from textInput at question level
            questionsByDatapoint[input.linkedDatapoint] = question
          }
        }
      }
    }

    return questionsByDatapoint
  }, [method])
}
