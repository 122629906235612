import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { BusinessArea, BusinessAreaNames } from "@esgt/types"
import { NewItem } from "components/NewItem"
import { CompanyPicker } from "components/NewRating/CompanyPicker"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"
import { EMAIL_PATTERN } from "components/common/Contact/emailValidation"
import { useCreateNewAccountMutation } from "lib/generated/graphql"
import { useEffect, useRef, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { AccountAdmins } from "./AccountAdmins"
import { NewAccount } from "./types"

const Form = styled.form`
	overflow: visible;
	display: flex;
	flex-flow: column nowrap;
	gap: 16px;
`

export function New() {
  useBreadcrumb("Opprett konto", "/admin/accounts/new")

  const formMethods = useForm<NewAccount>({
    defaultValues: {
      title: "",
      orgNumber: "",
      businessAreas: [],
      owner: {
        email: "",
        name: "",
        phone: "",
      },
      admins: [],
    },
    mode: "onBlur",
  })
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = formMethods

  const { isOpen: cancelAlertIsOpen, onOpen: cancelOnOpen, onClose: cancelOnClose } = useDisclosure()
  const { isOpen: confirmAlertIsOpen, onOpen: confirmOnOpen, onClose: confirmOnClose } = useDisclosure()
  const toast = useToast()

  const submitButtonRef = useRef<any>(null)
  const cancelRef = useRef<any>(null)
  const confirmRef = useRef<any>(null)
  const [createNewAccountResult, createNewAccount] = useCreateNewAccountMutation()
  const navigate = useNavigate()

  const [step, setStep] = useState<number>(1)

  const onSubmit = () => {
    const data = getValues()
    createNewAccount({ input: data }).finally(() => {
      confirmOnClose()
    })
  }

  const handleCompanySelected = (company: any) => {
    setValue("title", company.navn)
    setValue("orgNumber", company.organisasjonsnummer)
    setStep(2)
  }

  const confirmAbort = () => {
    navigate("..")
  }

  useEffect(() => {
    if (!createNewAccountResult.fetching) {
      if (createNewAccountResult.error) {
        toast({
          title: "Feil ved opprettelse av konto",
          description: createNewAccountResult.error.toString(),
          status: "error",
          position: "top",
        })
      } else if (createNewAccountResult?.data?.createAccount?.id) {
        toast({
          title: "Konto opprettet",
          description: "Kontoeiere har mottatt e-post om at kontoen er opprettet.",
          status: "success",
          position: "top",
          isClosable: true,
          onCloseComplete() {
            navigate("..")
          },
        })
      }
    }
  }, [createNewAccountResult, toast, navigate])

  return (
    <NewItem headingText="Opprett konto" maxWidth="900px">
      <BreadCrumbs onSetStep={(theStep) => setStep(theStep)} step={step} />
      <FormProvider {...formMethods}>
        <Form style={{ marginTop: "24px" }}>
          {step === 1 && (
            <>
              <CompanyPicker onSelect={handleCompanySelected} />
              <FormLabel>Søk etter selskap som skal eie kontoen</FormLabel>
            </>
          )}
          {step === 2 && (
            <>
              <div>
                <Heading as="h2" size="sm">
                  {watch("title")} | {watch("orgNumber")}
                </Heading>
                <Heading as="h2" size="md" mt={6} mb={0}>
                  Brukere
                </Heading>
              </div>
              <div>
                <strong>Kontoeier</strong>
                <Flex gap={2}>
                  <FormControl isInvalid={!!errors.owner?.name}>
                    <FormLabel htmlFor="owner.name">Navn</FormLabel>
                    <Input
                      id="owner.name"
                      placeholder="Navn"
                      autoFocus={true}
                      {...register("owner.name", { required: "Fyll ut fullt navn" })}
                    />
                    {errors.owner?.name && <FormErrorMessage>{errors.owner.name.message}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={!!errors.owner?.email}>
                    <FormLabel htmlFor="owner.email">E-post</FormLabel>
                    <Input
                      id="owner.email"
                      placeholder="E-post"
                      type={"email"}
                      {...register("owner.email", {
                        required: true,
                        pattern: { value: EMAIL_PATTERN, message: "Fyll ut gyldig e-post" },
                      })}
                    />
                    {errors.owner?.email && <FormErrorMessage>{errors.owner.email.message}</FormErrorMessage>}
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="owner.phone">Telefonnummer</FormLabel>
                    <Input id="owner.phone" placeholder="Telefonnummer" {...register("owner.phone")} />
                  </FormControl>
                </Flex>
              </div>
              <AccountAdmins />
            </>
          )}

          {step === 3 && (
            <>
              <div>
                <span style={{ margin: 0 }}>
                  {getValues().title} | {getValues().orgNumber}
                </span>
                <Heading as="h2" size="md" mt={6} mb={0}>
                  Forretningsområder
                </Heading>
              </div>
              <FormControl>
                <FormLabel htmlFor="name">Navn</FormLabel>
                <CheckboxGroup colorScheme="green">
                  <Stack spacing={[1, 5]} direction={["column", "row"]}>
                    <Checkbox {...register("businessAreas")} value={BusinessArea.SMBS}>
                      {BusinessAreaNames[BusinessArea.SMBS]}
                    </Checkbox>
                    <Checkbox {...register("businessAreas")} value={BusinessArea.BUILDINGS}>
                      {BusinessAreaNames[BusinessArea.BUILDINGS]}
                    </Checkbox>
                    <Checkbox {...register("businessAreas")} value={BusinessArea.CITIES}>
                      {BusinessAreaNames[BusinessArea.CITIES]}
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </FormControl>
            </>
          )}

          {step === 4 && (
            <>
              <div>
                <Heading as="h2" size="md" mt={6} mb={0}>
                  Oppsummering
                </Heading>
              </div>
              <Box bg={"gray.50"} borderRadius={"base"} borderWidth="1px" p={"5"}>
                <Heading as="h4" size="md" mt={0} mb={2}>
                  {getValues().title} | {getValues().orgNumber}
                </Heading>
                <Box>Forretningsområder: {getValues().businessAreas.join(", ")}</Box>
                <Box mt={2}>
                  <strong>Kontoeier:</strong>
                  <br /> {getValues().owner.name} | {getValues().owner.email}
                </Box>
                <Box mt={2}>
                  <strong>Konto medeiere:</strong>
                  <br />{" "}
                  {getValues().admins.length === 0 ? " — " : getValues().admins.map((r) => `${r.name} | ${r.email}`)}
                </Box>
              </Box>
            </>
          )}

          <Flex mt={2} justify="end">
            <Button
              variant="outline"
              size="lg"
              isLoading={createNewAccountResult.fetching}
              type="button"
              onClick={cancelOnOpen}
            >
              Avbryt
            </Button>
            {step === 4 && (
              <Button
                ml={3}
                ref={submitButtonRef}
                size="lg"
                colorScheme={"green"}
                type="button"
                onClick={confirmOnOpen}
              >
                Fullfør
              </Button>
            )}
            {step !== 1 && step < 4 && (
              <Button ml={3} onClick={() => setStep(step + 1)} size="lg" colorScheme={"green"} type="button">
                Neste
              </Button>
            )}
          </Flex>

          {step === 4 && (
            <Flex mt={2} justify="end">
              Når du trykker på &quot;Fullfør&quot; sendes en e-post til alle kontoeiere med link å opprette profil.
            </Flex>
          )}
        </Form>
      </FormProvider>
      <AlertDialog isOpen={cancelAlertIsOpen} leastDestructiveRef={cancelRef} onClose={cancelOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Vil du avbryte registreringen?
            </AlertDialogHeader>

            <AlertDialogBody>Kontoen er ikke lagret, er du sikker på at du vil avbryte?</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="outline" size="lg" onClick={cancelOnClose} mr={2}>
                Fortsett med registreringen
              </Button>
              <Button colorScheme="green" size="lg" ref={cancelRef} onClick={confirmAbort}>
                Ja, avbryt
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog isOpen={confirmAlertIsOpen} leastDestructiveRef={confirmRef} onClose={confirmOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Sensitiv informasjon
            </AlertDialogHeader>

            <AlertDialogBody>En kontoeier får innsyn i sensitiv informasjon til bedriften.</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="outline" mr={2} size="lg" onClick={confirmOnClose}>
                Avbryt
              </Button>
              <Button colorScheme={"green"} size="lg" ref={cancelRef} onClick={() => onSubmit()}>
                Jeg forstår
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </NewItem>
  )
}

const BreadCrumbs = ({ step, onSetStep }: { step: number; onSetStep: (step: number) => void }) => {
  return (
    <Breadcrumb separator="|">
      <BreadcrumbItem isCurrentPage={step === 1}>
        <BreadcrumbLink onClick={() => onSetStep(1)} fontWeight={step === 1 ? "bold" : "normal"} href="#">
          1. Kontoinformasjon
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage={step === 2}>
        <BreadcrumbLink onClick={() => onSetStep(2)} fontWeight={step === 2 ? "bold" : "normal"} href="#">
          2. Brukere
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage={step === 3}>
        <BreadcrumbLink onClick={() => onSetStep(3)} fontWeight={step === 3 ? "bold" : "normal"} href="#">
          3. Forretningsområde
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage={step === 4}>
        <BreadcrumbLink fontWeight={step === 4 ? "bold" : "normal"} href="#">
          4. Oppsumering
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
