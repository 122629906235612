import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Checkbox, Flex, HStack, Link, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react"
import { isMissingAllKpiDeps, isQuestionDone } from "@esgt/helpers"
import { Question, RatingFileCategory } from "@esgt/types"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { MethodI18nFragment, MethodI18nText } from "components/I18nText"
import { RatingUploader } from "components/RatingUploader"
import { useUpdateRatingAnswerMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import React, { useMemo } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { ShortTextInput } from "./ShortTextInput"
import { createRegexFromValidationPattern } from "./createRegexFromValidationPattern"

interface Props {
  isReadOnly: boolean
  question: Question
}

export const CategoryQuestion: React.FC<Props> = ({ question, isReadOnly }) => {
  const { ratingId, ratingState, ratingProfileConfig, isFree } = useRating()
  const [_saveStatus, save] = useUpdateRatingAnswerMutation()

  const form = useForm({
    defaultValues: ratingState?.answerValues[question.id],
    mode: "onBlur",
  })

  const values = form.watch()

  const saveChanges = () => {
    if (isReadOnly) return

    save({
      ratingId,
      scope: question.id,
      values: form.getValues(),
    })
  }

  const questionText = <MethodI18nFragment text={question.text} />

  const primaryAnswer = form.watch("primaryAnswer")

  const isExpanded = useMemo(() => {
    return question.noPrimaryValue || primaryAnswer === "true"
  }, [primaryAnswer, question.noPrimaryValue])

  const questionCompleted = useMemo(() => {
    return isQuestionDone(question, ratingState, new Set())
  }, [question, ratingState])

  if (isMissingAllKpiDeps(question, ratingProfileConfig)) {
    return null
  }

  if (question.dependsOn && ratingState?.answerValues[question.dependsOn]?.primaryAnswer !== "true") {
    return null
  }

  return (
    <FormProvider {...form}>
      <ContentAccordion
        headingText={
          <Flex gap="2">
            <Box>{question.questionNumber}</Box>
            <Box>{questionText}</Box>
          </Flex>
        }
        completed={questionCompleted}
        readOnly={isReadOnly}
      >
        <ContentAccordionLeft>
          <Flex key={question.id} gap="16px" flexFlow="column nowrap" as="form">
            {!question.noPrimaryValue && (
              <Controller
                name="primaryAnswer"
                control={form.control}
                render={({ field: { ref, onChange, value } }) => (
                  <RadioGroup
                    ref={ref}
                    isDisabled={isReadOnly}
                    onChange={(val) => {
                      onChange(val)
                      saveChanges()
                    }}
                    value={value}
                  >
                    <Stack style={{ position: "relative" }} direction="row" gap="16px">
                      <Radio value="true" bg="white">
                        Ja
                      </Radio>
                      <Radio value="false" bg="white">
                        Nei
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            )}

            {isExpanded && (
              <>
                {question.textInputs?.map((textInput, i) => {
                  const inputName = `input_${i}` as const

                  return (
                    <React.Fragment key={inputName}>
                      <Flex flexFlow="column nowrap" mt="2">
                        {textInput.text && (
                          <Text fontSize={"14px"} mb="2" fontWeight={500} as="div">
                            <MethodI18nText text={textInput.text} />
                          </Text>
                        )}

                        <HStack>
                          {textInput.size === "lg" && (
                            <Textarea
                              isDisabled={isReadOnly}
                              bgColor="white"
                              {...form.register(inputName, {
                                pattern: createRegexFromValidationPattern(textInput.validationPattern),
                              })}
                              onBlur={saveChanges}
                            />
                          )}
                          {["sm", "md"].includes(textInput.size) && (
                            <ShortTextInput
                              questionInput={textInput}
                              disabled={isReadOnly}
                              inputName={inputName}
                              saveChanges={saveChanges}
                            />
                          )}
                          {textInput.optional && (
                            <Checkbox
                              isChecked={values[inputName] === null}
                              onChange={() => {
                                form.setValue(inputName, values[inputName] === null ? "" : null)
                                saveChanges()
                              }}
                            >
                              Mangel på tall
                            </Checkbox>
                          )}
                        </HStack>

                        {textInput.helpText && (
                          <Text fontSize={"14px"} my="2" as="div">
                            <MethodI18nText text={textInput.helpText} />
                          </Text>
                        )}
                      </Flex>
                    </React.Fragment>
                  )
                })}
                {!isFree &&
                  question.uploads?.map((upload, i) => (
                    <React.Fragment key={`upload_${i}`}>
                      <Flex flexFlow="column nowrap" mt="2">
                        {upload.text && (
                          <Text fontSize={"14px"} fontWeight={500} as="div">
                            <MethodI18nText text={upload.text} />
                          </Text>
                        )}
                        {upload.helpText && (
                          <Text color="gray.600" fontSize={"14px"} as="div">
                            <MethodI18nText text={upload.helpText} />
                          </Text>
                        )}
                        {upload.link && (
                          <Link isExternal href={upload.link.url} color="gray.600" fontSize={"14px"}>
                            <MethodI18nText text={upload.link.text} /> <ExternalLinkIcon mb="4px" mx="2px" />
                          </Link>
                        )}

                        <Box mt="4">
                          <RatingUploader
                            ratingFileType={RatingFileCategory.generic}
                            uploadScope={`questions/${question.id}`}
                            readOnly={isReadOnly}
                          />
                        </Box>
                      </Flex>
                    </React.Fragment>
                  ))}
              </>
            )}
          </Flex>
        </ContentAccordionLeft>
        <ContentAccordionRight>
          {question.helpText && (
            <Text fontSize="14px" as="div">
              <MethodI18nText text={question.helpText} />
            </Text>
          )}
        </ContentAccordionRight>
      </ContentAccordion>
    </FormProvider>
  )
}
