import { QuestionInput } from "@esgt/types"
import { processEnergyRatingList } from "./energyRatings"

export function transformDatapointInput(input: string, definition: QuestionInput): number | undefined {
  // ensure we return undefined if input was cleared or not yet set
  if (input === undefined || input === null) {
    return undefined
  }
  if (!definition.linkedDatapoint) {
    return undefined
  }

  if (definition.transformation) {
    switch (definition.transformation) {
      case "energimerke":
        return processEnergyRatingList(input)
    }
  }

  return Number.parseFloat(input)
}
