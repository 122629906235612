import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react"
import { CenteredContent } from "components/CenteredContent"
import { ContentBox } from "components/ContentBox"
import { ErrorMessage } from "components/ErrorMessage"
import { Logo } from "components/Logo"
import { EmailInput } from "components/common/Contact/EmailInput"
import { PhoneInput } from "components/common/Contact/PhoneInput"
import { RequiredInputAsterisk } from "components/common/Contact/RequiredInputAsterisk"
import * as auth from "lib/auth"
import { routes } from "lib/routes"
import { useState } from "react"
import { FieldValues, useForm } from "react-hook-form"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import styled from "styled-components"

const Form = styled.form`
	overflow: visible;
	display: flex;
	flex-flow: column nowrap;
	gap: 16px;
`

export const Signup: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      companyName: "",
      email: "",
      phone: "",
      termsAccepted: false,
    } as FieldValues,
  })
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [signupError, setSignupError] = useState<string>()

  const signup = async ({ name, companyName, email, phone, termsAccepted }: FieldValues) => {
    setLoading(true)

    try {
      await auth.signup({ name, companyName, email, phone, termsAccepted })
      navigate(routes.signupWelcome(), { state: { name, email } })
    } catch (e) {
      console.error(e)
      setSignupError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CenteredContent maxWidth="712px" alignCenter>
      <Center>
        <Logo />
      </Center>

      <ContentBox padding="64px 72px 42px">
        <Heading size="h2" mb={6}>
          Opprett din gratis konto
        </Heading>
        <Text mb="16px" fontSize="lg">
          Fyll ut skjemaet for å opprette din gratis konto
          <br />
          og få tilgang til din gratis rapport.
        </Text>
        <>
          {signupError && (
            <Box mb={4}>
              <ErrorMessage error={signupError} />
            </Box>
          )}

          <Form onSubmit={handleSubmit(signup)}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">
                Navn <RequiredInputAsterisk />
              </FormLabel>
              <Input id="name" placeholder="Navn" {...register("name", { required: true })} />
              <FormErrorMessage>Skriv inn navn</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="companyName">Bedriftsnavn</FormLabel>
              <Input id="companyName" placeholder="Bedriftsnavn" {...register("companyName")} />
            </FormControl>

            <EmailInput
              inputKey="email"
              label="E-postadresse"
              setError={setError}
              clearErrors={clearErrors}
              register={register}
              errors={errors}
            />

            <PhoneInput
              inputKey="phone"
              setError={setError}
              clearErrors={clearErrors}
              register={register}
              errors={errors}
              isRequired={true}
            />

            <FormControl isInvalid={!!errors.termsAccepted}>
              <Flex gap={2} alignItems="center">
                <Checkbox {...register("termsAccepted", { required: true })}>
                  Jeg har lest og er enig i{" "}
                  <Link target="_blank" href="https://www.esgtrackr.no/personvernerklaering" variant="underlined">
                    Personvernerklæring
                  </Link>
                </Checkbox>
              </Flex>
              <FormErrorMessage>Du må akseptere vår Personvernerklæring</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              colorScheme="green"
              size="lg"
              color="white"
              variant="solid"
              mb={2}
              mt={4}
              isLoading={loading}
            >
              Fullfør
            </Button>
          </Form>
        </>
      </ContentBox>
      <Center mt="16px">
        <Text fontSize="md">
          Har du allerede konto?{" "}
          <Link as={RouterLink} to={routes.login()} variant="underlined">
            Logg inn
          </Link>
        </Text>
      </Center>
    </CenteredContent>
  )
}
