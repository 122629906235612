import { Flex } from "@chakra-ui/react"
import { MethodConfig } from "@esgt/types"
import { useBreadcrumb } from "components/breadcrumbs/BreadcrumbProvider"

type MethodQuestionsProps = {
  config: MethodConfig
}

export const MethodQuestions = ({ config }: MethodQuestionsProps) => {
  useBreadcrumb("Questions", "/admin/method/questions")

  return (
    <Flex direction="column">
      <div>
        <pre>{JSON.stringify(config.questions, null, 2)}</pre>
      </div>
    </Flex>
  )
}
