import { Icon, IconProps } from "@chakra-ui/react"

export const IconArrowRight: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <title>Arrow right</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6929 12.6817C18.8895 12.4942 19 12.2401 19 11.9751C19 11.7101 18.8895 11.4559 18.6929 11.2684L13.4426 6.26839C13.2435 6.09175 12.9802 5.99558 12.7082 6.00016C12.4361 6.00473 12.1766 6.10968 11.9842 6.29289C11.7918 6.47611 11.6816 6.72329 11.6768 6.98236C11.672 7.24143 11.773 7.49216 11.9585 7.68172L15.4167 10.9751L6.05007 10.9751C5.77157 10.9751 5.50449 11.0804 5.30756 11.2679C5.11063 11.4555 5 11.7098 5 11.9751C5 12.2403 5.11063 12.4946 5.30756 12.6822C5.50449 12.8697 5.77157 12.9751 6.05007 12.9751L15.4167 12.9751L11.9585 16.2684C11.8553 16.3599 11.7725 16.4703 11.7151 16.593C11.6578 16.7157 11.6269 16.8481 11.6244 16.9824C11.6219 17.1166 11.6479 17.25 11.7007 17.3745C11.7535 17.499 11.8321 17.6121 11.9318 17.7071C12.0315 17.8021 12.1503 17.8769 12.281 17.9272C12.4118 17.9775 12.5518 18.0022 12.6928 17.9998C12.8338 17.9975 12.9729 17.9681 13.1017 17.9134C13.2305 17.8588 13.3464 17.78 13.4426 17.6817L18.6929 12.6817Z"
        fill="currentColor"
      />
    </Icon>
  )
}
