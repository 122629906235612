import { Box, Button, Heading, Text } from "@chakra-ui/react"
import { BRREGUnit } from "@esgt/types"
import { CompanyPicker } from "components/NewRating/CompanyPicker"
import { Dispatch, SetStateAction, useRef } from "react"
import { NewRatingSubstep } from "../NewRatingStep"

interface CompanyInfoProps {
  selectedOrganization: BRREGUnit
  setSelectedOrganization: Dispatch<SetStateAction<BRREGUnit>>
}

export type RatingContactInfo = {
  email: string
  name: string
  phone?: string
}

export const CompanyInfo = ({ selectedOrganization, setSelectedOrganization }: CompanyInfoProps) => {
  const nameInputRef = useRef<HTMLInputElement>(null)

  const handleCompanySelected = (company: BRREGUnit) => {
    setSelectedOrganization(company)
    nameInputRef.current?.focus()
  }

  return (
    <NewRatingSubstep title="Virksomhet">
      {selectedOrganization ? (
        <Box
          w="70%"
          p={5}
          background="gray.50"
          shadow="sm"
          borderRadius="base"
          borderWidth="1px"
          flexFlow="row nowrap"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <Heading fontSize="xl">{selectedOrganization.navn}</Heading>
            <Text mt={1} fontSize="xs">
              Org. nr: {selectedOrganization.organisasjonsnummer}
            </Text>

            <Text mt={1} fontSize="xs">
              {selectedOrganization?.naeringskode1.kode} - {selectedOrganization.naeringskode1.beskrivelse}
            </Text>

            {selectedOrganization.forretningsadresse && (
              <Text mt={3}>
                {selectedOrganization.forretningsadresse?.adresse.join("\n")}
                <br />
                {selectedOrganization.forretningsadresse?.postnummer}{" "}
                {selectedOrganization.forretningsadresse?.poststed}
              </Text>
            )}
          </div>
          <Button variant="outline" alignSelf="end" onClick={() => setSelectedOrganization(null)}>
            Endre
          </Button>
        </Box>
      ) : (
        <Box w="50%">
          <CompanyPicker onSelect={handleCompanySelected} />
        </Box>
      )}
    </NewRatingSubstep>
  )
}
