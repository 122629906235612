import { BigNumber } from "mathjs"
import { A07Datapoints } from "./A07"
import { RatingFileCategory } from "./Upload"
import { UserRatingRole } from "./enums"

export type RatingPhase =
  | "SUBMISSION"
  | "PROCESSING_DOCUMENTATION"
  | "RATING_IN_PROGRESS"
  | "ABORTED"
  | "COMPLETED"
  | "PROCESSING_DATAFILES"

export type NewRater = {
  userId: number
  role: UserRatingRole
}

export type ContactInfo = {
  email: string
  name: string
  phone?: string | null
}

export type MinimalContactInfo = {
  email: string
  name: string
}

export interface RatingUpload {
  uploadId: string
  timestamp: string
  scope: string
  contentType?: string
  filename: string
  ratingFileType: RatingFileCategory
  processed: boolean
  valid: boolean
  processFailureMessages?: string[]
  processWarningMessages?: string[]
  parsedData?: Record<string, any>
}

export type RatingFileType = "saft" | "generic"
export type SaftDatapoints = Record<string, BigNumber>
export type RatingDatapoints = SaftDatapoints | A07Datapoints

export interface OmposteringsRule {
  from?: string
  to: string
  affectedDatapoints: string[]
}

export interface DatafileAnomaly {
  id: `anomaly${string}`
  concerning: string
  source: string
  message: string
  amount?: BigNumber
  omposteringsRules?: OmposteringsRule[]
}

export type RatingAnomaly = DatafileAnomaly & { ok?: boolean; comment?: string }

export interface PeriodMonth {
  year: number
  // january = 1
  month: number
}

export interface Period {
  start: PeriodMonth
  end: PeriodMonth
}

export enum RatingType {
  Full = "FULL",
  Free = "FREE",
}

export enum ProcessingStatus {
  Processing = "PROCESSING",
  Completed = "COMPLETED",
  Failed = "FAILED",
}

export interface FileProcessingStatus {
  status: ProcessingStatus
  errorMsg?: string
}

export interface DataFilesProcessingStatus {
  a07?: FileProcessingStatus
  saft?: FileProcessingStatus
}
