import { IconType } from "react-icons"

export const IconSearchLg: IconType = (props) => {
  return (
    <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Search Large</title>
      <path
        opacity="0.4"
        d="M79.8265 163.275C79.8265 163.275 107.987 167.795 130.071 148.009C152.155 128.223 158.231 96.0467 163.835 70.136C169.439 44.2253 157.527 21.1799 135.52 13.7949C113.513 6.40993 103.991 25.1962 76.8415 31.1309C49.6918 37.0656 41.4303 34.4714 22.3484 54.4614C3.26651 74.4515 7.66651 112.344 31.8524 136.449C56.0383 160.554 79.8265 163.275 79.8265 163.275Z"
        fill="#4BA792"
      />
      <path
        opacity="0.7"
        d="M79.8265 163.275C79.8265 163.275 107.987 167.795 130.071 148.009C152.155 128.223 158.231 96.0467 163.835 70.136C169.439 44.2253 157.527 21.1799 135.52 13.7949C113.513 6.40993 103.991 25.1962 76.8415 31.1309C49.6918 37.0656 41.4303 34.4714 22.3484 54.4614C3.26651 74.4515 7.66651 112.344 31.8524 136.449C56.0383 160.554 79.8265 163.275 79.8265 163.275Z"
        fill="white"
      />
      <path
        d="M159.343 114.446C159.343 114.446 161.103 115.995 160.787 116.611C160.47 117.227 154.099 123.724 154.099 123.724L152.965 122.077L156.573 115.579L159.343 114.446Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M150.931 106.434C150.931 106.434 158.724 113.312 158.724 113.724C158.724 114.136 155.837 116.818 155.837 116.818L150.167 115.167L150.477 108.775"
        fill="white"
      />
      <path
        d="M150.931 106.434C150.931 106.434 158.724 113.312 158.724 113.724C158.724 114.136 155.837 116.818 155.837 116.818L150.167 115.167L150.477 108.775"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M157.383 111.767L155.014 117.022L155.323 117.744L159.963 113.826C159.963 113.826 158.931 111.661 157.383 111.767Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M130.314 116.512C129.556 116.603 128.791 116.418 128.16 115.991L114.196 106.522C113.851 106.289 113.556 105.99 113.327 105.642C113.099 105.295 112.94 104.906 112.862 104.497C112.784 104.089 112.786 103.669 112.87 103.261C112.954 102.854 113.117 102.467 113.351 102.122C113.584 101.778 113.883 101.483 114.231 101.254C114.578 101.025 114.967 100.867 115.376 100.789C115.784 100.71 116.205 100.713 116.612 100.797C117.02 100.881 117.406 101.044 117.751 101.277L131.715 110.746C132.249 111.109 132.659 111.627 132.89 112.229C133.122 112.832 133.164 113.49 133.011 114.118C132.858 114.745 132.517 115.31 132.034 115.738C131.55 116.166 130.948 116.436 130.307 116.512H130.314Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.436 117.374C123.436 117.374 124.291 118.916 124.633 119.353C124.974 119.789 126.72 120.173 126.72 120.173C126.72 120.173 128.561 125.002 128.92 125.865C129.279 126.727 130.039 128.223 130.395 128.329C130.75 128.434 133.348 129.613 133.968 128.79C134.587 127.966 134.489 125.066 134.489 125.066L129.209 119.87L128.832 118.163L123.436 117.374Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M159.836 139.61C158.238 139.801 156.624 139.408 155.292 138.505L125.041 117.997C124.299 117.511 123.661 116.882 123.164 116.147C122.668 115.412 122.322 114.586 122.148 113.716C121.974 112.846 121.975 111.95 122.15 111.08C122.326 110.211 122.673 109.385 123.17 108.651C123.668 107.916 124.307 107.288 125.05 106.803C125.793 106.318 126.625 105.986 127.497 105.826C128.369 105.665 129.265 105.68 130.132 105.869C130.999 106.058 131.819 106.418 132.545 106.927L162.796 127.424C163.926 128.189 164.794 129.28 165.285 130.552C165.777 131.824 165.867 133.216 165.545 134.542C165.223 135.867 164.504 137.062 163.483 137.966C162.463 138.871 161.19 139.442 159.836 139.603V139.61Z"
        fill="#4BA792"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M124.341 119.363C124.341 119.363 123.552 117.325 123.45 117.121C123.348 116.917 122.707 116.065 122.809 115.804C122.911 115.544 123.214 115.4 123.686 115.738C124.157 116.076 125.041 117.997 125.041 117.997C125.041 117.997 126.424 117.293 127.033 117.899C127.642 118.504 127.776 120.131 127.776 120.131"
        fill="white"
      />
      <path
        d="M124.341 119.363C124.341 119.363 123.552 117.325 123.45 117.121C123.348 116.917 122.707 116.065 122.809 115.804C122.911 115.544 123.214 115.4 123.686 115.738C124.157 116.076 125.041 117.997 125.041 117.997C125.041 117.997 126.424 117.293 127.033 117.899C127.642 118.504 127.776 120.131 127.776 120.131"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M84.0083 45.9395C76.8524 46.7932 70.1103 49.7499 64.6349 54.4356C59.1594 59.1213 55.1964 65.3255 53.2472 72.2636C51.298 79.2017 51.45 86.562 53.6842 93.4137C55.9183 100.265 60.1341 106.301 65.7984 110.756C71.4628 115.212 78.3212 117.887 85.5063 118.445C92.6914 119.002 99.8804 117.416 106.164 113.888C112.448 110.359 117.544 105.047 120.808 98.6215C124.073 92.1965 125.358 84.9477 124.502 77.792C123.355 68.1988 118.445 59.4541 110.851 53.4808C103.257 47.5076 93.6018 44.7949 84.0083 45.9395ZM92.1431 114.132C85.8117 114.887 79.3986 113.748 73.7147 110.858C68.0309 107.969 63.3315 103.458 60.211 97.8978C57.0904 92.3374 55.6887 85.9765 56.1832 79.6194C56.6777 73.2623 59.0461 67.1947 62.9889 62.1836C66.9318 57.1726 72.2721 53.4432 78.3344 51.4672C84.3967 49.4911 90.9089 49.3571 97.0474 51.082C103.186 52.8069 108.675 56.3134 112.821 61.1579C116.967 66.0025 119.583 71.9675 120.338 78.2989C121.351 86.7895 118.949 95.3346 113.661 102.055C108.374 108.775 100.634 113.119 92.1431 114.132Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.5791 45.3341C75.4226 46.1848 68.679 49.1389 63.2015 53.8226C57.7239 58.5063 53.7585 64.7092 51.8066 71.6468C49.8547 78.5845 50.0041 85.9451 52.2359 92.7978C54.4676 99.6505 58.6816 105.687 64.3446 110.145C70.0077 114.603 76.8655 117.281 84.0507 117.84C91.236 118.4 98.4258 116.816 104.711 113.289C110.996 109.763 116.094 104.451 119.36 98.0265C122.626 91.602 123.913 84.3533 123.059 77.1971C121.914 67.6048 117.007 58.8597 109.416 52.8846C101.825 46.9095 92.172 44.1937 82.5791 45.3341ZM90.7138 113.527C84.3824 114.282 77.9693 113.143 72.2855 110.253C66.6016 107.363 61.9023 102.853 58.7817 97.2924C55.6612 91.7319 54.2595 85.371 54.754 79.014C55.2485 72.6569 57.6169 66.5892 61.5597 61.5782C65.5026 56.5671 70.8428 52.8378 76.9052 50.8617C82.9675 48.8857 89.4797 48.7516 95.6182 50.4766C101.757 52.2015 107.246 55.7079 111.392 60.5525C115.537 65.397 118.153 71.3621 118.909 77.6934C119.921 86.1841 117.52 94.7292 112.232 101.449C106.945 108.169 99.2044 112.514 90.7138 113.527Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.087 96.3248C136.087 96.3248 135.189 98.8416 135.249 99.3203C135.309 99.799 135.548 99.9821 135.249 100.281C134.95 100.58 133.992 101.299 134.112 101.717C134.273 102.068 134.514 102.375 134.816 102.615C134.816 102.615 135.474 105.252 136.132 105.551C136.791 105.85 137.811 105.611 137.811 105.611L138.515 107.529L143.549 106.272L143.25 103.217C143.25 103.217 144.746 100.341 144.387 98.6022C144.028 96.8633 140.912 95.1878 138.874 95.1878C136.836 95.1878 136.266 95.8461 136.087 96.3248Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path d="M136.118 103.34C136.118 103.34 136.903 103.238 137.143 102.759" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M136.189 100.267C136.189 100.475 136.118 100.644 136.034 100.644C135.949 100.644 135.883 100.475 135.883 100.267C135.883 100.06 135.949 99.8905 136.034 99.8905C136.118 99.8905 136.189 100.06 136.189 100.267Z"
        fill="#263238"
      />
      <path
        d="M135.812 99.4153C135.812 99.4153 136.256 98.8697 136.516 99.5491"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M137.28 97.2857C137.28 97.2857 135.302 97.4054 135.168 95.5469C135.034 93.6883 137.745 92.671 138.762 92.671C139.779 92.671 139.061 93.6918 139.061 93.6918C139.061 93.6918 141.578 92.9702 142.229 93.632C142.88 94.2937 142.349 94.8288 142.349 94.8288C142.349 94.8288 145.643 96.2051 145.643 98.6022C145.643 100.999 143.908 103.337 143.609 103.815C143.31 104.294 142.588 104.713 141.272 104.773C139.955 104.833 139.16 104.174 138.874 103.277L138.575 102.376C138.575 102.376 139.593 101.299 139.114 100.401C138.635 99.5033 138.336 100.049 138.216 100.401C138.097 100.753 138.336 101.105 138.097 101.105C137.857 101.105 138.037 100.806 137.393 100.148C136.748 99.4893 136.192 99.1865 136.432 98.5283C136.635 98.0651 136.922 97.6437 137.28 97.2857Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M137.107 107.237L136.393 108.817L145.489 106.748L144.436 105.435L137.107 107.237Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M140.761 161.234C140.761 161.234 138.051 161.881 136.474 161.881C134.897 161.881 134.207 161.8 134.207 162.325C134.207 162.849 136.717 163.821 138.171 164.388C139.624 164.954 142.458 165.764 142.701 165.683C142.944 165.602 143.429 162.649 143.429 162.649L140.761 161.234Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M134.369 162.019C134.316 162.05 134.273 162.095 134.245 162.149C134.216 162.203 134.203 162.264 134.207 162.325C134.207 162.853 136.717 163.821 138.171 164.388C139.624 164.954 142.458 165.764 142.701 165.683C142.757 165.665 142.824 165.493 142.898 165.246C140.064 164.68 135.538 162.575 134.369 162.019Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M135.907 126.657C135.89 128.614 135.971 130.571 136.15 132.521C136.432 135.228 137.727 147.118 137.847 148.98C137.966 150.843 140.761 161.234 140.761 161.234L143.429 162.642C143.429 162.642 144.763 151.075 144.763 149.135V145.9C144.763 145.9 147.108 136.717 147.836 134.534C148.565 132.352 148.928 128.022 148.85 125.597C146.713 126.429 144.453 126.905 142.162 127.005C140.071 127.125 137.972 127.008 135.907 126.657Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M150.688 109.891L149.6 118.624C149.6 118.624 149.174 125.235 149.054 125.844C148.935 126.452 148.385 126.752 145.886 127.604C143.387 128.455 136.664 127.846 136.118 127.297C135.573 126.748 135.453 121.415 135.453 121.415C135.453 121.415 134.724 124.509 134.179 124.935C133.633 125.361 131.57 125.178 130.599 124.27C129.627 123.362 130.782 112.925 130.719 111.95C130.655 110.975 136.664 108.613 136.664 108.613C136.664 108.613 145.01 106.698 145.464 106.744C145.918 106.79 150.543 105.825 150.906 106.431C151.268 107.036 151.515 107.402 151.515 107.402"
        fill="white"
      />
      <path
        d="M150.688 109.891L149.6 118.624C149.6 118.624 149.174 125.235 149.054 125.844C148.935 126.452 148.385 126.752 145.886 127.604C143.387 128.455 136.664 127.846 136.118 127.297C135.573 126.748 135.453 121.415 135.453 121.415C135.453 121.415 134.724 124.509 134.179 124.935C133.633 125.361 131.57 125.178 130.599 124.27C129.627 123.362 130.782 112.925 130.719 111.95C130.655 110.975 136.664 108.613 136.664 108.613C136.664 108.613 145.01 106.698 145.464 106.744C145.918 106.79 150.543 105.825 150.906 106.431C151.268 107.036 151.515 107.402 151.515 107.402"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path d="M135.464 121.419L133.823 113.471" stroke="#263238" strokeMiterlimit="10" />
      <path d="M144.323 110.503L140.553 121.813" stroke="#263238" strokeMiterlimit="10" />
      <path d="M143.296 117.871L140.553 124.728" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M130.173 121.729C130.081 121.858 130.033 122.013 130.036 122.172C130.036 122.443 129.494 124.636 129.73 125.062C129.965 125.488 130.955 126.086 132.546 126.153C134.137 126.22 134.756 125.984 134.893 125.572C135.121 124.702 135.28 123.814 135.369 122.918C135.337 122.683 135.133 122.816 135.133 122.816C134.091 123.03 133.012 122.972 132 122.647C130.412 122.102 130.173 121.729 130.173 121.729Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M136.108 128.959C136.108 128.959 136.189 135.756 136.351 139.758C136.512 143.76 137.16 147.242 137.407 148.048C137.653 148.854 143.151 155.894 144.404 157.393C145.657 158.893 148.083 161.153 148.083 161.153L150.821 159.657C150.821 159.657 147.273 154.602 146.143 152.265C145.013 149.927 143.028 146.038 143.028 146.038L144.278 134.816L146.34 133.686"
        fill="#263238"
      />
      <path
        d="M136.108 128.959C136.108 128.959 136.189 135.756 136.351 139.758C136.512 143.76 137.16 147.242 137.407 148.048C137.653 148.854 143.151 155.894 144.404 157.393C145.657 158.893 148.083 161.153 148.083 161.153L150.821 159.657C150.821 159.657 147.273 154.602 146.143 152.265C145.013 149.927 143.028 146.038 143.028 146.038L144.278 134.816L146.34 133.686"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M145.897 134.242L144.211 135.369L143.025 146.027L144.764 149.142"
        stroke="#696969"
        strokeMiterlimit="10"
      />
      <path
        d="M148.125 161.047C148.125 161.047 146.823 162.979 146.33 163.427C145.837 163.874 143.905 165.046 143.634 165.313C143.362 165.581 144.848 165.81 145.925 165.81C147.002 165.81 148.35 165.584 149.023 165.179C149.695 164.775 152.212 162.979 152.525 162.666C152.838 162.353 150.818 159.657 150.818 159.657L148.125 161.047Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M152.525 162.666C152.634 162.557 152.469 162.173 152.205 161.716C151.223 162.747 149.037 164.951 148.125 165.236C147.111 165.535 144.531 165.334 143.711 165.26L143.634 165.324C143.366 165.595 144.848 165.82 145.925 165.82C147.002 165.82 148.35 165.595 149.023 165.19C149.695 164.785 152.212 162.976 152.525 162.666Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
