import { CheckIcon } from "@chakra-ui/icons"
import { Link } from "@chakra-ui/react"
import { RatingState } from "@esgt/event-store"
import { CUID, Kpi, KpiCategory, RatingProfileConfigItem } from "@esgt/types"
import { ContentBox } from "components/ContentBox"
import { MethodI18nFragment } from "components/I18nText"
import { useRating } from "lib/providers/RatingProvider"
import React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
	width: 400px;
	overflow: auto;
`

const NavItem = styled(Link).attrs({ as: NavLink })<{ level: number }>`
	padding: 10px 16px 10px;
	display: block;
	border-radius: var(--chakra-radii-base);
	font-size: 16px;
	font-weight: var(--chakra-fontWeights-medium);
	font-family: var(--chakra-fonts-medium);

	&.active {
		background-color: var(--chakra-colors-blackAlpha-600);
		color: white;
	}
`

const NavHeader = styled.div`
	font-weight: var(--chakra-fontWeights-heading);
	font-family: var(--chakra-fonts-heading);
	text-transform: uppercase;
	font-size: 14px;
	color: var(--chakra-colors-blackAlpha-500);
	margin-top: 16px;
	padding: 16px;
`

export const Navigation: React.FC = () => {
  const { ratingState, ratingProfileConfig, method } = useRating()

  if (!method) {
    return null
  }

  return (
    <Container>
      <ContentBox px="16px" py="8px">
        {method.config.kpiDimensions.map((dimension) => (
          <React.Fragment key={dimension.id}>
            {ratingProfileConfig?.dimensions?.[dimension.slug]?.enabled !== false ? (
              <>
                <NavHeader>{<MethodI18nFragment text={dimension.name} />}</NavHeader>
                {dimension.categories.map((category) =>
                  ratingProfileConfig?.categories?.[category.id]?.enabled !== false ? (
                    <NavItem key={category.id} level={1} to={`category/${dimension.slug}/${category.id}`}>
                      <MethodI18nFragment text={category.name} />
                      {isDone(ratingState, category, method.kpisById, ratingProfileConfig?.kpis) ? (
                        <CheckIcon ml={2} boxSize="14px" color="green.400" />
                      ) : null}
                    </NavItem>
                  ) : null,
                )}
              </>
            ) : null}
          </React.Fragment>
        ))}
      </ContentBox>
    </Container>
  )
}

function isDone(
  ratingState: RatingState,
  category: KpiCategory,
  kpisById: Record<Kpi["id"], Kpi>,
  ratingProfileKpis: Record<CUID, RatingProfileConfigItem> | undefined,
) {
  if (!ratingState) {
    return false
  }

  const kpis = category.kpiIds.map((kpiId) => kpisById[kpiId])

  return (
    kpis
      // Ignore unused KPIs
      .filter((kpi) => ratingProfileKpis?.[kpi.id]?.enabled !== false)
      // All KPIs approved
      .every((kpi) => ratingState?.approvedKpis[kpi.id])
  )
}
