import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { validateContactPerson } from "@esgt/utils"
import { MinimalContactInfo, User, useChangeRatingContactPersonsMutation } from "lib/generated/graphql"
import { useResultToast } from "lib/hooks"
import { FadedLabel } from "pages/Ratings/NewRating/FadedLabel"
import { useCallback } from "react"
import { useBoolean } from "usehooks-ts"
import { OrderPropertyRow } from "./OrderPropertyRow"
import { EditContactPersonsModal } from "./contactPersons/EditContactPersonsModal"

interface OrderContactsProps {
  ratingId: string
  contactPerson?: User
  contributors?: User[]
  refetchContacts: () => void
  isLoading: boolean
}

export const OrderContacts = ({
  ratingId,
  contactPerson,
  contributors,
  isLoading,
  refetchContacts,
}: OrderContactsProps) => {
  const contactPersons = contactPerson ? [contactPerson, ...contributors] : [...contributors]
  const [changeContactsResult, changeContacts] = useChangeRatingContactPersonsMutation()

  const { value: isEditing, setTrue: startEditing, setFalse: stopEditing } = useBoolean(false)

  const submitContacts = useCallback(
    async (removedUserIdSet: Set<number>, newContacts: Array<MinimalContactInfo>) => {
      const addedContacts = newContacts.filter(validateContactPerson)
      const removedUserIds = [...removedUserIdSet]
      stopEditing()
      await changeContacts({ ratingId, removedUserIds, addedContacts })
      refetchContacts()
    },
    [changeContacts, ratingId, stopEditing, refetchContacts],
  )

  useResultToast(changeContactsResult, "Kontaktpersoner oppdatert", "Kunne ikke oppdatere kontaktpersoner")

  return (
    <OrderPropertyRow
      title="Kontaktpersoner"
      infoBody="helpTexts.ratingSettings.orderContacts"
      isEditing={isEditing}
      onEditStart={startEditing}
      isDisabled={isLoading || changeContactsResult.fetching}
    >
      <Table>
        <Thead>
          <Tr>
            <Th paddingLeft={0}>Navn</Th>
            <Th>Epost</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {contactPersons?.map((user) => (
            <Tr key={user.id}>
              <Td paddingLeft={0}>{user.name}</Td>
              <Td>{user.email}</Td>
              <Td textAlign="right">
                <FadedLabel>
                  {isSameContactInfo(user, contactPerson) ? "Hovedkontaktperson" : "Kontaktperson"}
                </FadedLabel>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {isEditing && (
        <EditContactPersonsModal
          primaryContactEmail={contactPerson?.email}
          contactPersons={contactPersons}
          onClose={stopEditing}
          onSubmit={submitContacts}
          refetchContacts={refetchContacts}
        />
      )}
    </OrderPropertyRow>
  )
}

function isSameContactInfo(a: MinimalContactInfo, b: MinimalContactInfo) {
  return a && b && a.name === b.name && a.email === b.email
}
