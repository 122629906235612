import { Box, Heading } from "@chakra-ui/react"
import styled from "styled-components"
import { Page } from "./components"

const Paragraph = styled(Box).attrs({ mb: "4mm", fontSize: "12pt", fontWeight: 300 })``
const ParagraphHeading = styled(Heading).attrs({ mb: "2mm", fontSize: "12pt", fontWeight: 800 })``

export const EsgTaxOther: React.FC = () => {
  return (
    <>
      <Page id="esg-tax-other">
        <Heading mb="4mm">FNs bærekraftmål og taksonomi</Heading>
        <Paragraph>
          Det eksisterer et mangfold av standarder for måling av bærekraft i virksomheter. Grunnpilarene er FN{"'"}s
          bærekraftsmål (SDG - Sustainable Development Goals) og taksonomi.
        </Paragraph>
        <Paragraph>
          FN{"'"}s bærekraftmål er verdens felles arbeidsplan for å utrydde fattigdom, bekjempe ulikhet og stoppe
          klimaendringene innen 2030. FNs bærekraftmål består av 17 mål og 169 delmål. Målene skal fungere som en felles
          global retning for land, næringsliv og sivilsamfunn. Målene ble laget på en demokratisk måte gjennom innspill
          fra land over hele verden og nesten ti millioner mennesker fikk sagt sin mening. Bærekraftmålingen i denne
          rapporten bygger på FNs bærekraftmål. Hver indikator knyttes til de(t) aktuelle bærekraftmål.
        </Paragraph>
        <Paragraph>
          Taksonomien skal definere bærekraftaktiviteter og er et klassifiseringssystem utarbeidet av EU. Taksonomien
          omfatter blant annet krav om offentliggjøring om hvorvidt økonomiske aktiviteter er bærekraftige i henhold til
          miljømål og definerte krav. Taksonomien inneholder også minimumskrav til sosiale forhold. Disse kravene finnes
          under EUs taksonomikompass som gir en visuell presentasjon av innholdet i taksonomien og brukes som et
          hjelpemiddel til å forstå aktiviteter som inngår i taksonomien. Taksonomikompasset viser aktiviteter og deres
          samsvar med betydelig bidrag og ikke-skade kriteriene til taksonomien.
        </Paragraph>
        <Paragraph>
          I taksonomiens artikkel 2, nr.22 og nr. 24 defineres “bærekraftrisiko” og “bærekraftsfaktorer”
        </Paragraph>
        <Paragraph>
          22{")"} «bærekraftsrisiko» en miljømessig, sosial eller styringsmessig hendelse eller omstendighet som dersom
          den oppstår, kan få en mulig eller faktisk negativ vesentlig innvirkning på investeringens verdi.” EUR-Lex -
          32020R0852 - EN - EUR-Lex (europa.eu)
        </Paragraph>
        <Paragraph mb="8mm">
          24{")"} «bærekraftsfaktorer» miljøspørsmål, sosiale spørsmål og personalspørsmål samt spørsmål knyttet til
          respekt for menneskerettighetene og bekjempelse av korrupsjon.” EUR-Lex - 32020R0852 - EN - EUR-Lex
          (europa.eu)
        </Paragraph>
      </Page>
      <Page>
        <Heading mb="4mm">ESG, TCFD, GRI og NSRS</Heading>
        <ParagraphHeading>Environmental, Social and Governance (ESG)</ParagraphHeading>
        <Paragraph mb="6mm">
          ESG-rapportering
          <sup>
            <a href="#reference-78">78</a>
          </sup>{" "}
          er retningslinjer gjeldende for noterte virksomheter, blant annet ved Oslo Børs. Grunnet fravær av en tydelig
          standard for måling av bærekraft ser man at det rapporteres forskjellig fra virksomheter ved
          ESG-rapporteringer.
        </Paragraph>
        <ParagraphHeading>Task force Climate-Related Financial Disclosers (TCFD)</ParagraphHeading>
        <Paragraph mb="6mm">
          TCFD er et rammeverk hvor det gis anbefalinger på hva man bør rapportere på knyttet til klimarisiko. I henhold
          til dette rammeverket skal virksomheter beskrive styring, strategi og risikostyring, samt mål og metode for
          rapporteringen.
        </Paragraph>
        <ParagraphHeading>Global Reporting Initative (GRI)</ParagraphHeading>
        <Paragraph mb="6mm">
          GRI er et rammeverk for rapportering på økonomiske, miljømessige og sosiale forhold, særlig egnet for større
          bedrifter. Rammeverket har obligatoriske indikatorer og spesifikke/valgfrie indikatorer og benytter i stor
          grad tekst.
        </Paragraph>
        <ParagraphHeading>Nordic Sustainability Reporting Standards (NSRS)</ParagraphHeading>
        <Paragraph mb="6mm">
          NSRS er bærekraftrapportering tilpasset små og mellomstore bedrifter. Standarden er utviklet av Nordic
          Accountant Federation (NAF) med Regnskap Norge som initiativtaker. NSRS bygger på TCFD og GRI. Ledelsens mål
          og strategi for virksomheten må kommenteres av virksomheten som måles for å møte dette rammeverket.
        </Paragraph>
        <Heading mb="4mm" fontWeight={800}>
          NFRD, CSRD og ESRS
        </Heading>
        <Paragraph mb="4mm">
          I 2014 introduserte EU Non-Financial Reporting Directive (NFRD). Dette direktivet påla store børsnoterte
          virksomheter å rapportere på ikke-finansiell informasjon. I Norge ble dette implementert i regnskapslovens
          §3-3 c. Corporate Sustainability Reporting Directive (CSRD) ble vedtatt av EU i 2022. Dette direktivet endrer
          rapporteringsplikten i NFRD. CSRD pålegger flere virksomheter å rapportere på ikke-finansiell informasjon, og
          omfavner om lag 50.000 virksomheter i EU. Videre gir CSRD krav om hva som skal rapporteres innenfor
          dimensjonene miljø og klima, sosiale forhold og virksomhetsstyring. CSRD blir innført stegvis for virksomheter
          som ikke var omfattet av NFRD.
          <sup>
            <a href="#reference-79">79</a>
          </sup>
          <br />
          <br />
          En av tankene bak CSRD var å standardisere hva virksomheter rapporterer av ikke-finansiell informasjon. EU
          engasjerte derfor European Financial Reporting Advisory Group (EFRAG) til å utarbeide European Sustainability
          Reporting Standard (ESRS). ESRS består av et definert indikatorsett som oppfyller rapporteringskravene i CSRD.
          ESRS forventes å bli vedtatt som rapporteringsstandard i midten av 2023. Metodens indikatorer er relatert til
          datapunkter og informasjonsbehov i Høringsforslaget. Økonomiske indikatorer har referanse til Directive
          2013/34/EU.
          <br />
          <br />
          Bærekraftmålingen i denne rapporten har ikke som hensikt å svare ut alle forhold innen alle rammeverk.
          <br />
          <br />
          Alle virksomheter som måles på plattformen ESG Trackr har forhåndsdefinert metode, interessenter, indikatorer
          og tilhørende måltall.
          <br />
          <br />
          Plattformens metode bygger på FN{"'"}s bærekraftmål (SDG), ESG, ESRS, GRI og NSRS. NSRS bygger på TCFD og GRI.
          Beskrivelser og risikovurderinger synliggjøres av indikatorenes resultat med utgangspunkt i virksomhetens
          bransje og tilgjengelige måltall.
        </Paragraph>
      </Page>
    </>
  )
}
