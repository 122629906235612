// Note: does not support scientific notation
import { BigNumber } from "mathjs"

const NUMBER_PATTERN = /^-?\d+(\.\d+)?$/

function isNumberString(str: string) {
  return NUMBER_PATTERN.test(str)
}

export function isNumerical(num: string | number | BigNumber) {
  if (typeof num === "number") {
    return true
  }
  if (typeof num === "string") {
    return isNumberString(num)
  }
  if (typeof num === "object") {
    // BigNumber
    return isNumberString(num.toString())
  }
  return false
}
