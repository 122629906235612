import { RatingState } from "@esgt/event-store"
import { Kpi, MethodConfig, Question, RatingProfileConfig, RatingType } from "@esgt/types"
import { byId } from "../byId"
import { filterCategoriesWithoutQuestions } from "../filterCategoriesWithoutQuestions"
import { makeAdditionalQuestionsCompatible } from "../makeAdditionalQuestionsCompatible"

export type QuestionsProgress = {
  [questionCategoryId: string]: boolean
}

export function questionsProgress(
  ratingState: Pick<RatingState, "answerValues" | "additionalQuestions" | "uploads" | "ratingType">,
  methodConfig: MethodConfig,
  ratingProfileConfig?: RatingProfileConfig,
): QuestionsProgress {
  const questionsById = byId(methodConfig.questions)
  const disabledKpiIds = getDisabledKpiIds(ratingProfileConfig)
  const allQuestions = methodConfig.questions

  const categories = filterCategoriesWithoutQuestions(
    ratingState,
    methodConfig,
    [...methodConfig.questionCategories],
    ratingProfileConfig,
  )

  const progress = categories.reduce(
    (memo, category) => {
      const categoryQuestions = category.questionIds.map((id) => questionsById[id])
      return Object.assign(memo, {
        [category.id]: areAllQuestionsDone(ratingState, categoryQuestions, disabledKpiIds, allQuestions),
      })
    },
    {} as Record<string, boolean>,
  )

  if (ratingState.additionalQuestions?.length) {
    progress.Tilleggsspørsmål = areAllQuestionsDone(
      ratingState,
      makeAdditionalQuestionsCompatible(ratingState.additionalQuestions),
      disabledKpiIds,
    )
  }

  return progress
}

function areAllQuestionsDone(
  ratingState: Pick<RatingState, "answerValues" | "additionalQuestions" | "uploads" | "ratingType">,
  targetQuestions: Array<Question>,
  disabledKpiIds: Set<Kpi["id"]>,
  allQuestions: Array<Question> = [],
) {
  return targetQuestions.every((q) => {
    return isQuestionDone(q, ratingState, disabledKpiIds, allQuestions)
  })
}

function isMissingAllRequiredKpis(
  hideIfAllDisabled: Array<Kpi["id"]> | undefined,
  disabledKpiIds: Set<Kpi["id"]>,
): boolean {
  if (!hideIfAllDisabled || hideIfAllDisabled.length === 0) {
    return false
  }

  return hideIfAllDisabled.every((kpiId) => disabledKpiIds.has(kpiId))
}

function getDisabledKpiIds(ratingProfileConfig?: RatingProfileConfig): Set<Kpi["id"]> {
  if (!ratingProfileConfig) {
    return new Set()
  }

  return new Set(
    Object.entries(ratingProfileConfig.kpis)
      .filter(([_, { enabled }]) => enabled === false)
      .map(([kpiId, _]) => kpiId as Kpi["id"]),
  )
}

function findQuestion(allQuestions: Array<Question>, dependsOn: Question["id"]) {
  const question = allQuestions.find((q) => q.id === dependsOn)
  if (question) {
    return question
  }

  return undefined
}

export function isQuestionDone(
  q: Question,
  ratingState: Pick<RatingState, "answerValues" | "additionalQuestions" | "uploads" | "ratingType">,
  disabledKpiIds: Set<Kpi["id"]>,
  allQuestions: Array<Question> = [],
) {
  const isFree = ratingState.ratingType === RatingType.Free
  if (q.dependsOn) {
    const dependency = findQuestion(allQuestions, q.dependsOn)

    // Question depends on another question's "Yes", but that question got a "No"
    if (dependency && ratingState.answerValues[dependency.id]?.primaryAnswer === "false") {
      return true
    }

    // Question depends on another question's "Yes", but that question is hidden due to all required KPIs being disabled
    if (dependency && isMissingAllRequiredKpis(dependency.hideIfAllDisabled, disabledKpiIds)) {
      return true
    }
  }

  // Questions is ignored because all required KPIs disabled
  if (isMissingAllRequiredKpis(q.hideIfAllDisabled, disabledKpiIds)) {
    return true
  }

  const uploadsCompleted =
    q.uploads?.length && !isFree ? ratingState.uploads.some((upload) => upload.scope === `questions/${q.id}`) : true

  const textInputsCompleted = q.textInputs?.length
    ? q.textInputs.every(({ optional }, i) => {
        const answer = ratingState.answerValues[q.id]?.[`input_${i}`]

        return answer !== undefined && answer !== "" && (optional || answer !== null)
      })
    : true

  if (q.noPrimaryValue) {
    return uploadsCompleted && textInputsCompleted
  }

  return (
    ratingState.answerValues[q.id]?.primaryAnswer === "false" ||
    (ratingState.answerValues[q.id]?.primaryAnswer === "true" && uploadsCompleted && textInputsCompleted)
  )
}
