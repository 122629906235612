import { UserAccountRole, UserRatingRole } from "@esgt/types"

export const RATING_ROLE_GUI_NAMES: Record<UserRatingRole, string> = {
  [UserRatingRole.Owner]: "Opprettet måling",
  [UserRatingRole.Rater]: "Kontrollør",
  [UserRatingRole.ContactPerson]: "Kontaktperson",
  [UserRatingRole.Contributor]: "Bidragsyter",
}

export const ACCOUNT_ROLE_GUI_NAMES: Record<keyof typeof UserAccountRole, string> = {
  Admin: "Administrator",
  AccountAdmin: "Kontomedeier",
  AccountOwner: "Kontoeier",
  AccountMember: "Kontrollør",
}

export type MonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const MONTH_STRINGS: Record<MonthNumber, string> = {
  1: "januar",
  2: "februar",
  3: "mars",
  4: "april",
  5: "mai",
  6: "juni",
  7: "juli",
  8: "august",
  9: "september",
  10: "oktober",
  11: "november",
  12: "desember",
}
