import { Icon, IconProps } from "@chakra-ui/react"

export const IconPlus: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <title>Plus</title>
      <rect x="6" y="11.5" width="12" height="1" rx="0.5" fill="currentColor" />
      <rect x="11.5" y="18" width="12" height="1" rx="0.5" transform="rotate(-90 11.5 18)" fill="currentColor" />
    </Icon>
  )
}
