import { IconType } from "react-icons"

export const IconNotFound: IconType = (props) => {
  return (
    <svg width="228" height="228" viewBox="0 0 228 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>404 - Not Found</title>
      <path
        d="M108.827 163.275C108.827 163.275 136.987 167.795 159.071 148.009C181.155 128.223 187.231 96.0467 192.835 70.136C198.439 44.2253 186.527 21.1799 164.52 13.7949C142.513 6.40993 132.991 25.1962 105.842 31.1309C78.6918 37.0656 70.4303 34.4714 51.3484 54.4614C32.2665 74.4515 36.6665 112.344 60.8524 136.449C85.0383 160.554 108.827 163.275 108.827 163.275Z"
        fill="#E0F0EC"
      />
      <path
        d="M188.343 114.446C188.343 114.446 190.103 115.995 189.787 116.611C189.47 117.227 183.099 123.724 183.099 123.724L181.965 122.077L185.573 115.579L188.343 114.446Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M179.931 106.434C179.931 106.434 187.724 113.312 187.724 113.724C187.724 114.136 184.837 116.818 184.837 116.818L179.167 115.167L179.477 108.775"
        fill="white"
      />
      <path
        d="M179.931 106.434C179.931 106.434 187.724 113.312 187.724 113.724C187.724 114.136 184.837 116.818 184.837 116.818L179.167 115.167L179.477 108.775"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M186.383 111.767L184.014 117.022L184.323 117.744L188.963 113.826C188.963 113.826 187.931 111.661 186.383 111.767Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M159.314 116.512C158.556 116.603 157.791 116.418 157.16 115.991L143.196 106.522C142.851 106.289 142.556 105.99 142.327 105.642C142.099 105.295 141.94 104.906 141.862 104.497C141.784 104.089 141.786 103.669 141.87 103.261C141.954 102.854 142.117 102.467 142.351 102.122C142.584 101.778 142.883 101.483 143.231 101.254C143.578 101.025 143.967 100.867 144.376 100.789C144.784 100.71 145.205 100.713 145.612 100.797C146.02 100.881 146.406 101.044 146.751 101.277L160.715 110.746C161.249 111.109 161.659 111.627 161.89 112.229C162.122 112.832 162.164 113.491 162.011 114.118C161.858 114.745 161.517 115.31 161.034 115.738C160.55 116.166 159.948 116.436 159.307 116.512H159.314Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.436 117.374C152.436 117.374 153.291 118.916 153.633 119.353C153.974 119.789 155.72 120.173 155.72 120.173C155.72 120.173 157.561 125.002 157.92 125.865C158.279 126.727 159.039 128.223 159.395 128.329C159.75 128.434 162.348 129.613 162.968 128.79C163.587 127.966 163.489 125.066 163.489 125.066L158.209 119.87L157.832 118.163L152.436 117.374Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M188.836 139.61C187.238 139.801 185.624 139.408 184.292 138.505L154.041 117.997C153.299 117.511 152.661 116.882 152.164 116.147C151.668 115.412 151.322 114.586 151.148 113.716C150.974 112.846 150.975 111.95 151.15 111.08C151.326 110.211 151.673 109.385 152.17 108.651C152.668 107.916 153.307 107.288 154.05 106.803C154.793 106.318 155.625 105.986 156.497 105.826C157.369 105.665 158.265 105.68 159.132 105.869C159.999 106.058 160.819 106.418 161.545 106.927L191.796 127.424C192.926 128.189 193.794 129.28 194.285 130.552C194.777 131.824 194.867 133.216 194.545 134.542C194.223 135.867 193.504 137.062 192.483 137.966C191.463 138.871 190.19 139.442 188.836 139.603V139.61Z"
        fill="#4BA792"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M153.341 119.363C153.341 119.363 152.552 117.325 152.45 117.121C152.348 116.917 151.707 116.065 151.809 115.804C151.911 115.544 152.214 115.4 152.686 115.738C153.157 116.075 154.041 117.997 154.041 117.997C154.041 117.997 155.424 117.293 156.033 117.899C156.642 118.504 156.776 120.131 156.776 120.131"
        fill="white"
      />
      <path
        d="M153.341 119.363C153.341 119.363 152.552 117.325 152.45 117.121C152.348 116.917 151.707 116.065 151.809 115.804C151.911 115.544 152.214 115.4 152.686 115.738C153.157 116.075 154.041 117.997 154.041 117.997C154.041 117.997 155.424 117.293 156.033 117.899C156.642 118.504 156.776 120.131 156.776 120.131"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M113.008 45.9395C105.852 46.7932 99.1103 49.7499 93.6349 54.4356C88.1594 59.1213 84.1964 65.3255 82.2472 72.2636C80.298 79.2017 80.45 86.562 82.6842 93.4137C84.9183 100.265 89.1341 106.301 94.7984 110.756C100.463 115.212 107.321 117.887 114.506 118.445C121.691 119.002 128.88 117.416 135.164 113.888C141.448 110.359 146.544 105.047 149.808 98.6215C153.073 92.1965 154.358 84.9477 153.502 77.792C152.355 68.1988 147.445 59.4541 139.851 53.4808C132.257 47.5076 122.602 44.7949 113.008 45.9395ZM121.143 114.132C114.812 114.887 108.399 113.748 102.715 110.858C97.0309 107.969 92.3315 103.458 89.211 97.8978C86.0904 92.3374 84.6887 85.9765 85.1832 79.6194C85.6777 73.2623 88.0461 67.1947 91.9889 62.1836C95.9318 57.1726 101.272 53.4432 107.334 51.4672C113.397 49.4911 119.909 49.3571 126.047 51.082C132.186 52.8069 137.675 56.3134 141.821 61.1579C145.967 66.0025 148.583 71.9675 149.338 78.2989C150.351 86.7895 147.949 95.3346 142.661 102.055C137.374 108.775 129.634 113.119 121.143 114.132Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.579 45.3341C104.423 46.1848 97.679 49.1389 92.2015 53.8226C86.7239 58.5063 82.7585 64.7092 80.8066 71.6468C78.8547 78.5845 79.0041 85.9451 81.2359 92.7978C83.4676 99.6505 87.6816 105.687 93.3446 110.145C99.0077 114.603 105.866 117.281 113.051 117.84C120.236 118.4 127.426 116.816 133.711 113.289C139.996 109.763 145.094 104.451 148.36 98.0265C151.626 91.602 152.913 84.3533 152.059 77.1971C150.914 67.6048 146.007 58.8597 138.416 52.8846C130.825 46.9095 121.172 44.1937 111.579 45.3341ZM119.714 113.527C113.382 114.282 106.969 113.143 101.285 110.253C95.6016 107.363 90.9023 102.853 87.7817 97.2924C84.6612 91.7319 83.2595 85.371 83.754 79.014C84.2485 72.6569 86.6169 66.5892 90.5597 61.5782C94.5026 56.5671 99.8428 52.8378 105.905 50.8617C111.968 48.8857 118.48 48.7516 124.618 50.4766C130.757 52.2015 136.246 55.7079 140.392 60.5525C144.537 65.397 147.153 71.3621 147.909 77.6934C148.921 86.1841 146.52 94.7292 141.232 101.449C135.945 108.169 128.204 112.514 119.714 113.527Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.087 96.3248C165.087 96.3248 164.189 98.8416 164.249 99.3203C164.309 99.799 164.548 99.982 164.249 100.281C163.95 100.58 162.992 101.299 163.112 101.717C163.273 102.068 163.514 102.375 163.816 102.615C163.816 102.615 164.474 105.251 165.132 105.551C165.791 105.85 166.811 105.611 166.811 105.611L167.515 107.529L172.549 106.272L172.25 103.217C172.25 103.217 173.746 100.341 173.387 98.6022C173.028 96.8633 169.912 95.1878 167.874 95.1878C165.836 95.1878 165.266 95.846 165.087 96.3248Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path d="M165.118 103.34C165.118 103.34 165.903 103.238 166.143 102.759" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M165.189 100.267C165.189 100.475 165.118 100.644 165.034 100.644C164.949 100.644 164.883 100.475 164.883 100.267C164.883 100.06 164.949 99.8906 165.034 99.8906C165.118 99.8906 165.189 100.06 165.189 100.267Z"
        fill="#263238"
      />
      <path
        d="M164.812 99.4154C164.812 99.4154 165.256 98.8698 165.516 99.5491"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M166.28 97.2857C166.28 97.2857 164.302 97.4054 164.168 95.5469C164.034 93.6883 166.745 92.671 167.762 92.671C168.779 92.671 168.061 93.6918 168.061 93.6918C168.061 93.6918 170.578 92.9702 171.229 93.632C171.88 94.2937 171.349 94.8288 171.349 94.8288C171.349 94.8288 174.643 96.2051 174.643 98.6022C174.643 100.999 172.908 103.337 172.609 103.815C172.31 104.294 171.588 104.713 170.272 104.773C168.955 104.833 168.16 104.174 167.874 103.277L167.575 102.376C167.575 102.376 168.593 101.299 168.114 100.401C167.635 99.5033 167.336 100.049 167.216 100.401C167.097 100.753 167.336 101.105 167.097 101.105C166.857 101.105 167.037 100.806 166.393 100.148C165.748 99.4893 165.192 99.1865 165.432 98.5283C165.635 98.0651 165.922 97.6437 166.28 97.2857Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M166.107 107.237L165.393 108.817L174.489 106.748L173.436 105.435L166.107 107.237Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M169.761 161.234C169.761 161.234 167.051 161.881 165.474 161.881C163.897 161.881 163.207 161.8 163.207 162.325C163.207 162.849 165.717 163.821 167.171 164.388C168.624 164.954 171.458 165.764 171.701 165.683C171.944 165.602 172.429 162.649 172.429 162.649L169.761 161.234Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M163.369 162.019C163.316 162.05 163.273 162.095 163.245 162.149C163.216 162.203 163.203 162.264 163.207 162.325C163.207 162.853 165.717 163.821 167.171 164.388C168.624 164.954 171.458 165.764 171.701 165.683C171.757 165.665 171.824 165.493 171.898 165.246C169.064 164.68 164.538 162.575 163.369 162.019Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M164.907 126.657C164.89 128.614 164.971 130.571 165.15 132.521C165.432 135.228 166.727 147.118 166.847 148.98C166.966 150.843 169.761 161.234 169.761 161.234L172.429 162.642C172.429 162.642 173.763 151.075 173.763 149.135V145.9C173.763 145.9 176.108 136.717 176.836 134.534C177.565 132.352 177.928 128.022 177.85 125.597C175.713 126.429 173.453 126.905 171.162 127.005C169.071 127.125 166.972 127.008 164.907 126.657Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M179.688 109.891L178.6 118.624C178.6 118.624 178.174 125.235 178.054 125.844C177.935 126.452 177.385 126.752 174.886 127.604C172.387 128.455 165.664 127.846 165.118 127.297C164.573 126.748 164.453 121.415 164.453 121.415C164.453 121.415 163.724 124.509 163.179 124.935C162.633 125.361 160.57 125.178 159.599 124.27C158.627 123.362 159.782 112.925 159.719 111.95C159.655 110.975 165.664 108.613 165.664 108.613C165.664 108.613 174.01 106.698 174.464 106.744C174.918 106.79 179.543 105.825 179.906 106.431C180.268 107.036 180.515 107.402 180.515 107.402"
        fill="white"
      />
      <path
        d="M179.688 109.891L178.6 118.624C178.6 118.624 178.174 125.235 178.054 125.844C177.935 126.452 177.385 126.752 174.886 127.604C172.387 128.455 165.664 127.846 165.118 127.297C164.573 126.748 164.453 121.415 164.453 121.415C164.453 121.415 163.724 124.509 163.179 124.935C162.633 125.361 160.57 125.178 159.599 124.27C158.627 123.362 159.782 112.925 159.719 111.95C159.655 110.975 165.664 108.613 165.664 108.613C165.664 108.613 174.01 106.698 174.464 106.744C174.918 106.79 179.543 105.825 179.906 106.431C180.268 107.036 180.515 107.402 180.515 107.402"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path d="M164.464 121.419L162.823 113.471" stroke="#263238" strokeMiterlimit="10" />
      <path d="M173.323 110.503L169.553 121.813" stroke="#263238" strokeMiterlimit="10" />
      <path d="M172.296 117.871L169.553 124.728" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M159.173 121.729C159.081 121.858 159.033 122.013 159.036 122.172C159.036 122.443 158.494 124.636 158.73 125.062C158.965 125.488 159.955 126.086 161.546 126.153C163.137 126.22 163.756 125.984 163.893 125.572C164.121 124.702 164.28 123.814 164.369 122.918C164.337 122.683 164.133 122.816 164.133 122.816C163.091 123.03 162.012 122.972 161 122.647C159.412 122.102 159.173 121.729 159.173 121.729Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M165.108 128.959C165.108 128.959 165.189 135.756 165.351 139.758C165.512 143.76 166.16 147.242 166.407 148.048C166.653 148.854 172.151 155.894 173.404 157.393C174.657 158.893 177.083 161.153 177.083 161.153L179.821 159.657C179.821 159.657 176.273 154.602 175.143 152.265C174.013 149.927 172.028 146.038 172.028 146.038L173.278 134.816L175.34 133.686"
        fill="#263238"
      />
      <path
        d="M165.108 128.959C165.108 128.959 165.189 135.756 165.351 139.758C165.512 143.76 166.16 147.242 166.407 148.048C166.653 148.854 172.151 155.894 173.404 157.393C174.657 158.893 177.083 161.153 177.083 161.153L179.821 159.657C179.821 159.657 176.273 154.602 175.143 152.265C174.013 149.927 172.028 146.038 172.028 146.038L173.278 134.816L175.34 133.686"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M174.897 134.242L173.211 135.369L172.025 146.027L173.764 149.142"
        stroke="#696969"
        strokeMiterlimit="10"
      />
      <path
        d="M177.125 161.047C177.125 161.047 175.823 162.979 175.33 163.427C174.837 163.874 172.905 165.046 172.634 165.313C172.362 165.581 173.848 165.81 174.925 165.81C176.002 165.81 177.35 165.584 178.023 165.179C178.695 164.775 181.212 162.979 181.525 162.666C181.838 162.353 179.818 159.657 179.818 159.657L177.125 161.047Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M181.525 162.666C181.634 162.557 181.469 162.173 181.205 161.716C180.223 162.747 178.037 164.951 177.125 165.236C176.111 165.535 173.531 165.334 172.711 165.26L172.634 165.324C172.366 165.595 173.848 165.82 174.925 165.82C176.002 165.82 177.35 165.595 178.023 165.19C178.695 164.785 181.212 162.976 181.525 162.666Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M67.5879 36.21V102H56.6979V36.21H67.5879ZM23.3079 79.41L53.0979 36.21H65.2479L35.0079 80.13L23.3079 79.41ZM75.5979 89.49H23.3079V79.41H75.5979V89.49ZM201.829 36.21V102H190.939V36.21H201.829ZM157.549 79.41L187.339 36.21H199.489L169.249 80.13L157.549 79.41ZM209.839 89.49H157.549V79.41H209.839V89.49Z"
        fill="#4BA792"
      />
    </svg>
  )
}
