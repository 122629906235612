import { Box, Heading } from "@chakra-ui/react"
import frontpageImg from "assets/report-frontpage.jpg"
import { Logo } from "components/Logo"
import { useRatingAccount } from "lib/providers/RatingAccount"
import { Page } from "./components"

export const Outro: React.FC = () => {
  const { accountTitle } = useRatingAccount()

  return (
    <Page style={{ breakAfter: "avoid" }}>
      <Heading mt={0} fontWeight={400} mb={"4mm"} fontSize={"25pt"} textAlign={"center"}>
        En takk
      </Heading>
      <Box position={"relative"}>
        <img alt="" width={"100%"} src={frontpageImg} />
        <div style={{ position: "absolute", top: "10mm", left: "10mm" }}>
          <Logo />
        </div>
      </Box>
      <Box textAlign={"center"} fontSize="10pt" mt={"6mm"} mb="4mm" fontWeight="300">
        Basert på objektivt konstaterbare fakta har vi med denne rapporten sammen bevist at det er mulig å
        bærekraftsmåle virksomheter på en forhåndsdefinert metode og indikatorsett.
      </Box>
      <Box textAlign={"center"} fontSize="10pt" mb="4mm" fontWeight="300">
        Deres virksomhet bidrar til videreutvikling av metode og måltall for måling av virksomheters bærekraft ved bruk
        av plattformen ESG Trackr. Takk!
      </Box>
      <Box textAlign={"center"} fontSize="10pt" mb="0" fontWeight="100">
        PRODUSERT AV {accountTitle || "-"} - {new Date().getFullYear()}
      </Box>
    </Page>
  )
}
