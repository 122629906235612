import {
  BenchmarkKey,
  CUID,
  Kpi,
  RatingAnomaly,
  RatingDatapoints,
  RatingPhase,
  RatingProfileConfig,
  RatingProfileConfigItem,
} from "@esgt/types"
import { Decimal } from "decimal.js"
import * as math from "mathjs"
import { ExtendedCalculatedKpi, KpiOverride } from "../types"
import { benchmarkLevels } from "./benchmarkLevels"
import { calculateKPIFormula } from "./calculateKPIFormula"

interface CalculatedKPI {
  benchmarkLevel?: number
  score?: Decimal
  value?: number | math.BigNumber
  hasAllRequiredDatapoints: boolean
}

export function calculateKPI(
  kpi: Kpi,
  ratingProfileConfig: RatingProfileConfig,
  datapoints: RatingDatapoints,
  anomalies: Array<RatingAnomaly>,
  overriddenKpisById: Record<CUID, KpiOverride>,
  kpisById: Record<CUID, Kpi> = {},
): CalculatedKPI | undefined {
  let value: math.BigNumber | number | undefined
  let hasAllRequiredDatapoints = false

  if (typeof overriddenKpisById[kpi.id]?.value !== "undefined") {
    value = overriddenKpisById[kpi.id].value
    // pretending to always have everything we since it's forced to a valid result
    hasAllRequiredDatapoints = true
  } else {
    try {
      const calculated = calculateKPIFormula(
        kpi,
        datapoints,
        anomalies,
        kpisById,
        ratingProfileConfig,
        overriddenKpisById,
      )

      // Apply KPI value override, if any
      value = overriddenKpisById[kpi.id]?.value ?? calculated.value

      hasAllRequiredDatapoints = calculated.hasAllRequiredDatapoints
    } catch (_error) {
      return
    }
  }

  if ((value === undefined && hasAllRequiredDatapoints) || (!ratingProfileConfig && hasAllRequiredDatapoints)) {
    return {
      value,
      hasAllRequiredDatapoints,
    }
  }

  const kpiConfig = ratingProfileConfig.kpis?.[kpi.id]

  if (!kpiConfig || kpiConfig.benchmark_0 === undefined || kpiConfig.benchmark_4 === undefined)
    return { value, hasAllRequiredDatapoints }

  if (!hasAllRequiredDatapoints) {
    return {
      benchmarkLevel: 0,
      hasAllRequiredDatapoints,
    }
  }

  let benchmarkLevel: number | undefined = undefined

  const ascending = benchmarksAreAscending(kpiConfig)

  for (let i = 0; i < 5; i++) {
    const bmValue = kpiConfig[`benchmark_${i}` as BenchmarkKey]

    if (bmValue === undefined || !bmValue?.toString().length) break

    if (
      ascending
        ? math.bignumber(value).greaterThanOrEqualTo(math.bignumber(bmValue))
        : math.bignumber(value).lessThanOrEqualTo(math.bignumber(bmValue))
    ) {
      benchmarkLevel = i
    }
  }

  if (benchmarkLevel === undefined) {
    benchmarkLevel = 0
  }

  const score = benchmarkLevel
    ? math
        .bignumber(value)
        .minus(math.bignumber(kpiConfig.benchmark_0))
        .dividedBy(
          math.bignumber(kpiConfig.benchmark_4).minus(
            //
            math.bignumber(kpiConfig.benchmark_0),
          ),
        )
        .mul(100)
    : undefined

  return {
    benchmarkLevel,
    score,
    value,
    hasAllRequiredDatapoints,
  }
  // benchmark values mean for that value up to next is this benchmark
  // i.e. the max benchmark is its value to infinity
}

const noBenchmarkColor = "#8ab4e0"

export function extendedCalculateKpi(
  kpi: Kpi,
  ratingProfileConfig: RatingProfileConfig,
  datapoints: RatingDatapoints,
  anomalies: Array<RatingAnomaly>,
  ratingPhase: RatingPhase,
  overriddenKpisById: Record<CUID, KpiOverride> = {},
  kpisById: Record<CUID, Kpi> = {},
): ExtendedCalculatedKpi {
  const kpiConfig = ratingProfileConfig.kpis?.[kpi.id]

  // Make original value available when overridden
  let beforeOverride: ExtendedCalculatedKpi | undefined = undefined
  if (overriddenKpisById[kpi.id]) {
    const { [kpi.id]: _, ...otherOverrides } = overriddenKpisById
    beforeOverride = extendedCalculateKpi(
      kpi,
      ratingProfileConfig,
      datapoints,
      anomalies,
      ratingPhase,
      otherOverrides,
      kpisById,
    )
  }

  if (kpi.isFuture === true) {
    return {
      beforeOverride,
      text: "Fremtidig",
      color: noBenchmarkColor,
      noDataCause: "not_relevant",
      shouldBeIncluded: false,
    }
  }

  if (kpiConfig && kpiConfig.enabled === false) {
    return {
      beforeOverride,
      text: "Ikke relevant",
      color: noBenchmarkColor,
      noDataCause: "not_relevant",
      shouldBeIncluded: false,
    }
  }

  const isAwaitingSubmit = kpi.noCalculationUntilSubmitted && ratingPhase === "SUBMISSION"
  if (isAwaitingSubmit) {
    return {
      beforeOverride,
      text: "Venter på grunnlag",
      color: noBenchmarkColor,
      noDataCause: "no_data",
      shouldBeIncluded: true,
    }
  }

  const calculated = calculateKPI(kpi, ratingProfileConfig, datapoints, anomalies, overriddenKpisById, kpisById)

  // If there are missing datapoints, it should always result in a bottom score
  const isMissingDatapoints = calculated?.hasAllRequiredDatapoints === false
  if (isMissingDatapoints) {
    return {
      beforeOverride,
      text: benchmarkLevels[0].name,
      color: benchmarkLevels[0].color,
      kpiConfig,
      shouldBeIncluded: true,
      ...calculated,
    }
  }

  if (!kpiConfig || kpiConfig.benchmark_0 === undefined || kpiConfig.benchmark_4 === undefined) {
    return {
      beforeOverride,
      text: "Ingen benchmark",
      color: noBenchmarkColor,
      noDataCause: "no_benchmark",
      shouldBeIncluded: true,
      ...calculated,
    }
  }

  if (!calculated || calculated?.benchmarkLevel === undefined) {
    return {
      beforeOverride,
      text: "Feil ved utregning",
      color: "red",
      noDataCause: "no_data",
      shouldBeIncluded: true,
    }
  }

  return {
    beforeOverride,
    text: benchmarkLevels[calculated.benchmarkLevel].name,
    color: benchmarkLevels[calculated.benchmarkLevel].color,
    kpiConfig,
    shouldBeIncluded: true,
    ...calculated,
  }
}

export function benchmarksAreAscending(kpiConfig: RatingProfileConfigItem) {
  return math.bignumber(kpiConfig.benchmark_0).lessThanOrEqualTo(math.bignumber(kpiConfig.benchmark_4))
}
