import { Icon, IconProps } from "@chakra-ui/react"

export const IconUser: React.FC<IconProps> = (props) => {
  return (
    <Icon width="9" height="9" viewBox="0 0 38 38" fill="none" {...props}>
      <title>User</title>
      <path
        d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 21.6666C22.7614 21.6666 25 19.428 25 16.6666C25 13.9052 22.7614 11.6666 20 11.6666C17.2386 11.6666 15 13.9052 15 16.6666C15 19.428 17.2386 21.6666 20 21.6666Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2798 31.415C10.6923 30.042 11.5364 28.8386 12.6869 27.9833C13.8373 27.1279 15.2329 26.6662 16.6665 26.6666H23.3331C24.7685 26.6661 26.1658 27.129 27.3171 27.9863C28.4684 28.8436 29.3122 30.0496 29.7231 31.425"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
