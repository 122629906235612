import { Alert, AlertIcon, Box, Flex, Input, InputGroup, InputRightAddon } from "@chakra-ui/react"
import { QuestionInput } from "@esgt/types"
import { MethodI18nFragment, MethodI18nText } from "components/I18nText"
import { InputNumber } from "components/Input/Number"
import { Controller, useFormContext } from "react-hook-form"
import { createRegexFromValidationPattern } from "./createRegexFromValidationPattern"

interface ShortTextInputProps {
  questionInput: QuestionInput
  disabled: boolean
  inputName: string
  saveChanges: () => void
}

export const ShortTextInput: React.FC<ShortTextInputProps> = ({ questionInput, disabled, inputName, saveChanges }) => {
  const {
    register,
    control,
    formState: { isValid },
  } = useFormContext()

  const { ...registeredProps } = register(inputName, {
    pattern: createRegexFromValidationPattern(questionInput.validationPattern),
    onChange: saveChanges,
  })

  return (
    <Flex flexDirection="column" gap={2}>
      <Box maxWidth={questionInput.size === "sm" ? "230px" : "400px"}>
        <InputGroup size="sm">
          {questionInput.inputType === "number" ? (
            <Controller
              control={control}
              name={inputName}
              render={({ field: { ref, value, ...field } }) => (
                <InputNumber
                  {...field}
                  value={value === null ? "" : value}
                  size="sm"
                  ref={ref}
                  isDisabled={disabled}
                  bgColor="white"
                  precision={2}
                  borderRightRadius="0"
                  thousands
                  hasError={!isValid}
                />
              )}
            />
          ) : (
            <Input isDisabled={disabled} bgColor="white" {...registeredProps} type="text" isInvalid={!isValid} />
          )}

          {questionInput.unit && (
            <InputRightAddon>
              <MethodI18nFragment text={questionInput.unit} />
            </InputRightAddon>
          )}
        </InputGroup>
      </Box>

      {!isValid && questionInput.invalidMessage && (
        <Alert status="error">
          <AlertIcon /> <MethodI18nText text={questionInput.invalidMessage} />
        </Alert>
      )}
    </Flex>
  )
}
