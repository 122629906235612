import { Flex } from "@chakra-ui/react"
import { useContentScroll } from "components/CenteredContent"
import { Feedback } from "components/Feedback"
import { Pagination } from "components/Pagination"
import { RatingStateFilter, useRatingsOverviewSubscription } from "lib/generated/graphql"
import { useEffect, useMemo, useState } from "react"
import { RatingCards } from "./RatingCards"
import { StateFilter } from "./types"

export type RatingViewMode = "card" | "table"

interface FetchRatingsWrapperProps {
  filter: StateFilter
  count?: number
}

const PAGE_SIZE = 10

export const FetchRatingsWrapper: React.FC<FetchRatingsWrapperProps> = ({ filter, count }) => {
  const [page, setPage] = useState(0)
  const { current: scrollableContent } = useContentScroll()

  const variables = {
    filter: filter.value as RatingStateFilter,
    pagination: { offset: page * PAGE_SIZE, limit: PAGE_SIZE },
  }

  const [{ data }] = useRatingsOverviewSubscription({
    variables,
    pause: count === 0,
  })

  const isLoading = useMemo(() => {
    return !data
  }, [variables, data])

  useEffect(() => setPage(0), [filter])

  const onPageChange = (page: number) => {
    setPage(page)
    if (scrollableContent) {
      scrollableContent.scrollTo({ top: 0, behavior: "auto" })
    }
  }

  return (
    <Flex w="full" flex="1" flexDir="column" alignItems="stretch" gap={6} pb={8}>
      {count > 0 ? (
        <RatingCards loading={isLoading} ratings={data?.ratings || []} count={count} state={filter.value} />
      ) : (
        <Flex flex="1" justifyContent="center" alignItems="center" gap={6}>
          <Feedback type="search" text={filter.emptyText} />
        </Flex>
      )}
      {count > PAGE_SIZE && (
        <Pagination pages={Math.ceil(count / PAGE_SIZE)} currentPage={page} onPageChange={onPageChange} />
      )}
    </Flex>
  )
}
